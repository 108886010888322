<template>
  <div class="cards">
    <div
      :class="`card ${simplified ? 'card--simplified' : ''} ${
        selectedItems.includes(item.id) ? 'card--selected' : ''
      }`"
      v-for="item in items"
      :key="`${item.type}${item.id}`"
    >
      <div class="card-header">
        <div class="jrwa-name">
          <p>{{ item.jrwa_display }}</p>
          <Checkbox
            v-if="selectable"
            class="checkbox"
            :checked="selectedItems.includes(item.id)"
            :onChange="(e) => onChecked(e, item)"
          />
        </div>
        <div class="title">
          <template v-if="item.initiation_date">
            <h4>
              <router-link
                v-if="hasIssuesPermission"
                :to="{ name: 'IssuePreview', params: { id: item.id } }"
                :title="item.signature"
              >
                {{ item.signature }}
              </router-link>
              <Tooltip
                v-else
                :mouseEnterDelay="0.5"
                :title="$t('repository.noPermissionToPreview')"
              >
                {{ item.signature }}
              </Tooltip>
            </h4>
            <Tag v-if="!simplified">{{ item.status_display }}</Tag>
          </template>
          <template v-else-if="item.document_date">
            <h4>
              <router-link
                v-if="hasDocumentsPermission"
                :to="{ name: 'DocumentsPreview', params: { id: item.id } }"
                :title="item.name"
              >
                {{ item.name }}
              </router-link>
              <Tooltip
                v-else
                :mouseEnterDelay="0.5"
                :title="$t('repository.noPermissionToPreview')"
              >
                {{ item.name }}
              </Tooltip>
            </h4>
            <FileTextOutlined v-if="!simplified" />
          </template>

          <template v-else-if="item.archive_category">
            <h4>
              <router-link
                v-if="hasDocumentsPermission"
                :to="{ name: 'FoldersPreview', params: { id: item.id } }"
                :title="item.title"
              >
                {{ item.act_symbol }}
              </router-link>

              <Tooltip
                v-else
                :mouseEnterDelay="0.5"
                :title="$t('repository.noPermissionToPreview')"
              >
                {{ item.name }}
              </Tooltip>
            </h4>
            <br />
            <FileTextOutlined v-if="!simplified" />
          </template>
        </div>
        <div class="content" v-if="item.archive_category">
          <Tooltip :title="item.title">
            <span class="content--title">{{ item.title }}</span>
          </Tooltip>
        </div>
      </div>
      <div class="description">
        <template v-if="item.document_date">
          <p>{{ new Date(item.document_date).toLocaleDateString() || "-" }}</p>
        </template>
        <template v-else-if="item.archive_category">
          <p>
            {{
              item?.edge_date_from
                ? new Date(item.edge_date_from).toLocaleDateString()
                : "-"
            }}
            /
            {{
              item?.edge_date_to
                ? new Date(item.edge_date_to).toLocaleDateString()
                : "-"
            }}
          </p>
        </template>
        <template v-else-if="item.initiation_date">
          <p v-if="!simplified">{{ item.owner_display }}</p>
          <p>
            {{ new Date(item.initiation_date).toLocaleDateString() || "-" }} /
            {{
              item.status === ISSUE_STATUS.IN_PROGRESS
                ? "-"
                : new Date(item.close_date).toLocaleDateString()
            }}
          </p>
          <p v-if="!simplified">
            <FileTextOutlined /> {{ item.documents.length || 0 }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Tag, Tooltip } from "ant-design-vue";
import { FileTextOutlined } from "@ant-design/icons-vue";
import PERMISSIONS from "@/consts/permissions";
import { ISSUE_STATUS } from "@/consts/statuses";

export default {
  name: "EODCards",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    onChecked: {
      type: Function,
      default: () => {},
    },
    simplified: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ISSUE_STATUS,
    };
  },
  components: {
    Checkbox,
    FileTextOutlined,
    Tag,
    Tooltip,
  },
  computed: {
    hasIssuesPermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES);
    },
    hasDocumentsPermission() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables.scss";

.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .card {
    margin: 0.5rem 0 0.5rem 1rem;
    border: 1px solid $background-color;
    width: 30%;
    min-height: 11.75rem;
    text-align: left;
    .card-header {
      border-bottom: 1px solid $background-color;
      padding: 0.5rem 0.65rem;
      font-size: 0.85rem;
      height: 80%;
      display: flex;
      flex-direction: column;
      .jrwa-name {
        display: flex;
        justify-content: space-between;
        max-height: 20%;
      }
      .title {
        flex: 1;
        min-width: 0;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-height: 25%;
        h4 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .content {
        display: flex;
        align-items: center;
        max-height: 50%;
        &--title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ant-tag {
        margin-right: 0;
        border-radius: 7px;
        background-color: transparent;
      }
    }
    .description {
      h4,
      p {
        margin: 0.55rem 0.65rem;
      }
    }
    &--simplified {
      width: 17%;
      height: 40%;
      min-height: auto;
    }
    &--selected {
      box-shadow: 0 0 2px $primary;
    }
  }
}
</style>
