<template>
  <div>
    <div v-if="previewFile">
      {{ pageDesc }}:
      <input
        v-model.number="page"
        type="number"
        min="1"
        :max="numPages"
        class="input-page"
        :title="$t('app.fileViewer.selectPage')"
      />
      /
      {{ numPages }}

      <button
        class="doc-icon ml-1"
        @click="rotate -= 90"
        :title="$t('app.fileViewer.rotate')"
      >
        <UndoOutlined />
      </button>

      <button
        class="doc-icon"
        @click="rotate += 90"
        :title="$t('app.fileViewer.rotate')"
      >
        <RedoOutlined />
      </button>

      <button
        class="doc-icon ml-1"
        @click="zoomIn"
        :title="$t('app.fileViewer.zoomIn')"
      >
        <PlusOutlined />
      </button>

      <button
        class="doc-icon"
        @click="zoomOut"
        :disabled="zoom == 100"
        :title="$t('app.fileViewer.zoomOut')"
      >
        <MinusOutlined />
      </button>

      <a
        :href="previewFile"
        :title="$t('app.downloadFile')"
        class="active-icon ml-1"
      >
        <DownloadOutlined />
      </a>

      <!-- Print ToDo -->
      <div class="pdf-viewer-wrapper" :class="{ 'zoom-active': zoom > 100 }">
        <pdf
          v-if="show"
          ref="pdf"
          :src="src"
          :page="page"
          :rotate="rotate"
          :style="`width: ${zoom}%`"
        ></pdf>
      </div>
    </div>
    <div v-else>{{ $t("app.fileViewer.selectFile") }}</div>
  </div>
</template>

<script>
import {
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
  RedoOutlined,
  UndoOutlined,
} from "@ant-design/icons-vue";

import pdf from "vue3-pdf";

export default {
  name: "FileViewer",
  props: {
    previewFile: String,
    correctAttachmentFormat: Boolean,
    cannotPreviewModal: {
      type: Boolean,
      default: false,
    },
    showCannotPreviewModal: Boolean,
    resetAttachmentData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      pageDesc: this.$t("app.page"),
      show: true,
      src: "",
      loadedRatio: 0,
      page: 1,
      numPages: 1,
      rotate: 0,
      zoom: 100,
    };
  },
  components: {
    UndoOutlined,
    RedoOutlined,
    PlusOutlined,
    DownloadOutlined,
    pdf,
    MinusOutlined,
  },
  methods: {
    zoomIn() {
      this.zoom += 20;
      this.loadFile();
    },
    zoomOut() {
      this.zoom -= 20;
      this.loadFile();
    },
    loadFile() {
      this.src = pdf.createLoadingTask(this.previewFile);

      this.src.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
          if (this.cannotPreviewModal) {
            this.$emit("update:correctAttachmentFormat", true);
          }
        })
        .catch(() => {
          if (this.cannotPreviewModal) {
            this.$emit("update:correctAttachmentFormat", false);
            this.$emit("update:showCannotPreviewModal", true);
          }
        });
    },
  },
  mounted() {
    if (this.previewFile.length > 0) {
      this.loadFile();
    }
  },
  watch: {
    previewFile(val) {
      if (val) {
        this.page = 1;
        this.zoom = 100;
        this.loadFile();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/Variables";

button {
  background-color: $white;
  border: none;
}
.active-icon {
  padding-left: 0.75rem;
  font-size: 1.5rem;
  color: $primary;
}
.doc-icon {
  padding-left: 0.75rem;
  font-size: 1rem;
  color: $primary;
}
.input-page {
  border-radius: 3px;
  width: 4em;
  border: 2px solid $primary;
}
.pdf-viewer-wrapper {
  overflow: scroll;
  max-height: 75vh;
  &.zoom-active {
    cursor: grab;
    -webkit-overflow-scrolling: touch; // Allows smooth/flick scrolling
  }
}
</style>
