<!-- TODO: create separate component for advanced and simple search AWF-1672 -->

<template>
  <Section :title="$t('search.searchResults')">
    <!-- TODO: add unique key for records -->
    <BaseTable
      v-if="searchId"
      :service="searchList"
      :customParams="{
        pk: searchId,
        filter_tab: filter,
      }"
      :columns="columns"
      :showHeader="false"
      :hasSearch="false"
      disableRefresh
      :customStore="`search${filter}`"
      paginationType="LOAD_MORE"
      :emitData="filter === 'all'"
    >
      <template #owner_display="{ record }">
        <Result :record="record" :openDrawer="openDrawer" />
      </template>
    </BaseTable>
    <div v-else>
      <img src="@/img/empty-img-default.png" alt="Logo" />
      <p class="color--font-tertiary">
        {{ $t("search.completeSearchFields") }}
      </p>
    </div>
    <Drawer
      :width="drawerWidth"
      :visible="drawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="closeDrawer"
      :mask="correctAttachmentFormat"
    >
      <template v-slot:title>
        <span v-html="fileName" />
      </template>
      <FileViewer
        :previewFile="previewFile"
        v-model:correctAttachmentFormat="correctAttachmentFormat"
        :cannotPreviewModal="true"
        v-model:showCannotPreviewModal="showCannotPreviewModal"
        :resetAttachmentData="closeDrawer"
      />
    </Drawer>
  </Section>
  <!--  TODO: modal wyświetla sie kiedy FileViewer nie może wyświetlić pliku-->
  <!--  TODO: należy z góry określić przy jakich rozszerzeniach wyświetla się podgląd, a przy jakich modal (blocked_by AWF-1436)-->
  <CannotPreviewModal
    v-model:visible="showCannotPreviewModal"
    :previewFile="previewFile"
    :resetAttachmentData="closeDrawer"
  />
</template>

<script>
import { BaseTable } from "@/components/table";
import { CannotPreviewModal } from "@/components/modals";
import { Drawer } from "ant-design-vue";
import { FileViewer } from "@/components/common";
import Result from "./Result";
import { Section } from "@/components/ui";
import dynamicTable from "@/store/modules/dynamicTable";
import { searchList } from "@/services/search";
import { downloadDocumentURL } from "@/services/documents";

export default {
  name: "SearchResults",
  props: {
    searchId: {
      type: Number,
      required: true,
    },
    filter: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      correctAttachmentFormat: false,
      showCannotPreviewModal: false,
      search: "",
      errors: {},
      resultsCount: 0,
      columns: [
        {
          dataIndex: "owner_display",
          slots: { customRender: "owner_display" },
          sorter: true,
        },
      ],
      drawerVisible: false,
      previewFile: "",
      fileName: "",
    };
  },
  components: {
    FileViewer,
    Drawer,
    Result,
    BaseTable,
    Section,
    CannotPreviewModal,
  },
  computed: {
    drawerWidth() {
      return this.correctAttachmentFormat ? 540 : 0;
    },
  },
  created() {
    if (!this.$store.hasModule(`search${this.filter}`)) {
      this.$store.registerModule(`search${this.filter}`, dynamicTable);
    }
  },
  methods: {
    searchList,
    downloadDocumentURL,
    openDrawer(fileId, fileName) {
      this.fileName = fileName;
      this.previewFile = downloadDocumentURL(fileId);
      this.drawerVisible = true;
    },
    closeDrawer() {
      this.drawerVisible = false;
      this.correctAttachmentFormat = false;
      this.fileName = "";
      this.previewFile = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";

.search-container {
  display: flex;
  background-color: $white;
  &__input {
    height: 100%;
  }
}
</style>
