<template>
  <ConfirmModal
    v-model:visible="cVisible"
    :title="$t('archive.handovers.generateHandoverLists')"
    :okText="$t('archive.handovers.generateHandoverLists')"
    :onCancel="closeGenerateHandoverModal"
    :onSubmit="handleGenerateHandover"
  >
    <p>{{ $t("archive.handovers.handoverGenerationMessage") }}</p>

    <EODSelect
      id="handovers"
      v-model:value="selectedHandover"
      storeID="handovers_generate"
      :service="fetchFolders"
      :errors="noSelectedHandoverError"
      :onChange="onHandoverSelect"
      :placeholder="$t('archive.handovers.handoverGeneretePlaceholder')"
    />
  </ConfirmModal>
</template>

<script>
import {
  generateHandoverLists,
  getHandoversSelect,
} from "@/services/handovers";

import { ConfirmModal } from "@/components/modals";
import { EODSelect } from "@/components/inputs";

export default {
  name: "GenerateHandoverListsModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedHandover: null,
      noSelectedHandoverError: [],
    };
  },
  components: {
    EODSelect,
    ConfirmModal,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  emits: ["update:visible"],
  methods: {
    handleGenerateHandover() {
      if (!this.selectedHandover) {
        this.noSelectedHandoverError.push(
          this.$t("archive.handovers.pickHandoverToGenerete"),
        );
        return;
      }
      this.isLoading = true;

      generateHandoverLists(this.selectedHandover).then(({ data }) =>
        window.open(URL.createObjectURL(data)),
      );
    },
    fetchFolders(params) {
      return getHandoversSelect({
        ...params,
      }).then(({ data }) => {
        const results = data.results.map((item) => ({
          id: item.id,
          name: item.protocol_number,
        }));
        return { ...data, results };
      });
    },
    closeGenerateHandoverModal() {
      this.$emit("update:visible", false);
    },
    onHandoverSelect() {
      this.noSelectedIssueError = [];
    },
  },
};
</script>
