<template>
  <Section id="folders-content" :title="$t('archive.handovers.content')">
    <BaseTable
      :columns="columns"
      :service="fetchRelatedFolders"
      :customStore="customStore"
      :rowSelection="rowSelection"
    >
      <template #archive_category="{ record }">
        {{ record.archive_category_display }}
      </template>
      <template v-slot:button>
        <EODButton
          @click="manageFolderHandler"
          :disabled="rowSelection.selectedRowKeys.length < 1"
          :name="`${$t('archive.handovers.detach')} ${$tc(
            'archive.folders.folder',
            0,
          )}`"
          type="danger"
          ghost
          icon="DeleteOutlined"
          class="ml-1"
        />
      </template>
      <template #actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'FoldersPreview',
            params: { id: record.id },
          }"
          :showEdit="false"
          :showRemove="false"
        >
        </TableActions>
      </template>
    </BaseTable>
  </Section>
</template>
<script>
import { BaseTable, TableActions } from "@/components/table";
import { EODButton, Section } from "@/components/ui";
import {
  getFoldersRelatedToHandover,
  patchHandover,
} from "@/services/handovers";
import { DisconnectOutlined } from "@ant-design/icons-vue";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "HandoversContent",
  props: {
    id: { type: Number, default: null },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customStore: "handoverFolders",
      columns: [
        {
          title: this.$t("archive.folders.actSymbol"),
          dataIndex: "act_symbol",
          sorter: true,
        },
        {
          title: this.$t("archive.folders.category"),
          dataIndex: "archive_category",
          sorter: true,
          slots: { customRender: "archive_category" },
        },
        {
          title: this.$t("archive.folders.titleGridShort"),
          dataIndex: "description",
          sorter: true,
        },
        {
          title: this.$t("archive.folders.numberOfVolumes"),
          dataIndex: "number_of_volumes",
          sorter: true,
        },
        {
          title: this.$t("archive.folders.edgeDates"),
          slots: { customRender: "edge_dates" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
      rowSelection: {
        selectedRowKeys: [],
        onChange: this.onChange,
      },
    };
  },
  components: {
    BaseTable,
    EODButton,
    Section,
    TableActions,
    DisconnectOutlined,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    getFoldersRelatedToHandover,
    fetchRelatedFolders(params) {
      return getFoldersRelatedToHandover(this.id, params);
    },
    manageFolderHandler() {
      return getFoldersRelatedToHandover(this.id).then(({ data }) => {
        //TO DO due to bck change below function/or endpoint should be changed AWF-1115

        const idsToSend = data.results
          .filter(
            (item) => !this.rowSelection.selectedRowKeys.includes(item.id),
          )
          .map((item) => item.id);
        patchHandover({ folders: idsToSend }, this.id);
        if (this.$store.hasModule(this.customStore)) {
          this.$store.dispatch(`${this.customStore}/refreshTable`);
        }
      });
    },
    onChange(selectedRowKeys) {
      this.rowSelection.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
</style>
