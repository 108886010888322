<template>
  <Breadcrumbs
    :current="$t('modules.unitDocuments')"
    iconComponent="FileTextOutlined"
  >
    <Button
      @click="unleashEdward"
      :title="$t('documents.scansImport')"
      :loading="loading$"
    >
      {{ $t("documents.scansImport") }}
    </Button>

    <EODButtonLink
      v-if="hasPermissionToAdd"
      :title="$t('documents.add')"
      :to="{ name: 'DocumentsCreate' }"
    />
  </Breadcrumbs>

  <section class="section pt-050">
    <div class="filters">
      <EODSelect
        id="unit"
        v-model:value="filters.unit"
        :placeholder="$t('issues.unit')"
        :service="fetchUnits"
        @change="handleFilter"
      />
      <EODButton
        class="mr-1 mt-050"
        :name="$t('app.reset')"
        @click="handleFilterReset"
      />
      <EODButton
        class="mt-050"
        type="primary"
        :name="$t('app.filter')"
        @click="handleFilter"
      />
    </div>
  </section>

  <DocumentsList
    :customStore="customStore"
    :params="{ in_units: true, ...filters }"
  />
</template>

<script>
import { Breadcrumbs, EODButtonLink, EODButton } from "@/components/ui";
import { EODSelect } from "@/components/inputs";
import { Button } from "ant-design-vue";
import DocumentsList from "./DocumentsList";
import PERMISSIONS from "@/consts/permissions";
import { Tabs } from "ant-design-vue";
import { edward } from "@/services/documents/";
import { getUnitsFlatSelect } from "@/services/units";

export default {
  name: "UnitsDocuments",
  data() {
    return {
      customStore: "unitDocuments",
      filters: {
        unit: null,
      },
    };
  },
  components: {
    Breadcrumbs,
    Button,
    DocumentsList,
    EODButton,
    EODSelect,
    EODButtonLink,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS_ADD);
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  created() {
    if (!user.is_boss && !user.is_superior) {
      this.$router.push({ name: "403" });
    }
  },
  methods: {
    getUnitsFlatSelect,
    unleashEdward() {
      this.loading$ = true;
      edward()
        .then(({ data }) => {
          this.$message.success(data);
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    handleFilter() {
      this.$store.dispatch(`${this.customStore}/refreshTable`);
    },
    handleFilterReset() {
      this.filters = {
        unit: null,
      };
      this.handleFilter();
    },
    fetchUnits(params) {
      return getUnitsFlatSelect(params).then(({ data }) => data);
    },
  },
};
</script>
