<template>
  <div class="eod-editor">
    <label for="eod-editor" class="label">
      {{ label }}
      <span
        v-if="required"
        class="color--error"
        :title="$t('app.fieldRequired')"
      >
        &nbsp;*
      </span>
    </label>
    <textarea :id="`eod-editor-${id}`" :form="form" @invalid="onInvalid" />
    <span class="error-message color--error" v-if="errorsEditor">
      {{ errorsEditor[0] }}
    </span>
  </div>
</template>

<script>
import SimpleMDE from "simplemde";

export default {
  name: "EODEditor",
  props: {
    id: { type: String, default: "" },
    value: { type: String, default: null },
    options: {
      type: Object,
      default: () => ({}),
    },
    label: { type: String, default: "" },
    required: { type: Boolean, default: false },
    form: { type: String, default: "" },
    errors: { type: Array, default: null },
    preview: { type: String, default: null },
    maxlength: { type: Number, default: 5000 },
    addImage: { type: Boolean, default: true },
  },
  data() {
    return {
      editor: null,
      errorsEditor: null,
      toolbarConfig: [
        {
          name: "bold",
          action: SimpleMDE.toggleBold,
          className: "fa fa-bold",
          title: this.$t("editor.bold"),
        },
        {
          name: "italic",
          action: SimpleMDE.toggleItalic,
          className: "fa fa-italic",
          title: this.$t("editor.italic"),
        },
        {
          name: "heading",
          action: SimpleMDE.toggleHeadingSmaller,
          className: "fa fa-header",
          title: this.$t("editor.heading"),
        },
        "|",
        {
          name: "unordered-list",
          action: SimpleMDE.toggleUnorderedList,
          className: "fa fa-list-ul",
          title: this.$t("editor.ul"),
        },
        {
          name: "ordered-list",
          action: SimpleMDE.toggleOrderedList,
          className: "fa fa-list-ol",
          title: this.$t("editor.ol"),
        },
        "|",
        {
          name: "link",
          action: SimpleMDE.drawLink,
          className: "fa fa-link",
          title: this.$t("editor.link"),
        },
        "|",
        {
          name: "preview",
          action: SimpleMDE.togglePreview,
          className: "fa fa-eye no-disable",
          title: this.$t("app.preview"),
        },
        {
          name: "side-by-side",
          action: SimpleMDE.toggleSideBySide,
          className: "fa fa-columns no-disable no-mobile",
          title: this.$t("editor.sideBySide"),
        },
        {
          name: "fullscreen",
          action: SimpleMDE.toggleFullScreen,
          className: "fa fa-arrows-alt no-disable no-mobile",
          title: this.$t("editor.fullscreen"),
        },
        "|",
        {
          name: "guide",
          action: "https://simplemde.com/markdown-guide",
          className: "fa fa-question-circle",
          title: this.$t("editor.guide"),
        },
      ],
    };
  },
  emits: ["update:value", "invalid"],
  methods: {
    onInvalid(event) {
      this.$emit("invalid", event);
    },
  },
  mounted() {
    if (this.addImage) {
      this.toolbarConfig.splice(8, 0, {
        name: "image",
        action: SimpleMDE.drawImage,
        className: "fa fa-picture-o",
        title: this.$t("editor.image"),
      });
    }

    const options = {
      status: false,
      toolbar: this.toolbarConfig,
      spellChecker: false,
      ...this.options,
      element: document.getElementById(`eod-editor-${this.id}`),
    };

    this.editor = new SimpleMDE(options);

    if (this.preview !== null) {
      this.editor.value(this.preview);
      this.editor.togglePreview();
    }

    this.editor.codemirror.on("beforeChange", (cm, change) => {
      if (this.editor.value().length + 1 > this.maxlength) {
        if (change.origin === "+input") {
          change.cancel();
        }
        this.errorsEditor = this.errors
          ? this.errors
          : [`${this.$t("forms.gui.maxCharactersLength")} ${this.maxlength}`];
        this.$emit(
          "update:value",
          this.editor.value().slice(0, this.maxlength + 1),
        );
      } else {
        this.errorsEditor = null;
        this.$emit("update:value", this.editor.value());
      }
    });
  },
  watch: {
    preview(val) {
      this.editor.value(val);

      if (!this.editor.isPreviewActive()) {
        this.editor.togglePreview();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~simplemde/dist/simplemde.min.css";

.eod-editor {
  text-align: left;
  width: 600px;
  max-width: 100%;
  margin: 0.5rem;

  .error-message {
    display: block;
    text-align: center;
  }
}
</style>
