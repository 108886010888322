
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";
import { Checkbox, Radio, Select, Tooltip } from "ant-design-vue";
import {
  EODDatePicker,
  EODEditor,
  EODInput,
  EODInputMask,
  EODRadio,
  EODSelect,
  EODTreeSelect,
  JRWASelect,
} from "@/components/inputs";
import { computed, defineComponent, inject, toRefs } from "vue";
import { EODSeparator } from "@/components/ui";
import { MessageApi } from "ant-design-vue/lib/message";
import useFormBuilderSelects from "./useFormBuilderSelects";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "FormBuilderInputs",
  props: {
    fieldKey: { type: String, required: true },
    uniqueKey: { type: String, default: null },
    val: { type: Object, required: true },
    schemaUI: { type: Object, required: true },
    properties: { type: Object, required: true },
    required: { type: Array, required: true },
    errors: { type: Object, required: true },
    defaultValues: { type: Object, required: true },
    modelValue: { type: Object, required: true },
    modelAdditionalValues: { type: Object, required: true },
    modelSearchValue: { type: Object, required: true },
    treeSelectOptions: { type: Object, required: true },
    onLoadData: { type: Function, required: true },
    fetchTreeDictionary: { type: Function, required: true },
    onTreeSelectChange: { type: Function, required: true },
    treeComponents: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const $message = inject<MessageApi>("$message");
    const { t } = useI18n();

    const {
      modelAdditionalValues,
      fieldKey: key,
      modelValue,
      modelSearchValue,
      properties,
      schemaUI,
    } = toRefs(props);

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val: any) {
        emit("update:modelValue", val);
      },
    });

    const additionalValues = computed<Record<string, string | number>>({
      get() {
        return modelAdditionalValues.value;
      },
      set(val) {
        emit("update:modelAdditionalValues", val);
      },
    });

    const searchValue = computed({
      get() {
        return modelSearchValue.value;
      },
      set(val) {
        emit("update:modelSearchValue", val);
      },
    });

    const radioStyle = {
      display: "flex",
      marginLeft: "0",
      marginTop: "1rem",
      textAlign: "left",
    };

    const getInputType = (key: string) => {
      switch (schemaUI.value[key].component) {
        case "textarea": {
          return "textarea";
        }
        case "number": {
          return "number";
        }
        case "input": {
          const format = properties.value[key].format;
          if (format === "uri") {
            return "url";
          }
          return ["text", "email"].includes(format) ? format : "text";
        }
        default: {
          return "text";
        }
      }
    };

    const disabledDate = (current: Date) => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return current < yesterday;
    };

    const calculateValue = (key: string) => {
      if (/^[-\d[(]+[ .\d+\-/*()[\]]*$/.test(schemaUI.value[key].calc.value)) {
        // prettier-ignore
        // eslint-disable-next-line no-unused-vars
        const variables = schemaUI.value[
          key
        ].calc.variables.map((item: string) =>
          parseFloat(additionalValues.value[item] ?? model.value[item]),
        );

        try {
          const val = eval(
            schemaUI.value[key].calc.value.replace(/\[/g, "variables["),
          )?.toFixed(schemaUI.value[key].calc.precision);
          if (!isNaN(val)) {
            model.value[key] = val;
            return val;
          }
        } catch (err) {
          // do nothing
        }
      } else {
        $message.error(t("forms.gui.calcError"));
      }
      return undefined;
    };

    return {
      ...useFormBuilderSelects(schemaUI, additionalValues),
      calculateValue,
      disabledDate,
      getInputType,
      key,
      model,
      radioStyle,
      searchValue,
    };
  },
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
    Checkbox,
    CheckboxGroup: Checkbox.Group,
    EODDatePicker,
    EODEditor,
    EODInput,
    EODInputMask,
    EODRadio,
    EODSelect,
    EODSeparator,
    EODTreeSelect,
    InfoCircleOutlined,
    JRWASelect,
    Radio,
    SelectOption: Select.Option,
    Tooltip,
  },
  emits: [
    "update:modelAdditionalValues",
    "update:modelSearchValue",
    "update:modelValue",
  ],
});
