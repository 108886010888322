<template>
  <section class="section pt-050">
    <div class="filters">
      <EODSelect
        id="unit"
        v-model:value="filters.unit"
        :placeholder="$t('issues.unit')"
        :service="fetchUnits"
        @change="handleFilter"
      />
    </div>
  </section>

  <section class="section">
    <BaseTable
      :service="(params) => getHandovers({ ...params, ...filters })"
      :columns="columns"
      :customStore="customStore"
      :rowSelection="rowSelection"
      emitData
    >
      <template #status="{ record }">
        <StatusDot
          :status="record.status"
          :colorsMap="{
            3: 'color--success',
            2: 'color--error',
            1: 'color--grey',
          }"
        />
        {{ record.status_display }}
      </template>
      <template v-slot:button>
        <EODButton
          @click="handleMultipleHandoverGeneration"
          :name="$t('archive.handovers.generateHandoverLists')"
          :disabled="this.rowSelection.selectedRowKeys.length < 1"
          class="ml-1"
        />
      </template>
      <template v-slot:protocol_type_display="{ record }">
        <Tooltip
          placement="top"
          :title="record.protocol_type_display"
          v-if="record.protocol_type === handoverType.CASUAL"
        >
          <FileOutlined />
        </Tooltip>
        <Tooltip
          placement="top"
          :title="record.protocol_type_display"
          v-if="record.protocol_type === handoverType.PHOTO_DOCUMENTATION"
        >
          <UsbOutlined />
        </Tooltip>
        <Tooltip
          placement="top"
          :title="record.protocol_type_display"
          v-if="record.protocol_type === handoverType.IT_MEDIA"
        >
          <PictureOutlined />
        </Tooltip>
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :customStore="customStore"
          :previewRoute="{
            name: 'HandoversPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'HandoversEdit',
            params: { id: record.id },
          }"
          :deleteMessage="
            $t('app.doYouWantToDelete_', [
              $tc('archive.handovers.handoverLists', 0),
            ])
          "
          :modalTitle="
            $t('app.delete_', [$tc('archive.handovers.handoverLists', 0)])
          "
          :deleteService="deleteHandover"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import {
  getHandovers,
  deleteHandover,
  bulkHandoverGeneration,
} from "@/services/handovers";

import { EODButton } from "@/components/ui";
import { EODSelect } from "@/components/inputs";
import { HANDOVER_TYPE } from "@/consts/statuses";
import dynamicTable from "@/store/modules/dynamicTable";
import { getEmployeeUnits } from "@/services/units";
import {
  FileOutlined,
  UsbOutlined,
  PictureOutlined,
} from "@ant-design/icons-vue";
import { Tooltip } from "ant-design-vue";

export default {
  name: "HandoverLists",
  props: {
    customStore: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedItemsAsObjects: [],
      documentName: "",
      selectedItems: null,
      filters: {
        unit: null,
      },
      folderStatuses: [],
      handoverType: HANDOVER_TYPE,
      columns: [
        {
          dataIndex: "protocol_type_display",
          slots: { customRender: "protocol_type_display" },
          width: "5%",
        },
        {
          title: this.$t("archive.handovers.handoverCreationDate"),
          dataIndex: "filing_date",
          sorter: true,
        },
        {
          title: this.$t("archive.handovers.handoverCreationPerson"),
          dataIndex: "user_display",
          sorter: true,
        },
        {
          title: this.$t("archive.handovers.handoverApprovalDate"),
          dataIndex: "confirmation_date",
          sorter: true,
        },
        {
          title: this.$t("archive.handovers.handoverNumber"),
          dataIndex: "protocol_number",
          sorter: true,
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
      rowSelection: {
        selectedRowKeys: [],
        onChange: this.onChange,
      },
    };
  },
  components: {
    BaseTable,
    EODButton,
    EODSelect,
    StatusDot,
    TableActions,
    FileOutlined,
    UsbOutlined,
    PictureOutlined,
    Tooltip,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    getHandovers,
    deleteHandover,

    handleFilter() {
      this.$store.dispatch(`${this.customStore}/refreshTable`);
    },

    handleResetFilter() {
      this.filters = {
        unit: null,
      };
      this.handleFilter();
    },
    onChange(selectedRowKeys) {
      if (this.rowSelection.selectedRowKeys.length < selectedRowKeys.length) {
        const addedItems = selectedRowKeys.filter(
          (item) => !this.rowSelection.selectedRowKeys.includes(item),
        );
        const selectedItemsAsObjects = [];
        addedItems.forEach((selectedItem) => {
          selectedItemsAsObjects.push(
            this.$store.getters[`${this.customStore}/getData`].results.find(
              (document) => document.id == selectedItem,
            ),
          );
        });

        this.selectedItemsAsObjects = this.selectedItemsAsObjects.concat(
          selectedItemsAsObjects,
        );
      } else {
        const deletedItems = this.rowSelection.selectedRowKeys.filter(
          (item) => !selectedRowKeys.includes(item),
        );
        this.selectedItemsAsObjects = this.selectedItemsAsObjects.filter(
          (item) => !deletedItems.includes(item.id),
        );
      }
      this.rowSelection.selectedRowKeys = selectedRowKeys;
    },
    handleMultipleHandoverGeneration() {
      this.isLoading = true;

      bulkHandoverGeneration(this.rowSelection.selectedRowKeys)
        .then(({ data }) => window.open(URL.createObjectURL(data)))
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchUnits(params) {
      return getEmployeeUnits(params).then(({ data }) => data);
    },
  },
};
</script>
