<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Deployments' }, title: $t('modules.deployments') },
    ]"
    :current="$t('deployments.preview')"
    iconComponent="SyncOutlined"
  >
    <EODButton
      v-if="id && hasExportPermission"
      :name="$t('deployments.export')"
      @click="exportDeployment([id])"
    />
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'DeploymentEdit', params: { id } }"
      :title="$t('deployments.meta.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('deployments.preview')"
    :noPreviewMsg="$t('deployments.noPreview')"
  />
</template>

<script>
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";

import PERMISSIONS from "@/consts/permissions";
import { Preview } from "@/components/common";
import { getDeployment } from "@/services/deployments";
import { ref } from "vue";
import useExportDeployments from "@/composables/useExportDeployments";
import { useI18n } from "vue-i18n";

export default {
  name: "DeploymentsPreview",
  props: { id: Number },
  setup(props) {
    const { t } = useI18n();

    const previewData = ref([]);

    if (props.id) {
      getDeployment(props.id).then(({ data }) => {
        previewData.value = [
          { title: t("app.name"), value: data.name },
          {
            title: t("app.status"),
            value: data.status_display,
          },
          {
            title: t("app.version"),
            value: data.version,
          },
          {
            title: t("app.key"),
            value: data.camunda_key,
          },
          {
            title: t("app.description"),
            value: data.description,
          },
        ];
      });
    }

    return { previewData, ...useExportDeployments() };
  },
  components: { Breadcrumbs, EODButton, EODButtonLink, Preview },
  computed: {
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.DEPLOYMENTS_EDIT);
    },
  },
};
</script>
