import i18n from "@/i18n";

const t = i18n.global.t;

const RESULT_TYPES = {
  DOCUMENTS: "documents",
  ATTACHMENTS: "attachments",
  ISSUES: "issues",
  PROCESSES: "processes",
};

const SEARCH_FIELD_TYPE = {
  DOCUMENTS: "document",
  ATTACHMENT: "attachment",
  DEPLOYMENT: "deployment",
  FORM_FIELD: "form_field",
};

const GET_NEW_FORM = () => {
  return {
    attachmentType: {
      value: null,
      operation: "contain",
      operator: "and",
      field: "attachment_extension",
    },
    documents: [
      {
        id: 0,
        field: null,
        value: "",
        operation: "contain",
        operator: "or",
        date: false,
      },
    ],
    attachments: [
      {
        id: 0,
        field: null,
        value: "",
        operation: "contain",
        operator: "or",
        date: false,
      },
    ],
    deployment: {
      value: null,
      operation: "contain",
      operator: null,
      field: "deployment_id",
    },
    processesStatus: {
      value: null,
      operation: "contain",
      operator: "and",
      field: "active",
    },
    formFields: [
      {
        id: 0,
        field: null,
        value: "",
        operation: "contain",
        operator: "or",
        date: false,
      },
    ],
    modified: null,
  };
};

const EMPTY_ERRORS = () => {
  return {
    documents: [],
    attachments: [],
    formFields: [],
  };
};

const DOCUMENTS_FIELDS_LIST = [
  {
    id: "document_date",
    name: t("documents.date"),
    date: true,
  },
  {
    id: "document_jrwa_name",
    name: t("documents.JRWANumber"),
  },
  {
    id: "document_issue_signature",
    name: t("documents.caseNumber"),
  },
  {
    id: "document_name",
    name: t("app.name"),
  },
  {
    id: "document_description",
    name: t("app.description"),
  },
  {
    id: "document_type",
    name: t("app.type"),
  },
];

const ATTACHMENTS_FIELDS_LIST = [
  {
    id: "attachment_name",
    name: t("app.name"),
  },
  {
    id: "attachment_description",
    name: t("app.description"),
  },
  {
    id: "attachment_content",
    name: t("app.content"),
  },
];

const OPERATORS_LIST = [
  {
    id: "or",
    name: t("search.operators.or"),
  },
  {
    id: "and",
    name: t("search.operators.and"),
  },
];

const OPERATION_LIST = [
  {
    id: "contain",
    name: t("forms.conditionalForm.inc"),
  },
  {
    id: "not_contain",
    name: t("forms.conditionalForm.dec"),
  },
  {
    id: "empty",
    name: t("search.operators.isEmpty"),
  },
  {
    id: "not_empty",
    name: t("search.operators.isNotEmpty"),
  },
  {
    id: "equal",
    name: t("forms.conditionalForm.eq"),
  },
  {
    id: "regex",
    name: t("search.operators.regex"),
  },
];

const OPERATION_LIST_FOR_DATE = [
  {
    id: "contain",
    name: t("forms.conditionalForm.inc"),
  },
  {
    id: "not_contain",
    name: t("forms.conditionalForm.dec"),
  },
  {
    id: "from",
    name: t("search.operators.from"),
  },
  {
    id: "to",
    name: t("search.operators.to"),
  },
];

const PROCESSES_STATUS_LIST = [
  {
    id: null,
    name: t("app.all"),
  },
  {
    id: "true",
    name: t("app.active"),
  },
  {
    id: "false",
    name: t("app.completed"),
  },
];

const UPDATE_TYPES_LIST = [
  {
    id: null,
    name: t("search.updatedTypes.ever"),
  },
  {
    id: "now-1d",
    name: t("search.updatedTypes.last24"),
  },
  {
    id: "now-1w",
    name: t("search.updatedTypes.lastWeek"),
  },
  {
    id: "now-1M",
    name: t("search.updatedTypes.lastMonth"),
  },
  {
    id: "now-1y",
    name: t("search.updatedTypes.lastYear"),
  },
];

const FILE_TYPES_LIST = [
  {
    id: null,
    name: t("search.attachmentTypes.any"),
  },
  {
    id: "PICTURE",
    name: t("search.attachmentTypes.image"),
  },
  {
    id: "TEXT",
    name: t("search.attachmentTypes.text"),
  },
  {
    id: "EXCEL",
    name: t("search.attachmentTypes.spreadsheet"),
  },
  {
    id: "COMPRESSED",
    name: t("search.attachmentTypes.compressed"),
  },
  {
    id: "OTHER",
    name: t("search.attachmentTypes.other"),
  },
];

const IS_RULE_COMPLETE = (rule) => {
  return (
    (rule.value !== "" ||
      rule.operation === "empty" ||
      rule.operation === "not_empty") &&
    rule.field !== null
  );
};

const IS_VALUE_DISABLED = (operation) => {
  return operation === "empty" || operation === "not_empty";
};

const GET_FILE_TYPE_FROM_EXTENSION_LIST = (extensionList) => {
  if (
    extensionList.includes("jpg") &&
    extensionList.includes("txt") &&
    extensionList.includes("xls") &&
    extensionList.includes("tar")
  ) {
    return "OTHER";
  } else if (extensionList.includes("jpg")) {
    return "PICTURE";
  } else if (extensionList.includes("txt")) {
    return "TEXT";
  } else if (extensionList.includes("xls")) {
    return "EXCEL";
  } else if (extensionList.includes("tar")) {
    return "COMPRESSED";
  } else {
    return null;
  }
};

export {
  RESULT_TYPES,
  SEARCH_FIELD_TYPE,
  GET_NEW_FORM,
  EMPTY_ERRORS,
  DOCUMENTS_FIELDS_LIST,
  ATTACHMENTS_FIELDS_LIST,
  OPERATORS_LIST,
  OPERATION_LIST,
  OPERATION_LIST_FOR_DATE,
  PROCESSES_STATUS_LIST,
  UPDATE_TYPES_LIST,
  FILE_TYPES_LIST,
  IS_VALUE_DISABLED,
  IS_RULE_COMPLETE,
  GET_FILE_TYPE_FROM_EXTENSION_LIST,
};
