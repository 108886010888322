<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Forms' }, title: $t('modules.forms') }]"
    :current="title"
    iconComponent="FormOutlined"
    :what="$tc('forms.form', 0)"
  />

  <Modal
    v-model:visible="showPreview"
    :title="$t('forms.preview')"
    @ok="closeModal"
    @cancle="closeModal"
  >
    <FormBuilder
      v-if="showPreview"
      v-model:modelValue="model"
      :schema="data.schema"
      :schemaUI="data.schema_ui"
      :preview="true"
    />
  </Modal>

  <Section :title="$t('contractors.basicInfo')">
    <div class="inputs">
      <EODInput
        id="name"
        v-model:value="data.name"
        :required="true"
        :errors="errors.name"
        :label="$t('app.name')"
        :props="{ maxlength: 250, form: 'form-builder' }"
      />

      <EODRadio
        id="status"
        form="form-builder"
        :label="$t('app.status')"
        v-model:value="data.status"
        required
        :options="statusOptions"
        :errors="errors.status"
      />

      <EODInput
        id="description"
        type="textarea"
        v-model:value="data.description"
        :label="$t('app.description')"
        :props="{ maxlength: 500, form: 'form-builder' }"
        :errors="errors.description"
      />
    </div>
  </Section>

  <Section
    :title="$t('forms.gui.createForm')"
    :info="$t('forms.gui.createFormInfo')"
  >
    <template #button>
      <EODButton
        htmlType="button"
        :title="$t('app.preview')"
        @click="togglePreview"
        icon="EyeOutlined"
        :name="$t('app.preview')"
      />
    </template>

    <div class="forms-builder">
      <Tabs class="form-config" v-model:activeKey="activeTab" :animated="false">
        <TabPane key="1" :tab="$t('forms.gui.addField')" class="pa-1 columns">
          <div class="column">
            <EODSeparator class="pr-075" :title="$t('forms.gui.basicFields')" />
            <InputSet
              :inputList="inputList.slice(0, 6)"
              v-model:globalID="globalID"
            />
            <EODSeparator
              class="pr-075"
              :title="$t('forms.gui.choiceFields')"
            />
            <InputSet
              :inputList="inputList.slice(6, 13)"
              v-model:globalID="globalID"
            />
          </div>
          <div class="column">
            <EODSeparator class="pr-075" :title="$t('forms.gui.dateFields')" />
            <InputSet
              :inputList="inputList.slice(13, 17)"
              v-model:globalID="globalID"
            />
            <EODSeparator
              class="pr-075"
              :title="$t('forms.gui.specialFields')"
            />
            <InputSet
              :inputList="inputList.slice(17)"
              v-model:globalID="globalID"
            />
          </div>
        </TabPane>
        <TabPane
          key="2"
          :tab="$t('forms.gui.fieldSettings')"
          :disabled="fieldsList.length === 0"
          forceRender
        >
          <form id="form-builder" @submit.prevent="submit">
            <template v-for="elem in globalFieldsList" :key="elem.id">
              <div v-show="elem === selectedField" class="pa-1">
                <EODInput
                  v-if="elem.ui.component !== 'jrwa'"
                  :id="`${elem.id}-title`"
                  v-model:value="elem.title"
                  :required="
                    !['separator', 'info', 'infoCheck'].includes(
                      elem.ui.component,
                    )
                  "
                  :label="$t('forms.gui.fieldName')"
                  @invalid="onInvalid"
                  :props="{ maxlength: 150 }"
                />

                <EODRadio
                  v-if="
                    ![
                      'numeration',
                      'separator',
                      'currentDate',
                      'calc',
                      'info',
                      'multiValue',
                    ].includes(elem.ui.component)
                  "
                  :id="`${elem.id}-required`"
                  v-model:value="elem.required"
                  :required="true"
                  :label="$t('forms.gui.required')"
                  :options="[
                    { id: true, name: $t('forms.gui.required') },
                    { id: false, name: $t('forms.gui.notRequired') },
                  ]"
                  @invalid="onInvalid"
                />

                <EODSeparator />

                <EODInput
                  v-if="elem.ui.component !== 'separator'"
                  :id="`${elem.id}-property-name`"
                  v-model:value="elem.propertyName"
                  :label="$t('forms.gui.propertyName')"
                  :placeholder="
                    data.in_use ? '' : $t('forms.gui.propertyPlaceholder')
                  "
                  pattern="(?!^(\d+)$|^numeration$|^jrwa$|^document_name$|^dueDate$|^priority$|^candidate_users$|^candidate_groups$|^candidate_units$|^candidateGroups$|^deployment_name$|^separator-)^[^ {}]+$"
                  :disabled="
                    (data.in_use && originalFields.includes(elem.id)) ||
                    [
                      'numeration',
                      'jrwa',
                      'dueDate',
                      'prioritySelect',
                      'userDelegation',
                      'groupDelegation',
                      'unitDelegation',
                      'currentUser',
                      'allUnits',
                      'usersUnits',
                    ].includes(elem.ui.component)
                  "
                  @invalid="onInvalid"
                  :props="{ maxlength: 200 }"
                >
                  <Tooltip :title="$t('forms.gui.propertyNameInfo')">
                    <InfoCircleOutlined />
                  </Tooltip>
                </EODInput>

                <EODInput
                  v-if="
                    ['input', 'textarea', 'number'].includes(elem.ui.component)
                  "
                  :id="`${elem.id}-default`"
                  v-model:value="elem.default"
                  :type="elem.ui.component === 'number' ? 'number' : 'text'"
                  allowClear
                  :label="$t('forms.gui.default')"
                  @invalid="onInvalid"
                  :props="{ maxlength: 250 }"
                />

                <EODDatePicker
                  v-if="['date', 'dueDate'].includes(elem.ui.component)"
                  :id="`${elem.id}-default`"
                  v-model:value="elem.default"
                  allowClear
                  :label="$t('forms.gui.default')"
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="
                    ![
                      'radio',
                      'checkbox',
                      'daterange',
                      'numeration',
                      'separator',
                      'currentDate',
                      'info',
                      'infoCheck',
                      'multiValue',
                    ].includes(elem.ui.component)
                  "
                  :id="`${elem.id}-placeholder`"
                  v-model:value="elem.ui.props.placeholder"
                  allowClear
                  :label="$t('forms.gui.placeholder')"
                  @invalid="onInvalid"
                  :props="{ maxlength: 45 }"
                />

                <EODInput
                  v-if="
                    !['separator', 'info', 'multiValue'].includes(
                      elem.ui.component,
                    )
                  "
                  :id="`${elem.id}-description`"
                  v-model:value="elem.description"
                  type="textarea"
                  allowClear
                  :required="elem.ui.component === 'info'"
                  :label="
                    elem.ui.component === 'info'
                      ? $t('forms.gui.infoContent')
                      : $t('app.description')
                  "
                  @invalid="onInvalid"
                  :props="{ maxlength: 500 }"
                />

                <EODEditor
                  v-if="elem === selectedField && elem.ui.component === 'info'"
                  :id="`${elem.id}-description`"
                  required
                  :label="$t('forms.gui.infoContent')"
                  v-model:value="elem.description"
                  :options="{ initialValue: elem.description }"
                  :addImage="false"
                  @invalid="onInvalid"
                />

                <EODEditor
                  v-if="
                    elem === selectedField && elem.ui.component === 'infoCheck'
                  "
                  :id="`${elem.id}-checkbox-title`"
                  required
                  :label="$t('forms.gui.checkboxTitle')"
                  v-model:value="elem.ui.checkboxTitle"
                  :options="{ initialValue: elem.ui.checkboxTitle }"
                  :addImage="false"
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="['input', 'textarea'].includes(elem.ui.component)"
                  :id="`${elem.id}-pattern`"
                  v-model:value="elem.pattern"
                  :label="$t('forms.gui.pattern')"
                  allowClear
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="elem.ui.component === 'bankAccount'"
                  :id="`${elem.id}-mask`"
                  v-model:value="elem.ui.mask"
                  :label="$t('forms.gui.mask')"
                  required
                  @invalid="onInvalid"
                >
                  <Tooltip>
                    <template #title>
                      <div>
                        {{ $t("forms.gui.maskInfo") }}
                        <ul>
                          <li>a - {{ $t("forms.gui.alpha") }}</li>
                          <li>9 - {{ $t("forms.gui.numeric") }}</li>
                          <li>* - {{ $t("forms.gui.alphanumeric") }}</li>
                        </ul>
                      </div>
                    </template>
                    <InfoCircleOutlined />
                  </Tooltip>
                </EODInput>

                <EODInput
                  v-if="
                    ['input', 'textarea', 'bankAccount'].includes(
                      elem.ui.component,
                    )
                  "
                  :id="`${elem.id}-minLength`"
                  type="number"
                  :min="0"
                  :max="500"
                  allowClear
                  v-model:value="elem.minLength"
                  :label="$t('forms.gui.minLength')"
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="
                    ['input', 'textarea', 'bankAccount'].includes(
                      elem.ui.component,
                    )
                  "
                  :id="`${elem.id}-maxLength`"
                  type="number"
                  :min="0"
                  :max="500"
                  allowClear
                  v-model:value="elem.maxLength"
                  :label="$t('forms.gui.maxLength')"
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="elem.ui.component === 'number'"
                  :id="`${elem.id}-minimum`"
                  type="number"
                  allowClear
                  v-model:value="elem.minimum"
                  :label="$t('forms.gui.minimum')"
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="elem.ui.component === 'number'"
                  :id="`${elem.id}-maximum`"
                  type="number"
                  :min="elem.minimum ?? undefined"
                  allowClear
                  v-model:value="elem.maximum"
                  :label="$t('forms.gui.maximum')"
                  @invalid="onInvalid"
                />

                <EODInput
                  v-if="elem.ui.component === 'number'"
                  :id="`${elem.id}-step`"
                  type="number"
                  :min="0.001"
                  :max="100"
                  :step="0.001"
                  required
                  v-model:value="elem.multipleOf"
                  :label="$t('forms.gui.step')"
                  @invalid="onInvalid"
                />

                <EODSelect
                  v-if="
                    ['dictSelect', 'treeSelect', 'multiSelect'].includes(
                      elem.ui.component,
                    )
                  "
                  storeID="forms-dictionaries"
                  :id="`${elem.id}-dictionaryID`"
                  required
                  v-model:value="elem.ui.dictionaryID"
                  :service="fetchDictionaries"
                  :defaultValue="defaultValues[elem.id]"
                  :label="$t('dictionaries.entries.dictionary')"
                />

                <EODSelect
                  v-if="elem.ui.component === 'systemSelect'"
                  storeID="forms-system-dictionaries"
                  :id="`${elem.id}-dictionaryKeyword`"
                  required
                  v-model:value="elem.ui.dictionaryKeyword"
                  :service="fetchSystemDictionaries"
                  :defaultValue="defaultValues[elem.id]"
                  :label="$t('dictionaries.entries.dictionary')"
                />

                <EODSelect
                  v-if="elem.ui.component === 'numeration'"
                  :id="`${elem.id}-numeration`"
                  storeID="forms-numerations"
                  required
                  v-model:value="elem.ui.numerationID"
                  :service="fetchNumerations"
                  :defaultValue="defaultValues[elem.id]"
                  :label="$t('modules.numeration')"
                />

                <EODInput
                  v-if="
                    componentsWithStrRepresentation.includes(elem.ui.component)
                  "
                  :id="`${elem.id}-string-representation`"
                  required
                  v-model:value="elem.stringRepresentation"
                  :placeholder="
                    $t('forms.gui.possibleValuesSemicolonSeparated')
                  "
                  :label="$t('forms.gui.possibleValues')"
                  @invalid="onInvalid"
                />

                <EODRadio
                  v-if="elem.ui.component === 'separator'"
                  :id="`${elem.id}-showLine`"
                  v-model:value="elem.ui.props.showLine"
                  :required="true"
                  :label="$t('forms.gui.showLine')"
                  :options="radioOptions"
                  @invalid="onInvalid"
                />

                <EODSelect
                  v-if="elem.ui.component === 'calc'"
                  :id="`${elem.id}-variables`"
                  v-model:value="elem.ui.calc.variables"
                  mode="multiple"
                  :label="$t('forms.gui.variables')"
                  optionLabelProp="label"
                  @invalid="onInvalid"
                >
                  <template #tooltip>
                    <Tooltip :title="$t('forms.gui.calcVariablesInfo')">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </template>
                  <SelectOption
                    v-for="option in numberFieldsList"
                    :key="option.id"
                    :value="`${option.propertyName || option.id}`"
                    :label="`[${elem.ui.calc.variables.indexOf(
                      `${option.propertyName || option.id}`,
                    )}] : ${option.title}`"
                  >
                    {{ option.title }}
                  </SelectOption>
                </EODSelect>

                <EODInput
                  v-if="elem.ui.component === 'calc'"
                  :id="`${elem.id}-value`"
                  v-model:value="elem.ui.calc.value"
                  required
                  pattern="^[-\d[(]+[ .\d+\-/*()[\]]*$"
                  :label="$t('app.value')"
                  @invalid="onInvalid"
                >
                  <Tooltip :title="$t('forms.gui.calcValueInfo')">
                    <InfoCircleOutlined />
                  </Tooltip>
                </EODInput>

                <EODInput
                  v-if="elem.ui.component === 'calc'"
                  :id="`${elem.id}-precision`"
                  v-model:value="elem.ui.calc.precision"
                  required
                  type="number"
                  :min="0"
                  :max="5"
                  :label="$t('forms.gui.precision')"
                  @invalid="onInvalid"
                >
                  <Tooltip :title="$t('forms.gui.precisionInfo')">
                    <InfoCircleOutlined />
                  </Tooltip>
                </EODInput>

                <EODInput
                  v-if="
                    elem.ui.component === 'multiValue' &&
                    elem.ui.multiValueType !== 'address'
                  "
                  :id="`${elem.id}-button-title`"
                  v-model:value="elem.ui.buttonTitle"
                  required
                  :label="$t('forms.gui.buttonTitle')"
                  @invalid="onInvalid"
                  :props="{ maxlength: 40 }"
                />
              </div>
            </template>
          </form>
          <div v-if="!selectedField" class="choose-info">
            {{ $t("forms.gui.chooseFieldToChangeSettings") }}
          </div>
        </TabPane>
        <TabPane
          key="3"
          :tab="$t('forms.gui.fieldDependencies')"
          :disabled="fieldsList.length === 0"
        >
          <ConditionalRenderingForm
            v-if="selectedField"
            class="pa-1"
            v-model:fieldsList="fieldsList"
            v-model:selectedField="selectedField"
          />
          <div v-else class="choose-info">
            {{ $t("forms.gui.chooseFieldToChangeDependencies") }}
          </div>
        </TabPane>
      </Tabs>

      <Draggable
        :list="fieldsList"
        :group="{ name: 'tiles', pull: false }"
        class="form-blueprint"
      >
        <template v-if="fieldsList.length > 0">
          <div v-for="elem in fieldsList" :key="elem.id">
            <FormTile
              :elem="elem"
              :handleSelectedField="handleSelectedField"
              :removeItem="
                (id) => {
                  fieldsList = removeItem(id, fieldsList);
                }
              "
              :class="[
                selectedField === elem ? 'selected' : '',
                elem.ui.component === 'multiValue' ? 'mb-0' : '',
              ]"
            />
            <Draggable
              v-if="elem.ui.component === 'multiValue'"
              :list="elem.subfields"
              :group="{ name: 'tiles', pull: false }"
              class="subtiles"
            >
              <template v-if="elem.subfields.length > 0">
                <template v-for="subfield in elem.subfields" :key="subfield.id">
                  <FormTile
                    :elem="subfield"
                    :handleSelectedField="handleSelectedField"
                    :removeItem="
                      (id) => {
                        elem.subfields = removeItem(id, elem.subfields);
                      }
                    "
                    :class="selectedField === subfield ? 'selected' : ''"
                    multiValue
                  />
                </template>
              </template>
              <div v-else class="color--font-secondary mt-050">
                {{ $t("forms.gui.dragToAddFields") }}
              </div>
            </Draggable>
          </div>
        </template>
        <div class="drop-info" v-else>
          <h1 class="color--primary">{{ $t("forms.gui.addFieldsHere") }}</h1>
          <p>
            {{ $t("forms.gui.addFieldsInfo") }}
          </p>
        </div>
      </Draggable>
    </div>
  </Section>

  <Button
    class="mb-2"
    form="form-builder"
    htmlType="submit"
    type="primary"
    :title="$t('app.save')"
    :loading="loading$"
  >
    {{ $t("app.save") }}
  </Button>
</template>

<script>
import { Breadcrumbs, EODButton, EODSeparator, Section } from "@/components/ui";
import { Button, Modal, Select, Tabs, Tooltip } from "ant-design-vue";
import {
  ConditionalRenderingForm,
  CustomAjv,
  FormBuilder,
  FormTile,
  InputSet,
  formIcons,
  formsErrorHandler,
  generateCondition,
  generateSchemaDraft,
} from "@/components/forms";
import {
  EODDatePicker,
  EODEditor,
  EODInput,
  EODRadio,
  EODSelect,
} from "@/components/inputs";
import {
  config,
  iconsMap,
  namesMap,
} from "@/components/forms/formInputsConfig";
import {
  createForm,
  editForm,
  getForm,
  getFormStatuses,
} from "@/services/forms";

import { STATUS } from "@/consts/statuses";
import SchemaUISchema from "@/schemas/schemaUI.schema.json";
import { VueDraggableNext } from "vue-draggable-next";
import { getDictionariesSelect } from "@/services/dictionaries";
import { getNumerationSelect } from "@/services/numeration";
import stringToUniqueArray from "@/helpers/stringToUniqueArray";

export default {
  name: "FormsForms",
  data() {
    return {
      statusOptions: [],
      data: {
        name: "",
        status: STATUS.ACTIVE,
        description: "",
        schema: { properties: {} },
        schema_ui: {},
        in_use: false,
      },
      model: {},
      errors: {},
      id: +this.$route.params?.id,
      copyForm: +this.$route.query?.createFrom,
      ajv: new CustomAjv(),
      showPreview: false,
      inputList: config,
      fieldsList: [],
      globalID: 1,
      selectedField: null,
      radioOptions: [
        { id: true, name: this.$t("app.yes") },
        { id: false, name: this.$t("app.no") },
      ],
      originalFields: [],
      activeTab: "1",
      defaultValues: { country: { id: "address_country", name: "kraj" } },
      componentsWithStrRepresentation: ["simpleSelect", "radio", "checkbox"],
    };
  },
  components: {
    Breadcrumbs,
    Button,
    ConditionalRenderingForm,
    Draggable: VueDraggableNext,
    EODButton,
    EODDatePicker,
    EODEditor,
    EODInput,
    EODRadio,
    EODSelect,
    EODSeparator,
    FormBuilder,
    FormTile,
    InputSet,
    Modal,
    Section,
    SelectOption: Select.Option,
    TabPane: Tabs.TabPane,
    Tabs,
    Tooltip,
    ...formIcons,
  },
  computed: {
    action() {
      return this.id ? this.$t("app.editAction") : this.$t("app.addAction");
    },
    title() {
      return `${
        this.id ? this.$t("app.editActivity") : this.$t("app.addition")
      } ${this.$tc("forms.form", 0)}`;
    },
    numberFieldsList() {
      return this.fieldsList.filter(
        (item) =>
          ["number", "dictSelect", "systemSelect", "treeSelect"].includes(
            item.ui.component,
          ) && item.required,
      );
    },
    globalFieldsList() {
      return this.fieldsList.reduce(
        (acc, item) =>
          item.ui.component === "multiValue"
            ? [...acc, item, ...item.subfields]
            : [...acc, item],
        [],
      );
    },
  },
  created() {
    getFormStatuses().then(({ data }) => {
      this.statusOptions = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });

    if (this.id || this.copyForm) {
      this.fetchForm();
    }
  },
  methods: {
    generateSchema(preview = false) {
      const schema = {
        $schema: "http://json-schema.org/draft-07/schema#",
        title: this.data.name,
        description: this.data.description,
        type: "object",
        properties: {},
        allOf: [],
        required: [],
      };
      const schemaUI = {};

      // TODO: check uniqueness globally?
      if (!this.checkUniqueness()) {
        return;
      }

      if (!this.parseData(schema, schemaUI, this.fieldsList)) {
        return;
      }

      this.data.schema = schema;
      this.data.schema_ui = schemaUI;
      this.data.schema_draft = generateSchemaDraft(schema);

      if (preview) {
        this.showPreview = true;
      }

      return true;
    },
    parseData(schema, schemaUI, fieldsList) {
      // check if there is no more than one numeration field and it has all required fields filled
      const numerationsListFiltered = this.fieldsList.filter(
        (item) => item.ui.component === "numeration",
      );

      if (numerationsListFiltered.length > 1) {
        this.$message.error(
          this.$t("forms.gui.oneFieldError_", [
            this.$tc("numeration.numeration", 2),
          ]),
        );
        return;
      } else if (
        numerationsListFiltered.length === 1 &&
        !numerationsListFiltered[0].ui.numerationID
      ) {
        // TODO: fix required select
        this.$message.error(this.$t("forms.gui.numerationRequiredError"));
        this.fieldsList.find(
          (elem) => elem.id === numerationsListFiltered[0].id,
        ).show = true;
        document
          .getElementById(`${numerationsListFiltered[0].id}-numeration`)
          .focus();
        return;
      }

      for (const [index, elem] of fieldsList.entries()) {
        /* eslint-disable no-unused-vars */
        const {
          id,
          propertyName,
          ui,
          required,
          name,
          show,
          icon,
          stringRepresentation,
          subfields,
          ...rest
        } = elem;
        /* eslint-enable no-unused-vars */

        let key = propertyName || id;

        // check if there are no duplicates in property names
        if (schemaUI[key]) {
          this.$message.error(
            this.$t("forms.gui.cantDuplicateProperty_", [key]),
          );
          return;
        }

        if (key === "previous_user" && required) {
          this.$message.error(
            this.$t("forms.gui.previousUserCannotBeRequired"),
          );
          return;
        }

        // if the condition exists, set proper key value to property (case: propertyName was given after condition was created), if property wasn't found throw error
        if (ui.condition) {
          const property = this.fieldsList.find(
            (item) =>
              item.id.toString() === ui.condition.property ||
              item.propertyName === ui.condition.property,
          );

          if (property) {
            if (
              !property.required &&
              !["calc", "numeration", "currentDate"].includes(
                property.ui.component,
              )
            ) {
              this.$message.error(
                this.$t("forms.gui.dependentFieldRequired_", [rest.title]),
              );
              this.selectedField = elem;
              this.activeTab = "3";
              return;
            }

            ui.condition.property =
              property.propertyName || property.id.toString();
          } else {
            this.$message.error(
              this.$t("forms.gui.propertyNotFound_", [rest.title]),
            );
            this.selectedField = elem;
            this.activeTab = "3";
            return;
          }
        }

        if (ui.calc) {
          // validation of variables used in calculation field, similar to condition's property validation
          for (const [index, property] of ui.calc.variables.entries()) {
            const propertyObject = this.fieldsList.find(
              (item) =>
                item.id.toString() === property ||
                item.propertyName === property,
            );

            if (propertyObject) {
              if (propertyObject.required) {
                ui.calc.variables[index] =
                  propertyObject.propertyName || propertyObject.id.toString();
              } else {
                this.$message.error(
                  this.$t("forms.gui.variableRequired_", [
                    propertyObject.title,
                  ]),
                );
                this.selectedField = elem;
                this.activeTab = "2";
                return;
              }
            } else {
              this.$message.error(
                this.$t("forms.gui.variableNotFound_", [rest.title]),
              );
              this.selectedField = elem;
              this.activeTab = "2";
              return;
            }
          }

          // check if variable index isn't out of range
          for (const index of ui.calc.value.matchAll(/\[(\d*)\]/g)) {
            if (index[1] >= ui.calc.variables.length) {
              this.$message.error(
                this.$t("forms.gui.valueError_", [index[0], rest.title]),
              );
              this.selectedField = elem;
              this.activeTab = "2";
              return;
            }
          }
        }

        switch (ui.component) {
          case "input":
          case "textarea":
          case "bankAccount": {
            rest.minLength = this.parseValue(rest.minLength, parseInt);
            rest.maxLength = this.parseValue(rest.maxLength, parseInt);
            rest.pattern = rest.pattern || undefined;
            break;
          }
          case "date": {
            if (rest.default && rest.default.length === 10) {
              rest.default += "T00:00:00Z";
            }
            break;
          }
          case "number": {
            rest.minimum = this.parseValue(rest.minimum);
            rest.maximum = this.parseValue(rest.maximum);
            rest.multipleOf = this.parseValue(rest.multipleOf);
            break;
          }
          case "dictSelect":
          case "treeSelect":
          case "multiSelect": {
            if (!ui.dictionaryID) {
              this.$message.error(this.$t("forms.gui.chooseDictionary"));
              this.fieldsList.find((elem) => elem.id === id).show = true;
              return;
            }
            break;
          }
          case "systemSelect": {
            if (!ui.dictionaryKeyword) {
              this.$message.error(this.$t("forms.gui.chooseDictionary"));
              this.fieldsList.find((elem) => elem.id === id).show = true;
              return;
            }
            break;
          }
          case "separator": {
            if (!key.toString().startsWith("separator-")) {
              key = `separator-${id}`;
            }
            break;
          }
          case "calc": {
            ui.calc.precision = parseInt(ui.calc.precision);
            break;
          }
          case "radio":
          case "simpleSelect": {
            rest.enum = stringToUniqueArray(stringRepresentation);
            break;
          }
          case "checkbox": {
            rest.items.enum = stringToUniqueArray(stringRepresentation);
            break;
          }
          case "info": {
            if (!rest.description) {
              this.$message.error(
                this.$t("forms.gui.fillRequiredField_", [
                  this.$t("forms.gui.infoContent"),
                ]),
              );
              this.selectedField = elem;
              this.activeTab = "2";
              return;
            }
            break;
          }
          case "infoCheck": {
            if (!ui.checkboxTitle) {
              this.$message.error(
                this.$t("forms.gui.fillRequiredField_", [
                  this.$t("forms.gui.checkboxTitle"),
                ]),
              );
              this.selectedField = elem;
              this.activeTab = "2";
              return;
            }
            break;
          }
          case "multiValue": {
            // check if multiValue doesn't contain any illegal fields
            const illegalSubfield = subfields.find((item) =>
              [
                "numeration",
                "dueDate",
                "currentDate",
                "prioritySelect",
                "userDelegation",
                "groupDelegation",
                "unitDelegation",
                "calc",
                "currentUser",
                "allUnits",
                "multiValue",
                "jrwa",
              ].includes(item.ui.component),
            );

            if (illegalSubfield) {
              this.$message.error(
                this.$t("forms.gui.multiValueError_", [illegalSubfield.name]),
              );
              return;
            }

            rest.items.properties = {};
            rest.items.required = [];

            if (!this.parseData(rest.items, schemaUI, subfields)) {
              return;
            }
            break;
          }
          default: {
            break;
          }
        }

        ui.ordering = index;

        schemaUI[key] = ui;

        if (ui.condition) {
          const component = this.fieldsList.find(
            (item) =>
              item.id.toString() === ui.condition.property ||
              item.propertyName === ui.condition.property,
          )?.ui?.component;

          const condition = {
            if: {
              properties: {
                [ui.condition.property]: generateCondition(component, elem),
              },
              required: [ui.condition.property],
            },
            then: {
              properties: {
                [key]: rest,
              },
              required: required ? [key.toString()] : [],
            },
          };

          schema.allOf.push(condition);
        } else {
          schema.properties[key] = rest;

          if (required) {
            schema.required.push(key.toString());
          }
        }
      }

      if (schema.allOf?.length === 0) {
        schema.allOf = undefined;
      }

      const schemaIsValid = this.ajv.validateSchema(schema);

      if (!schemaIsValid) {
        console.error(this.ajv.errors);
        return;
      }

      const schemaUIIsValid = this.ajv.validate(SchemaUISchema, schemaUI);

      if (!schemaUIIsValid) {
        console.error(this.ajv.errors);
        return;
      }

      return true;
    },
    submit() {
      this.loading$ = true;

      if (!this.generateSchema()) {
        this.loading$ = false;
        return;
      }

      const service = this.id ? editForm : createForm;
      service(this.data, this.id, formsErrorHandler)
        .then(() => {
          this.$router.push({ name: "Forms" });
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchDictionaries(params) {
      return getDictionariesSelect({ status: STATUS.ACTIVE, ...params }).then(
        ({ data }) => data,
      );
    },
    fetchSystemDictionaries(params) {
      return getDictionariesSelect({
        status: STATUS.ACTIVE,
        system: true,
        ...params,
      }).then(({ data }) => ({
        ...data,
        results: data.results.map((item) => ({
          id: item.keyword,
          name: item.name,
        })),
      }));
    },
    fetchNumerations(params) {
      return getNumerationSelect({ status: STATUS.ACTIVE, ...params }).then(
        ({ data }) => data,
      );
    },
    removeItem(id, list) {
      return list.filter((elem) => elem.id !== id);
    },
    togglePreview() {
      const form = document.getElementById("form-builder");
      if (form.reportValidity()) {
        this.generateSchema(true);
      }
    },
    closeModal() {
      this.showPreview = false;
      this.model = {};
      this.data.schema = { properties: {} };
      this.data.schema_ui = {};
    },
    checkUniqueness() {
      const uniqueFields = {
        jrwa: this.$t("modules.jrwa"),
        dueDate: this.$t("forms.inputs.deadline"),
        prioritySelect: this.$t("forms.inputs.priority"),
        userDelegation: this.$t("forms.inputs.userDelegation"),
        groupDelegation: this.$t("forms.inputs.teamDelegation"),
        unitDelegation: this.$t("forms.inputs.unitDelegation"),
        currentUser: this.$t("forms.inputs.currentUser"),
        allUnits: this.$t("forms.inputs.allUnits"),
      };

      for (const [field, translation] of Object.entries(uniqueFields)) {
        if (
          this.fieldsList.filter((item) => item.ui.component === field).length >
          1
        ) {
          this.$message.error(
            this.$t("forms.gui.oneFieldError_", [translation]),
          );
          return false;
        }
      }

      return true;
    },
    fetchForm() {
      getForm(this.id || this.copyForm).then(({ data }) => {
        if (this.id) {
          this.data = data;
        }

        const properties = data.schema.allOf
          ? {
              ...data.schema.properties,
              ...Object.fromEntries(
                data.schema.allOf.map((item) => {
                  return Object.entries(item.then.properties)[0];
                }),
              ),
            }
          : data.schema.properties;

        const required = data.schema.allOf
          ? [
              ...data.schema.required,
              ...data.schema.allOf
                .map((item) => item.then.required)
                .reduce((acc, val) => [...acc, ...val], []),
            ]
          : data.schema.required;

        this.fieldsList = this.convertInputData(
          properties,
          data.schema_ui,
          required,
        );
      });
    },
    convertInputData(properties, schema_ui, required) {
      return Object.entries(properties)
        .map(([key, val]) => {
          const propertyName = /(?!^\d+$)^.+$/.test(key) ? key : "";
          if (propertyName === "" && +key >= this.globalID) {
            this.globalID = +key + 1;
          }

          this.originalFields.push(key);

          if (schema_ui[key].dictionaryID) {
            this.defaultValues[key] = this.$getDefaultValue(
              schema_ui[key],
              "dictionaryID",
            );
          } else if (schema_ui[key].dictionaryKeyword) {
            this.defaultValues[key] = this.$getDefaultValue(
              schema_ui[key],
              "dictionaryKeyword",
            );
          } else if (schema_ui[key].numerationID) {
            this.defaultValues[key] = this.$getDefaultValue(
              schema_ui[key],
              "numerationID",
            );
          }

          let stringRepresentation;

          if (["radio", "simpleSelect"].includes(schema_ui[key].component)) {
            stringRepresentation = val.enum.join(";");
          }

          if (schema_ui[key].component === "checkbox") {
            stringRepresentation = val.items.enum.join(";");
          }

          let subfields;

          if (schema_ui[key].component === "multiValue") {
            subfields = this.convertInputData(
              val.items.properties,
              schema_ui,
              val.items.required,
            );
            val.items.properties = {};
            val.items.required = [];
          }

          return {
            ...val,
            id: key,
            propertyName,
            ui: schema_ui[key],
            show: false,
            required: required.includes(key),
            stringRepresentation,
            subfields,
            name:
              namesMap[
                schema_ui[key].multiValueType ?? schema_ui[key].component
              ],
            icon:
              iconsMap[
                schema_ui[key].multiValueType ?? schema_ui[key].component
              ],
          };
        })
        .sort((a, b) => {
          return a.ui.ordering - b.ui.ordering;
        });
    },
    onInvalid(event) {
      const id = event.target.id.split("-")[0];
      this.selectedField = this.globalFieldsList.find((item) => item.id == id);
      this.activeTab = "2";
    },
    handleSelectedField(field, tab) {
      this.selectedField = field;
      this.activeTab = tab;
    },
    parseValue(value, func = parseFloat) {
      const parsedVal = func(value);
      return isNaN(parsedVal) ? undefined : parsedVal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";
@import "@/scss/Forms";

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;

  .input-container {
    width: 25rem;
  }
}

.form-blueprint {
  width: 50%;
  border: 2px dashed $primary;
  height: 70vh;
  overflow-y: scroll;
}

.form-config {
  width: 50%;
  background-color: primaryOpacity(0.04);
  margin-bottom: 1rem;

  .eod-editor {
    width: 100%;
    margin: 0;
  }
}

.drop-info {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h1 {
    font-size: 2.5rem;
  }
}

.selected {
  background-color: primaryOpacity(0.12);
}

.choose-info {
  text-align: "left";
  margin: 2rem;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: scroll;
  height: 65vh;

  .column {
    flex: 1;
    min-width: 12rem;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}
</style>
