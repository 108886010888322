const permissionList = () =>
  Array.from([
    {
      id: 1,
      name: "deploymentsAndTasks",
      icon: "SyncOutlined",
      subcategories: [
        {
          id: 1,
          name: "deployments",
          appName: "camunda",
          fields: [],
        },
      ],
    },
    {
      id: 2,
      name: "workspace",
      icon: "SnippetsOutlined",
      subcategories: [
        {
          id: 1,
          name: "mailboxes",
          appName: "mailboxes",
          fields: [],
        },
        {
          id: 2,
          name: "documents",
          appName: "documents",
          fields: [],
        },
      ],
    },
    {
      id: 3,
      name: "issues",
      icon: "FolderOutlined",
      subcategories: [
        {
          id: 1,
          name: "issues",
          appName: "issues",
          fields: [],
        },
      ],
    },
    {
      id: 4,
      name: "contractors",
      icon: "TeamOutlined",
      subcategories: [
        {
          id: 1,
          name: "contractors",
          appName: "contractors",
          fields: [],
        },
      ],
    },
    {
      id: 5,
      name: "numeration",
      icon: "OrderedListOutlined",
      subcategories: [
        {
          id: 1,
          name: "numeration",
          appName: "counters",
          fields: [],
        },
      ],
    },
    {
      id: 6,
      name: "dictionaries",
      icon: "SortAscendingOutlined",
      subcategories: [
        {
          id: 1,
          name: "dictionaries",
          appName: "dicts",
          fields: [],
        },
        {
          id: 2,
          name: "jrwa",
          appName: "jrwa",
          fields: [],
        },
      ],
    },
    {
      id: 7,
      name: "replacements",
      icon: "UserSwitchOutlined",
      subcategories: [
        {
          id: 11,
          name: "replacements",
          appName: "replacement",
          fields: [],
        },
      ],
    },
    {
      id: 8,
      name: "permissions",
      icon: "IdcardOutlined",
      subcategories: [
        {
          id: 1,
          name: "users",
          appName: "users",
          fields: [],
        },
      ],
    },
    {
      id: 9,
      name: "units",
      icon: "HomeOutlined",
      subcategories: [
        {
          id: 1,
          name: "units",
          appName: "units",
          fields: [],
        },
      ],
    },
    {
      id: 10,
      name: "notifications",
      icon: "MessageOutlined",
      subcategories: [
        {
          id: 10,
          name: "notifications",
          appName: "notifications",
          fields: [],
        },
      ],
    },
    {
      id: 11,
      name: "forms",
      icon: "FormOutlined",
      subcategories: [
        {
          id: 1,
          name: "forms",
          appName: "forms",
          fields: [],
        },
      ],
    },
    {
      id: 12,
      name: "search",
      icon: "SearchOutlined",
      subcategories: [
        {
          id: 1,
          name: "search",
          appName: "search",
          fields: [],
        },
      ],
    },
    {
      id: 13,
      name: "repository",
      icon: "FolderOutlined",
      subcategories: [
        {
          id: 1,
          name: "repository",
          appName: "repositories",
          fields: [],
        },
      ],
    },
    {
      id: 14,
      name: "archive",
      icon: "ContainerOutlined",
      subcategories: [
        {
          id: 1,
          name: "archive",
          appName: "archives",
          fields: [],
        },
      ],
    },
    {
      id: 15,
      name: "registry",
      icon: "CarryOutOutlined",
      subcategories: [
        {
          id: 1,
          name: "registry",
          appName: "registers",
          fields: [],
        },
      ],
    },
    {
      id: 16,
      name: "contacts",
      icon: "TeamOutlined",
      subcategories: [
        {
          id: 1,
          name: "contacts",
          appName: "contacts",
          fields: [],
        },
      ],
    },
  ]).reduce((acc, item) => {
    item.subcategories = item.subcategories.filter(
      (elem) => !!+process.env[`VUE_APP_SWITCH_${elem.appName.toUpperCase()}`],
    );

    return item.subcategories.length > 0 ? [...acc, item] : acc;
  }, []);

export { permissionList };
