import axios from "@/config/apiClient";

const addDocumentsToFolder = (data, id) => {
  return axios.post(`/api/archives/folders/${id}/documents/`, data);
};

const addDocumentsToVolume = (data, id) => {
  return axios.post(`/api/archives/folders/${id}/documents/set-volume/`, data);
};

const addIssuesToFolder = (data, id) => {
  return axios.post(`/api/archives/folders/${id}/issues/`, data);
};

const addIssuesToVolume = (data, id) => {
  return axios.post(`/api/archives/folders/${id}/issues/set-volume/`, data);
};

const getFolders = (params) => {
  return axios.get("/api/archives/folders/", { params });
};

const createFolder = (data) => {
  return axios.post("/api/archives/folders/", data);
};

const editFolder = (data, id) => {
  return axios.put(`/api/archives/folders/${id}/`, data);
};

const getFolderStatuses = () => {
  return axios.get("/api/archives/status/types/");
};

const getFolder = (id) => {
  return axios.get(`/api/archives/folders/${id}/`);
};

const getFolderIssues = (id, params) => {
  return axios.get(`/api/archives/folders/${id}/issues/`, { params });
};

const getFolderDocuments = (id, params) => {
  return axios.get(`/api/archives/folders/${id}/documents/`, { params });
};

const deleteFolder = (id) => {
  return axios.delete(`/api/archives/folders/${id}/`);
};

const deleteFolderDocument = (id) => {
  return axios.delete(`/api/archives/folders/documents/${id}/`);
};

const deleteFolderIssue = (id) => {
  return axios.delete(`/api/archives/folders/issues/${id}/`);
};

export {
  addDocumentsToFolder,
  addDocumentsToVolume,
  addIssuesToFolder,
  addIssuesToVolume,
  createFolder,
  deleteFolder,
  deleteFolderDocument,
  deleteFolderIssue,
  editFolder,
  getFolder,
  getFolderDocuments,
  getFolderIssues,
  getFolderStatuses,
  getFolders,
};
