<template>
  <div class="section">
    <div class="operator" v-if="removeRule">
      <EODSelect
        :id="`${idName}_operator`"
        v-model:value="cOperator"
        :options="OPERATORS_LIST"
        :disabled="disabled"
        :allowClear="false"
      />
    </div>
    <div class="field">
      <EODSelect
        :id="`${idName}_field`"
        v-model:value="cField"
        :options="fieldList"
        :placeholder="$t('search.selectField')"
        :disabled="disabled"
        :errors="cErrors.field"
      />
    </div>
    <div class="contain">
      <EODSelect
        :id="`${idName}_contain`"
        v-model:value="cContain"
        :options="operationOptions"
        :disabled="disabled"
        :allowClear="false"
      />
    </div>
    <div class="phase">
      <EODDatePicker
        v-if="isFieldTypeDate(cField)"
        :id="`${idName}_phrase`"
        v-model:value="cPhrase"
        :placeholder="$t('dictionaries.entries.chooseDate')"
        dateOnly
        :errors="cErrors.phrase"
        :disabled="disabled || IS_VALUE_DISABLED(cContain)"
        :allowClear="false"
      />
      <EODInput
        v-else
        :id="`${idName}_phrase`"
        v-model:value="cPhrase"
        :placeholder="$t('search.enterSearchText')"
        :disabled="disabled || IS_VALUE_DISABLED(cContain)"
        :errors="cErrors.phrase"
      />
    </div>
    <div class="buttons">
      <Tooltip
        :title="$tc('search.maxRulesInfo_', [maxRulesInSection])"
        :trigger="!disabledAddBtn ? 'none' : 'hover'"
      >
        <div>
          <EODButton
            v-if="addNewRule"
            type="primary"
            :name="$t('search.addNewRule')"
            @click="addNewRule"
            :disabled="disabledAddBtn || disabled"
          />
        </div>
      </Tooltip>
      <button
        type="button"
        class="remove-button"
        v-if="removeRule"
        @click="removeRule"
      >
        <DeleteOutlined />
      </button>
    </div>
    <slot name="sectionInfo"></slot>
  </div>
</template>

<script>
import { EODDatePicker, EODInput, EODSelect } from "@/components/inputs";
import {
  IS_VALUE_DISABLED,
  OPERATION_LIST,
  OPERATION_LIST_FOR_DATE,
  OPERATORS_LIST,
} from "./searchConst";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { EODButton } from "@/components/ui";
import { Tooltip } from "ant-design-vue";
export default {
  name: "AdvancedSearchSection",
  props: {
    operator: {
      type: String,
      default: "",
    },
    field: {
      type: String,
      default: "",
    },
    contain: {
      type: String,
      default: "",
    },
    phrase: {
      type: String,
      default: "",
    },
    date: Boolean,
    addNewRule: {
      type: [Function, Boolean],
      default: false,
    },
    removeRule: {
      type: [Function, Boolean],
      default: false,
    },
    idName: String,
    fieldList: {
      type: Object,
      default: () => ({
        id: 1,
        name: "empty",
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabledAddBtn: {
      type: Boolean,
      default: false,
    },
    maxRulesInSection: Number,
  },
  data() {
    return {
      OPERATORS_LIST,
      operationOptions: this.isFieldTypeDate(this.field)
        ? OPERATION_LIST_FOR_DATE
        : OPERATION_LIST,
    };
  },
  components: {
    DeleteOutlined,
    EODDatePicker,
    EODButton,
    EODInput,
    EODSelect,
    Tooltip,
  },
  computed: {
    cOperator: {
      get() {
        return this.operator;
      },
      set(newValue) {
        this.$emit("update:operator", newValue);
      },
    },
    cField: {
      get() {
        return this.field;
      },
      set(newValue) {
        this.$emit("update:field", newValue);
      },
    },
    cContain: {
      get() {
        return this.contain;
      },
      set(newValue) {
        this.$emit("update:contain", newValue);
      },
    },
    cPhrase: {
      get() {
        return this.phrase;
      },
      set(newValue) {
        this.$emit("update:phrase", newValue);
      },
    },
    cDate: {
      get() {
        return this.date;
      },
      set(newValue) {
        this.$emit("update:date", newValue);
      },
    },
    cErrors: {
      get() {
        return this.errors;
      },
      set(newValue) {
        this.$emit("update:errors", newValue);
      },
    },
  },
  methods: {
    IS_VALUE_DISABLED,
    clearErrors() {
      if (!this.cErrors.field && !this.cErrors.phrase) {
        this.cErrors = {};
      }
    },
    isFieldTypeDate(val) {
      return this.fieldList.find((item) => item.id === val)?.date;
    },
  },
  watch: {
    cField(val, prevVal) {
      const isValTypeDate = this.isFieldTypeDate(val);
      const isPrevValTypeDate = this.isFieldTypeDate(prevVal);
      this.cDate = isValTypeDate;
      this.operationOptions = this.isFieldTypeDate(val)
        ? OPERATION_LIST_FOR_DATE
        : OPERATION_LIST;
      if (isValTypeDate !== isPrevValTypeDate) {
        this.cPhrase = "";
        this.cContain = this.operationOptions[0].id;
      }

      if (val) {
        this.cErrors.field = null;
      } else {
        this.cErrors.phrase = null;
      }
      this.clearErrors();
    },
    cContain(val) {
      if (IS_VALUE_DISABLED(val)) {
        this.cErrors.phrase = null;
      }
      this.clearErrors();
    },
    cPhrase(val) {
      if (val) {
        this.cErrors.phrase = null;
      } else {
        this.cErrors.field = null;
      }
      this.clearErrors();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.section {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  .section > div {
    padding-right: 1rem;
  }

  .operator {
    width: 10%;
    padding-right: 1rem;
  }
  .field {
    width: 25%;
    padding-right: 1rem;
  }
  .contain {
    width: 20%;
    padding-right: 1rem;
  }
  .phase {
    flex-grow: 1;
    padding-right: 1rem;
  }
  .buttons {
    width: 20%;

    .remove-button {
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: 1.3rem;
      color: $error;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>
