<template>
  <section class="section pt-0">
    <div class="filters">
      <EODSelect
        id="year"
        v-model:value="filters.year"
        :label="$t('issues.year')"
        :options="yearOption"
        :placeholder="$t('issues.selectYear')"
      />

      <EODSelect
        id="jrwa"
        storeID="filter-jrwa-option"
        v-model:value="filters.jrwa"
        :label="$t('issues.jrwa')"
        :service="fetchJRWAOption"
      />

      <EODSelect
        id="unit"
        storeID="filter-unit-options"
        v-model:value="filters.unit"
        :label="$t('issues.unit')"
        :service="fetchUnitOption"
      />

      <EODSelect
        id="owner"
        storeID="filter-owner-options"
        v-model:value="filters.owner"
        :label="$t('issues.handler')"
        :service="fetchOwnerOption"
      />

      <EODButton
        :name="$t('app.reset')"
        @click="handleFilterReset"
        class="mr-1 mt-1"
      />
      <EODButton
        class="mt-1"
        type="primary"
        :name="$t('app.filter')"
        @click="handleFilter"
      />
    </div>
  </section>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="(params) => getIssuesList({ status, ...filters, ...params })"
      :scroll="{ x: 800 }"
      :customStore="customStore"
    >
      <template #initDate="{ record }">
        {{
          record.initiation_date
            ? new Date(record.initiation_date).toLocaleDateString()
            : "-"
        }}
      </template>

      <template #endDate="{ record }">
        {{
          record.close_date
            ? new Date(record.close_date).toLocaleDateString()
            : "-"
        }}
      </template>

      <template #owner="{ record }">
        <span :title="record.owner.email">{{
          record.owner.full_name || "-"
        }}</span>
      </template>

      <template #actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'IssuePreview',
            params: { id: record.id },
          }"
          :showEdit="false"
          :showRemove="false"
          :customStore="customStore"
        >
          <button
            class="action"
            v-if="record?.actions?.editable"
            @click="handlers.edit(record)"
            :title="$t('issues.edit')"
          >
            <EditOutlined />
          </button>
          <button
            class="action"
            v-if="record?.actions?.attach"
            @click="handlers.addDocument(record)"
            :title="$t('issues.addDocument')"
          >
            <FileAddOutlined />
          </button>
          <button
            class="action"
            v-if="record?.actions?.share"
            @click="handlers.share(record)"
            :title="$t('issues.share')"
          >
            <ShareAltOutlined />
          </button>
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import {
  EditOutlined,
  FileAddOutlined,
  ShareAltOutlined,
} from "@ant-design/icons-vue";
import {
  filterJRWA,
  filterOwners,
  filterYears,
  getIssuesList,
} from "@/services/issues";
import { getUnitsInIssuesFilterSelect } from "@/services/units";
import { EODButton } from "@/components/ui";
import { EODSelect } from "@/components/inputs";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "IssuesList",
  props: {
    status: {
      type: Number,
      default: null,
    },
    customStore: {
      type: [Boolean, String],
      default: false,
    },
    handlers: { type: Object, required: true },
  },
  data() {
    return {
      filters: {
        year: null,
        unit: null,
        jrwa: null,
      },
      yearOption: [],
      columns: [
        {
          title: this.$t("issues.number"),
          dataIndex: "signature",
          sorter: true,
        },
        {
          title: this.$t("issues.content"),
          dataIndex: "content",
          ellipsis: true,
        },
        {
          title: this.$t("issues.creationDate"),
          dataIndex: "initiation_date",
          slots: { customRender: "initDate" },
          sorter: true,
          width: "15%",
        },
        {
          title: this.$t("issues.endDate"),
          dataIndex: "close_date",
          slots: { customRender: "endDate" },
          sorter: true,
          width: "17%",
        },
        {
          title: this.$t("issues.handler"),
          dataIndex: "owner.full_name_plus",
          slots: { customRender: "owner" },
          ellipsis: true,
        },
        {
          title: this.$t("modules.documents"),
          dataIndex: "documents_count",
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODButton,
    EODSelect,
    TableActions,
    EditOutlined,
    FileAddOutlined,
    ShareAltOutlined,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
    this.fetchYearOption();
  },
  methods: {
    getIssuesList,
    refreshIssues() {
      for (const store of ["issues", "issuesInProgress", "issuesClosed"]) {
        if (this.$store.hasModule(store)) {
          this.$store.dispatch(`${store}/refreshTable`);
        }
      }
    },
    handleFilter() {
      this.refreshIssues();
    },
    handleFilterReset() {
      this.filters = {
        year: null,
        unit: null,
        jrwa: null,
      };
      this.refreshIssues();
    },
    fetchYearOption() {
      filterYears().then(({ data }) => {
        this.yearOption = data.map((year) => ({ id: year, name: year }));
      });
    },
    fetchOwnerOption(params) {
      return filterOwners(params).then(({ data }) => ({
        pages_number: data.pages_number,
        results: data.results.map((item) => ({
          id: item.id,
          name: `${item.full_name} (${item.email})`,
        })),
      }));
    },
    fetchUnitOption(params) {
      return getUnitsInIssuesFilterSelect(params).then(({ data }) => data);
    },
    fetchJRWAOption(params) {
      return filterJRWA(params).then(({ data }) => ({
        pages_number: data.pages_number,
        results: data.results.map((item) => ({
          id: item.id,
          name: `${item.key} ${item.name}`,
        })),
      }));
    },
  },
};
</script>
