<template>
  <div>
    <p class="dependency-info">
      {{
        $t("forms.conditionalForm.info_", [`${field?.name}: ${field?.title}`])
      }}
    </p>

    <EODSelect
      v-model:value="condition.property"
      :options="fieldsOptions"
      :label="$t('forms.conditionalForm.field')"
      required
      @change="onPropertyChange"
      :errors="
        ['dictSelect', 'multiSelect', 'treeSelect'].includes(
          currentComponent,
        ) && !dictionaryID
          ? [$t('forms.conditionalForm.noDict')]
          : null
      "
    >
      <template #tooltip>
        <Tooltip :title="$t('forms.conditionalForm.fieldInfo')">
          <InfoCircleOutlined />
        </Tooltip>
      </template>
    </EODSelect>

    <EODSelect
      v-model:value="condition.operation"
      :options="operationOptions"
      :label="$t('forms.conditionalForm.operation')"
      required
      @change="onOperationChange"
      :disabled="
        ['dictSelect', 'multiSelect', 'treeSelect'].includes(
          currentComponent,
        ) && !dictionaryID
      "
    />

    <EODSelect
      v-if="
        [
          'dictSelect',
          'multiSelect',
          'contractors',
          'prioritySelect',
          'userDelegation',
          'groupDelegation',
          'currentUser',
          'contacts',
        ].includes(currentComponent)
      "
      id="conditional-select-value"
      :storeID="`${currentComponent}-${dictionaryID}`"
      v-model:value="condition.value"
      :mode="condition.operation === 'some' ? 'multiple' : undefined"
      :service="getService(currentComponent)"
      :defaultValue="defaultValue"
      :label="$t('app.value')"
      @change="onSelectChange"
      required
      :disabled="
        ['dictSelect', 'multiSelect'].includes(currentComponent) &&
        !dictionaryID
      "
    />

    <EODSelect
      v-else-if="
        ['radio', 'checkbox', 'simpleSelect'].includes(currentComponent)
      "
      id="conditional-choice-value"
      v-model:value="condition.value"
      :mode="condition.operation === 'some' ? 'multiple' : undefined"
      :options="choiceOptions"
      :label="$t('app.value')"
      required
    />

    <EODTreeSelect
      v-else-if="
        [
          'treeSelect',
          'unitDelegation',
          'jrwa',
          'allUnits',
          'usersUnits',
        ].includes(currentComponent)
      "
      v-model:value="condition.value"
      :treeData="treeData"
      :multiple="condition.operation === 'some'"
      :onLoadData="onLoadData"
      :label="$t('app.value')"
      @change="onTreeSelectChange"
      @search="fetchTree(currentComponent)"
      v-model:searchValue="searchValue"
      required
      :disabled="currentComponent === 'treeSelect' && !dictionaryID"
    />

    <EODDatePicker
      v-else-if="dateComponent"
      v-model:value="condition.value"
      :label="$t('app.value')"
      required
    />

    <EODInput
      v-else
      v-model:value="condition.value"
      :label="$t('app.value')"
      :type="['number', 'calc'].includes(currentComponent) ? 'number' : 'text'"
      required
    />

    <div class="modal-footer">
      <EODButton
        :name="$t('forms.conditionalForm.deleteCondition')"
        @click="deleteCondition"
        ghost
        type="danger"
      />

      <EODButton
        type="primary"
        :name="$t('forms.conditionalForm.saveCondition')"
        @click="saveCondition"
      />
    </div>
  </div>
</template>

<script>
import {
  EODDatePicker,
  EODInput,
  EODSelect,
  EODTreeSelect,
} from "@/components/inputs";
import {
  getGroupsSimpleList,
  getUsersSimpleList,
} from "@/services/permissions";

import { EODButton } from "@/components/ui";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { STATUS } from "@/consts/statuses";
import { Tooltip } from "ant-design-vue";
import { getContactsSelect } from "@/services/contacts";
import { getContractorsSelect } from "@/services/contractors";
import { getDictionaryEntriesSelect } from "@/services/dictionaries";
import { getJRWAEntriesSelectList } from "@/services/jrwa";
import { getPriorityOption } from "@/services/tasks";
import { getUnitsSelect } from "@/services/units";
import { nextTick } from "vue";
import operationOptionFilters from "./operationOptionsFilters";
import stringToUniqueArray from "@/helpers/stringToUniqueArray";

const defaultCondition = {
  property: "",
  operation: "",
  value: "",
  value_display: "",
};

export default {
  name: "ConditionalRenderingForm",
  props: { fieldsList: Array, selectedField: Object },
  data() {
    return {
      condition: { ...defaultCondition },
      treeData: [],
      originalTreeValue: null,
      defaultValue: null,
      searchValue: "",
    };
  },
  components: {
    EODButton,
    EODDatePicker,
    EODInput,
    EODSelect,
    EODTreeSelect,
    InfoCircleOutlined,
    Tooltip,
  },
  computed: {
    fields: {
      get() {
        return this.fieldsList;
      },
      set(val) {
        this.$emit("update:fieldsList", val);
      },
    },
    field: {
      get() {
        return this.selectedField;
      },
      set(val) {
        this.$emit("update:selectedField", val);
      },
    },
    fieldsOptions() {
      return this.fields.map((elem) => ({
        id: elem.propertyName || elem.id.toString(),
        name: `${elem.name}: ${elem.title}`,
        disabled:
          elem.id === this.field?.id ||
          [
            "separator",
            "info",
            "infoCheck",
            "multiValue",
            "editor",
            "bankAccount",
          ].includes(elem.ui.component) ||
          (!elem.required &&
            !["calc", "numeration", "currentDate"].includes(elem.ui.component)),
      }));
    },
    propertyField() {
      return this.fields.find(
        (field) =>
          field.id == this.condition.property ||
          (field.propertyName !== "" &&
            field.propertyName === this.condition.property),
      );
    },
    currentComponent() {
      return this.propertyField?.ui?.component;
    },
    dictionaryID() {
      return this.propertyField?.ui?.dictionaryID;
    },
    dateComponent() {
      return ["date", "daterange", "currentDate", "dueDate"].includes(
        this.currentComponent,
      );
    },
    operationOptions() {
      return ["eq", "neq", "le", "leq", "ge", "geq", "inc", "dec", "some"]
        .map((operation) => ({
          id: operation,
          name: this.$t(`forms.conditionalForm.${operation}`),
          disabled: this.disabledCondition(operationOptionFilters[operation]),
        }))
        .filter((operation) => !operation.disabled);
    },
    choiceOptions() {
      return stringToUniqueArray(
        this.propertyField.stringRepresentation ?? "",
      ).map((item) => ({ id: item, name: item }));
    },
  },
  created() {
    this.condition = this.field?.ui?.condition
      ? { ...this.field.ui.condition }
      : { ...defaultCondition };

    this.setDefaultValue();
  },
  emits: ["update:fieldsList", "update:selectedField", "formChanged"],
  methods: {
    saveCondition() {
      if (!this.condition.property) {
        this.$message.error(
          this.$t("forms.conditionalForm.fieldRequired_", [
            this.$t("forms.conditionalForm.field"),
          ]),
        );
        return;
      }

      if (!this.condition.operation) {
        this.$message.error(
          this.$t("forms.conditionalForm.fieldRequired_", [
            this.$t("forms.conditionalForm.operation"),
          ]),
        );
        return;
      }

      if (["number", "calc"].includes(this.currentComponent)) {
        const value = parseFloat(this.condition.value);
        if (!isNaN(value)) {
          this.condition.value = value;
        } else {
          this.$message.error(this.$t("forms.conditionalForm.numberError"));
          return;
        }
      } else {
        if (
          (Array.isArray(this.condition.value) &&
            this.condition.value.length === 0) ||
          !this.condition.value
        ) {
          this.$message.error(
            this.$t("forms.gui.fillRequiredField_", [this.$t("app.value")]),
          );
          return;
        }
      }

      this.field.ui.condition = { ...this.condition };

      this.$emit("formChanged");
      this.$message.success(this.$t("forms.conditionalForm.success"));
    },
    deleteCondition() {
      this.condition = { ...defaultCondition };
      this.defaultValue = null;
      this.field.ui.condition = undefined;
      this.$emit("formChanged");
    },
    disabledCondition(list) {
      return this.condition.property === ""
        ? true
        : !list.includes(
            this.fieldsList.find(
              (field) =>
                field.id == this.condition.property ||
                field.propertyName === this.condition.property,
            )?.ui.component,
          );
    },
    setDefaultValue() {
      if (this.field?.ui?.condition?.value_display) {
        if (Array.isArray(this.field?.ui?.condition?.value_display)) {
          this.defaultValue = this.field.ui.condition.value.map(
            (item, idx) => ({
              id: item,
              name: this.field.ui.condition.value_display[idx],
            }),
          );
        } else {
          this.defaultValue = this.$getDefaultValue(this.condition, "value");
        }
      }
    },
    getService(component) {
      //FIXME: getService is called everytime when you add new field to form
      const callback = ({ data }) => ({
        ...data,
        results: data.results.map((elem) => ({
          id: elem.id,
          name: elem.name ?? elem.full_name,
        })),
      });

      switch (component) {
        case "prioritySelect": {
          return (params) => getPriorityOption(params).then(callback);
        }
        case "currentUser":
        case "userDelegation": {
          return (params) => getUsersSimpleList(params).then(callback);
        }
        case "groupDelegation": {
          return (params) => getGroupsSimpleList(params).then(callback);
        }
        case "contacts": {
          return (params) => getContactsSelect(params).then(callback);
        }
        case "contractors": {
          return (params) =>
            getContractorsSelect({ status: STATUS.ACTIVE, ...params }).then(
              callback,
            );
        }
        default: {
          return (params) =>
            getDictionaryEntriesSelect({
              dictionary: this.dictionaryID,
              status: STATUS.ACTIVE,
              ...params,
            }).then(callback);
        }
      }
    },
    getTreeService(component, parent) {
      // TODO: proper hierarchic select API AWF-1425
      const params = { limit: 100, parent, search_field: this.searchValue };

      switch (component) {
        case "allUnits":
        case "usersUnits":
        case "unitDelegation": {
          return getUnitsSelect(params);
        }
        case "jrwa": {
          return getJRWAEntriesSelectList(params);
        }
        default: {
          return getDictionaryEntriesSelect({
            dictionary: this.dictionaryID,
            status: STATUS.ACTIVE,
            ...params,
          });
        }
      }
    },
    fetchTree(component = "") {
      const isJRWA = component === "jrwa";
      const service = this.getTreeService(component);

      service.then(({ data: { results } }) => {
        this.treeData = results.map((item) => {
          const title = isJRWA ? `${item.key} ${item.name}` : item.name;
          return {
            id: item.id,
            pId: item.parent || 0,
            value: item.id,
            title,
            isLeaf: !item.children,
            disabled: ["unitDelegation", "allUnits", "usersUnits"].includes(
              component,
            )
              ? false
              : !!item.children,
          };
        });

        if (Array.isArray(this.condition.value)) {
          this.originalTreeValue = [...this.condition.value];

          if (this.condition.value.length > 0) {
            for (const [idx, item] of this.condition.value.entries()) {
              if (
                !this.treeData.find((elem) => elem.id === item) &&
                !this.searchValue
              ) {
                this.treeData.push({
                  id: item,
                  pId: 0,
                  value: item,
                  title: this.condition.value_display[idx],
                  isLeaf: true,
                });
              }
            }
          }
        } else {
          if (
            this.condition.value &&
            !this.treeData.find((elem) => elem.id === this.condition.value) &&
            !this.searchValue
          ) {
            this.originalTreeValue = this.condition.value;

            this.treeData.push({
              id: this.condition.value,
              pId: 0,
              value: this.condition.value,
              title: this.condition.value_display,
              isLeaf: true,
            });
          }
        }
      });
    },
    onLoadData(treeNode) {
      const { id: nodeId } = treeNode.dataRef;
      const isJRWA = this.currentComponent === "jrwa";

      const service = this.getTreeService(this.currentComponent, nodeId);

      return service.then(({ data: { results } }) => {
        const children = results.map((item) => {
          if (
            (Array.isArray(this.condition.value) &&
              this.originalTreeValue?.includes(item.id)) ||
            (this.condition.value && item.id === this.originalTreeValue)
          ) {
            this.treeData = this.treeData.filter((elem) => elem.id !== item.id);
          }

          const title = isJRWA ? `${item.key} ${item.name}` : item.name;

          return {
            id: item.id,
            pId: item.parent || 0,
            value: item.id,
            title,
            isLeaf: !item.children,
            disabled: ["unitDelegation", "allUnits", "usersUnits"].includes(
              this.currentComponent,
            )
              ? false
              : !!item.children,
          };
        });

        this.treeData = this.treeData.concat(children);
      });
    },
    onOperationChange(val) {
      if (val === "some") {
        this.condition.value = [];
      }
    },
    onSelectChange(value) {
      const getName = (id) =>
        this.$store.state[
          `select_${this.currentComponent}-${this.dictionaryID}`
        ]?.serviceOptions?.find((elem) => elem.id === id)?.name;
      if (Array.isArray(value)) {
        this.condition.value_display = value.map(getName);
      } else {
        this.condition.value_display = getName(value);
      }
    },
    onTreeSelectChange(value) {
      this.searchValue = "";
      const getName = (id) =>
        this.treeData.find((elem) => elem.id === id)?.title;

      if (Array.isArray(value)) {
        this.condition.value_display = value.map(getName);
      } else {
        this.condition.value_display = getName(value);
      }
    },
    onPropertyChange() {
      this.defaultValue = null;
      this.searchValue = "";
      this.condition.operation = "";
      this.condition.value = "";
    },
  },
  watch: {
    field(val) {
      this.condition = { ...defaultCondition };
      this.defaultValue = null;
      this.searchValue = "";

      if (val.ui.condition) {
        nextTick(() => {
          this.condition = { ...val.ui.condition };
          this.setDefaultValue();
        });
      }
    },
    currentComponent(val) {
      switch (val) {
        case "allUnits":
        case "usersUnits":
        case "unitDelegation":
        case "jrwa": {
          this.originalTreeValue = null;
          this.fetchTree(val);
          break;
        }
        case "treeSelect": {
          if (!this.dictionaryID) {
            this.$message.warning(this.$t("forms.conditionalForm.noDict"));
          } else {
            this.originalTreeValue = null;
            this.fetchTree();
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    "condition.operation": function (_, oldVal) {
      if (oldVal === "some") {
        this.condition.value = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.dependency-info {
  text-align: left;
  margin: 1rem 0;
}
</style>
