<template>
  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getDocumentsList"
      :customParams="params"
      :customStore="customStore"
      :rowSelection="rowSelection"
      emitData
    >
      <template v-slot:button>
        <EODButton
          v-if="hasPermissionToAddIssue"
          @click="
            checkSelectedItemsBeforeOperation('addToIssue', openAddToIssueModal)
          "
          icon="FolderOutlined"
          :name="$t('documents.addToCase')"
          :disabled="this.rowSelection.selectedRowKeys.length < 1"
        />
        <EODButton
          v-if="hasStartDeploymentPermission"
          @click="
            () =>
              checkSelectedItemsBeforeOperation(
                'startDeployment',
                openSelectDeploymentModal,
              )
          "
          icon="PlayCircleOutlined"
          :name="$t('deployments.start')"
          :disabled="this.rowSelection.selectedRowKeys.length < 1"
          class="ml-1"
        />
        <EODButton
          v-if="hasDocumentsEditPermission"
          @click="
            () =>
              checkSelectedItemsBeforeOperation('delete', () =>
                toggleMultipleDeleteModal(true),
              )
          "
          icon="DeleteOutlined"
          :name="$t('app.deleteSelected')"
          :disabled="this.rowSelection.selectedRowKeys.length < 1"
          class="ml-1"
        />
      </template>
      <template #name="{ record }">
        {{
          `${record.original_document ? `[${$t("documents.copy")}] ` : ""}${
            record.name
          }`
        }}
        <CopyOutlined
          v-if="record.original_document"
          :title="$t('documents.thisIsCopy')"
        />
      </template>
      <template #jrwa="{ record }">
        {{ record.jrwa_display || "-" }}
      </template>
      <template #issue_signature="{ record }">
        <router-link
          :to="{ name: 'IssuePreview', params: { id: record.issue } }"
          :title="$t('issues.meta.preview')"
        >
          {{ record.issue_signature || "-" }}
        </router-link>
      </template>
      <template #document_date="{ record }">
        {{ new Date(record.document_date).toLocaleDateString() }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'DocumentsPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'DocumentsEdit',
            params: { id: record.id },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'documents.document',
            1,
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('documents.delete')"
          :deleteService="deleteDocument"
          :customStore="customStore"
        >
          <template #deploymentStart>
            <button
              v-if="record?.actions?.start_deployment"
              class="action"
              @click="openSelectDeploymentModal(record)"
              :title="$t('deployments.start')"
            >
              <PlayCircleOutlined />
            </button>
          </template>
        </TableActions>
      </template>
    </BaseTable>
  </section>
  <StartDeploymentForDocModal
    v-model:visible="showSelectDeploymentModal"
    :documentName="documentName"
    selectId="deploymentsToStartDocList"
    :documentsId="selectedItems.allow.map((item) => item.id)"
  />
  <AddToIssueModal
    v-model:visible="showAddToIssueModal"
    v-model:documents="selectedItems.allow"
    cleanDocumentsList
    :customStore="customStore"
  />
  <ConfirmModal
    v-model:visible="showPermanentBlockActionModal"
    :title="$t('documents.permanentBlockActionTitle')"
    :onCancel="() => togglePermanentBlockActionModal(false)"
    disableConfirm
  />
  <BlockOperationModal
    v-model:visible="showBlockActionModal"
    :openOperationModal="operationModal"
    v-model:allowItems="selectedItems.allow"
    v-model:blockedItems="selectedItems.blocked"
    :allowInfo="checkOperationModalInfo.allow"
    :blockedInfo="checkOperationModalInfo.blocked"
  />
  <ConfirmModal
    v-model:visible="showMultipleDeleteModal"
    :title="$t('documents.deleteDocuments')"
    :onSubmit="handleMultipleDelete"
    :onCancel="() => toggleMultipleDeleteModal(false)"
    :okText="$t('app.yesUpper')"
    :cancelText="$t('app.noUpper')"
    :isLoading="isLoading"
  >
    <p>{{ $t("documents.doYouWantToRemoveDocuments") }}</p>
  </ConfirmModal>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { CopyOutlined, PlayCircleOutlined } from "@ant-design/icons-vue";
import {
  deleteDocument,
  deleteMultipleDocuments,
  getDocumentsList,
} from "@/services/documents";

import AddToIssueModal from "@/views/Documents/AddToIssueModal";
import BlockOperationModal from "@/views/Documents/BlockOperationModal";
import { ConfirmModal } from "@/components/modals";
import { EODButton } from "@/components/ui";
import PERMISSIONS from "@/consts/permissions";
import StartDeploymentForDocModal from "./StartDeploymentForDocModal";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "DocumentsList",
  props: {
    params: Object,
    customStore: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      selectedItemsAsObjects: [],
      documentName: "",
      selectedItems: {
        blocked: [],
        allow: [],
      },
      showPermanentBlockActionModal: false,
      showBlockActionModal: false,
      showSelectDeploymentModal: false,
      showAddToIssueModal: false,
      showMultipleDeleteModal: false,
      operationModal: () => {},
      isLoading: false,
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
          slots: { customRender: "name" },
        },
        {
          title: "JRWA",
          dataIndex: "jrwa__key",
          slots: { customRender: "jrwa" },
          sorter: true,
        },
        {
          title: this.$t("documents.caseNumber"),
          dataIndex: "issue_signature",
          slots: { customRender: "issue_signature" },
        },
        {
          title: this.$t("documents.date"),
          dataIndex: "document_date",
          slots: { customRender: "document_date" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
      rowSelection: {
        selectedRowKeys: [],
        onChange: this.onChange,
      },
      checkOperationModalInfo: {
        allow: "",
        blocked: "",
      },
    };
  },
  components: {
    AddToIssueModal,
    BlockOperationModal,
    BaseTable,
    ConfirmModal,
    CopyOutlined,
    EODButton,
    PlayCircleOutlined,
    StartDeploymentForDocModal,
    TableActions,
  },
  computed: {
    hasStartDeploymentPermission() {
      return this.$permissions.includes(PERMISSIONS.DEPLOYMENTS_START);
    },
    hasDocumentsEditPermission() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS_ADD);
    },
    hasPermissionToAddIssue() {
      return this.$permissions.includes(PERMISSIONS.ISSUES_ADD);
    },
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    deleteDocument,
    getDocumentsList,
    openSelectDeploymentModal(document = null) {
      if (document !== null) {
        this.selectedItems.allow = [document];
      }
      this.documentName = document?.name;
      this.showSelectDeploymentModal = true;
    },
    onChange(selectedRowKeys) {
      if (this.rowSelection.selectedRowKeys.length < selectedRowKeys.length) {
        const addedItems = selectedRowKeys.filter(
          (item) => !this.rowSelection.selectedRowKeys.includes(item),
        );
        const selectedItemsAsObjects = [];
        addedItems.forEach((selectedItem) => {
          selectedItemsAsObjects.push(
            this.$store.getters[`${this.customStore}/getData`].results.find(
              (document) => document.id === selectedItem,
            ),
          );
        });
        this.selectedItemsAsObjects = this.selectedItemsAsObjects.concat(
          selectedItemsAsObjects,
        );
      } else {
        const deletedItems = this.rowSelection.selectedRowKeys.filter(
          (item) => !selectedRowKeys.includes(item),
        );
        this.selectedItemsAsObjects = this.selectedItemsAsObjects.filter(
          (item) => !deletedItems.includes(item.id),
        );
      }
      this.rowSelection.selectedRowKeys = selectedRowKeys;
    },
    checkSelectedItemsBeforeOperation(operationType, openOperationModal) {
      const allowOperationElements = [];
      const blockOperationElements = [];
      switch (operationType) {
        case "addToIssue": {
          this.selectedItemsAsObjects.forEach((item) => {
            if (!item.issue && item.actions.can_be_in_issue) {
              allowOperationElements.push(item);
            } else {
              blockOperationElements.push(item);
            }
          });
          this.checkOperationModalInfo.allow = this.$t(
            "documents.blockActionAllowInfoIssue",
          );
          this.checkOperationModalInfo.blocked = this.$t(
            "documents.blockActionBlockInfoIssue",
          );
          break;
        }
        case "delete": {
          this.selectedItemsAsObjects.forEach((item) => {
            if (item.actions.removable) {
              allowOperationElements.push(item);
            } else {
              blockOperationElements.push(item);
            }
          });
          this.checkOperationModalInfo.allow = this.$t(
            "documents.blockActionAllowInfoDelete",
          );
          this.checkOperationModalInfo.blocked = this.$t(
            "documents.blockActionBlockInfoDelete",
          );
          break;
        }
        case "startDeployment": {
          this.selectedItemsAsObjects.forEach((item) => {
            if (item.actions.start_deployment) {
              allowOperationElements.push(item);
            } else {
              blockOperationElements.push(item);
            }
          });
          this.checkOperationModalInfo.allow = this.$t(
            "documents.blockActionAllowInfoStartDeployment",
          );
          this.checkOperationModalInfo.blocked = this.$t(
            "documents.blockActionBlockInfoStartDeployment",
          );
        }
      }
      if (blockOperationElements.length === 0) {
        this.selectedItems.allow = allowOperationElements;
        openOperationModal();
      } else if (allowOperationElements.length === 0) {
        this.togglePermanentBlockActionModal(true);
      } else {
        this.selectedItems.blocked = blockOperationElements;
        this.selectedItems.allow = allowOperationElements;
        this.operationModal = openOperationModal;
        this.openBlockActionModal();
      }
    },
    togglePermanentBlockActionModal(open) {
      this.showPermanentBlockActionModal = open;
    },
    openBlockActionModal() {
      this.showBlockActionModal = true;
    },
    openAddToIssueModal() {
      this.showAddToIssueModal = true;
    },
    toggleMultipleDeleteModal(open) {
      this.showMultipleDeleteModal = open;
    },
    handleMultipleDelete() {
      this.isLoading = true;
      const documentsToDelete = this.selectedItems.allow.map((item) => item.id);
      deleteMultipleDocuments(documentsToDelete)
        .then(() => {
          this.selectedItems.allow = [];
          this.selectedItems.blocked = [];
          this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
            (item) => !documentsToDelete.includes(item),
          );
          this.selectedItemsAsObjects = this.selectedItemsAsObjects.filter(
            (item) => !documentsToDelete.includes(item.id),
          );
          this.$store.dispatch(`${this.customStore}/refreshTable`);
        })
        .finally(() => {
          this.isLoading = false;
          this.toggleMultipleDeleteModal(false);
        });
    },
  },
};
</script>
