<template>
  <section class="section pt-050">
    <div class="filters">
      <span class="filter-label mr-050">{{ $t("tasks.deployment") }}:</span>
      <EODSelect
        id="deployment"
        storeID="deployment"
        v-model:value="deployment"
        :placeholder="$t('tasks.deployment')"
        :service="deploymentsService"
      />
    </div>
  </section>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getTasksList"
      :customParams="{ processInstanceId, deployment, my_tasks: myTasks }"
      :rowClassName="rowClassName"
      :hasSearch="false"
    >
      <template #assignee="{ record }">
        {{ record.assignee || "-" }}
      </template>
      <template #deployment_name="{ record }">
        {{ record.description?.deployment_name || "-" }}
      </template>
      <template #created_by="{ record }">
        {{ record.description?.created_by || "-" }}
      </template>
      <template #created="{ record }">
        {{ record.created ? new Date(record.created).toLocaleString() : "-" }}
      </template>
      <template #due="{ record }">
        {{ record.due ? new Date(record.due).toLocaleDateString() : "-" }}
        &nbsp;
        <ExclamationCircleOutlined
          v-if="record.due ? new Date(record.due) < tomorrow : false"
          :class="
            new Date(record.due).getTime() === today.getTime()
              ? 'color--warning'
              : 'color--error'
          "
          :title="
            new Date(record.due).getTime() === today.getTime()
              ? $t('tasks.dueComing')
              : $t('tasks.pastDue')
          "
        />
      </template>
      <template #priority="{ record }">
        <ArrowUpOutlined
          v-if="record.priority >= 50"
          :class="record.priority === 50 ? 'color--warning' : 'color--error'"
          :title="
            record.priority === 50 ? $t('tasks.medium') : $t('tasks.high')
          "
          :style="{ fontSize: '1.5rem' }"
        />
        <ArrowDownOutlined
          v-else
          class="color--success"
          :title="$t('tasks.low')"
          :style="{ fontSize: '1.5rem' }"
        />
      </template>
      <template v-slot:actions="{ record }">
        <div class="actions">
          <router-link
            class="action"
            :to="{
              name: 'TaskPreview',
              params: { id: record.id },
            }"
            :title="$t('app.preview')"
          >
            <EyeOutlined />
          </router-link>
          <button
            v-if="record?.actions?.assignee"
            class="action"
            @click="openAssignUserModal(record)"
            :title="$t('tasks.delegate')"
          >
            <SolutionOutlined />
          </button>
          <button
            v-if="record?.actions?.claim"
            class="action"
            :disabled="loading"
            @click="handleClaim(record)"
            :title="$t('tasks.claim')"
          >
            <PushpinOutlined />
          </button>
          <router-link
            v-if="record?.actions?.complete"
            class="action"
            :to="{
              name: 'TaskForm',
              params: { id: record.id },
              query: { 'back-to': $route.name },
            }"
            :title="$t('tasks.start')"
          >
            <RightSquareOutlined />
          </router-link>
          <button
            v-if="record?.actions?.unclaim"
            class="action"
            :disabled="loading"
            @click="handleUnclaim(record)"
            :title="$t('tasks.unclaim')"
          >
            <StopOutlined />
          </button>
        </div>
      </template>
    </BaseTable>
  </section>
  <SelectModal
    v-model:visible="showAssignUserModal"
    v-model:selectedOption="selectedUser"
    :title="$t('tasks.delegate')"
    :body="$tc('tasks.delegateInfo', [this.selectedTask.name])"
    :okText="$t('tasks.delegate')"
    :service="fetchUserSimpleList"
    :onSubmit="handleAssignUser"
    :onCancel="closeAssignUserModal"
    resetDataAfterClose
    selectId="usersToAssignCurrentTasks"
  />
</template>

<script>
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PushpinOutlined,
  RightSquareOutlined,
  SolutionOutlined,
  StopOutlined,
} from "@ant-design/icons-vue";
import {
  assignTask,
  claimTask,
  getTasksList,
  unclaimTask,
} from "@/services/tasks";

import { BaseTable } from "@/components/table";
import { EODSelect } from "@/components/inputs";
import { SelectModal } from "@/components/modals";
import { getUsersSimpleList } from "@/services/permissions";

export default {
  name: "CurrentTasks",
  props: {
    deploymentsService: Function,
    myTasks: { type: Boolean, required: true },
  },
  data() {
    return {
      showAssignUserModal: false,
      selectedTask: {},
      processInstanceId: null,
      today: null,
      tomorrow: null,
      selectedUser: null,
      deployment: null,
      loading: false,
      columns: [
        {
          title: this.$t("deployments.name"),
          dataIndex: "description.deployment_name",
          slots: { customRender: "deployment_name" },
        },
        {
          title: this.$t("processes.createdBy"),
          dataIndex: "description.created_by",
          slots: { customRender: "created_by" },
        },
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: this.$t("tasks.assignedTo"),
          dataIndex: "assignee",
          sorter: true,
          slots: { customRender: "assignee" },
        },
        {
          title: this.$t("tasks.created"),
          dataIndex: "created",
          sorter: true,
          slots: { customRender: "created" },
        },
        {
          title: this.$t("tasks.due"),
          dataIndex: "dueDate",
          sorter: true,
          slots: { customRender: "due" },
        },
        {
          title: this.$t("forms.inputs.priority"),
          dataIndex: "priority",
          sorter: true,
          slots: { customRender: "priority" },
          width: "10%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    ArrowDownOutlined,
    ArrowUpOutlined,
    BaseTable,
    EODSelect,
    ExclamationCircleOutlined,
    SolutionOutlined,
    PushpinOutlined,
    RightSquareOutlined,
    StopOutlined,
    EyeOutlined,
    SelectModal,
  },
  created() {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);

    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.tomorrow.setHours(0, 0, 0, 0);

    this.processInstanceId = this.$route.query.processInstanceId || null;
  },
  methods: {
    getTasksList,
    handleClaim(task) {
      this.loading = true;
      claimTask(task, task.id)
        .then(({ data }) => {
          this.$message.success(data);
          this.$store.dispatch("refreshTable");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUnclaim(task) {
      this.loading = true;
      unclaimTask(task, task.id)
        .then(({ data }) => {
          this.$message.success(data);
          this.$store.dispatch("refreshTable");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rowClassName(record) {
      return record.due
        ? new Date(record.due) < this.today
          ? "color--error"
          : ""
        : "";
    },
    fetchUserSimpleList(params) {
      return getUsersSimpleList({
        processDefinitionId: this.selectedTask.processDefinitionId,
        taskDefinitionKey: this.selectedTask.taskDefinitionKey,
        ...params,
      }).then(({ data }) => {
        const results = data.results.map((item) => ({
          ...item,
          name: item.full_name,
        }));
        return { ...data, results };
      });
    },
    openAssignUserModal(task) {
      this.selectedTask = task;
      this.showAssignUserModal = true;
    },
    closeAssignUserModal() {
      this.showAssignUserModal = false;
      this.selectedTask = {};
      this.selectedUser = null;
    },
    handleAssignUser() {
      this.selectedTask.user = this.selectedUser;
      assignTask(this.selectedTask, this.selectedTask.id).then(({ data }) => {
        this.closeAssignUserModal();
        this.$message.success(data);
        this.$store.dispatch("refreshTable");
      });
    },
  },
};
</script>
