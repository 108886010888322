<template>
  <Breadcrumbs
    :current="$t('modules.notifications')"
    iconComponent="MessageOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'NotificationsCreate',
      }"
      :title="$t('notifications.add')"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getNotificationsList">
      <template v-slot:notifications_type="{ record }">
        {{ record.notifications_type_display }}
      </template>

      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>

      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'NotificationsPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'NotificationsEdit',
            params: { id: record.id },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'notifications.notification',
            1,
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('notifications.delete')"
          :deleteService="deleteNotification"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import {
  deleteNotification,
  getNotificationsList,
} from "@/services/notifications";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "Notifications",
  data() {
    return {
      columns: [
        { title: this.$t("app.name"), dataIndex: "name", sorter: true },
        {
          title: this.$t("app.type"),
          dataIndex: "notifications_type",
          sorter: true,
          slots: { customRender: "notifications_type" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    StatusDot,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.NOTIFICATIONS_ADD);
    },
  },
  methods: {
    deleteNotification,
    getNotificationsList,
  },
};
</script>
