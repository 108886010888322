<template>
  <BaseTable
    v-if="record"
    :columns="columns"
    :service="service"
    :customStore="customStore"
    :customParams="{ processInstanceId, deployment }"
    :hasSearch="false"
    :title="title"
    :link="link"
    paginationType="NONE"
  >
    <template #assignee="{ record }">
      {{ record.assignee || "-" }}
    </template>
    <template #created="{ record }">
      {{ record.created ? new Date(record.created).toLocaleString() : "-" }}
    </template>
    <template #due="{ record }">
      {{ record.due ? new Date(record.due).toLocaleDateString() : "-" }}
      &nbsp;
      <ExclamationCircleOutlined
        v-if="record.due ? new Date(record.due) < tomorrow : false"
        :class="
          new Date(record.due).getTime() === today.getTime()
            ? 'color--warning'
            : 'color--error'
        "
        :title="
          new Date(record.due).getTime() === today.getTime()
            ? $t('tasks.dueComing')
            : $t('tasks.pastDue')
        "
      />
    </template>
    <template #priority="{ record }">
      <ArrowUpOutlined
        v-if="record.priority >= 50"
        :class="record.priority === 50 ? 'color--warning' : 'color--error'"
        :title="record.priority === 50 ? $t('tasks.medium') : $t('tasks.high')"
        :style="{ fontSize: '1.5rem' }"
      />
      <ArrowDownOutlined
        v-else
        class="color--success"
        :title="$t('tasks.low')"
        :style="{ fontSize: '1.5rem' }"
      />
    </template>
    <template v-slot:actions="{ record }">
      <div class="actions">
        <router-link
          class="action"
          :to="{
            name: 'TaskPreview',
            params: { id: record.id },
          }"
          :title="$t('app.preview')"
        >
          <EyeOutlined />
        </router-link>
        <button
          v-if="record?.actions?.claim"
          class="action"
          :disabled="loading"
          @click="handleClaim(record)"
          :title="$t('tasks.claim')"
        >
          <PushpinOutlined />
        </button>
        <router-link
          v-if="record?.actions?.complete"
          class="action"
          :to="{
            name: 'TaskForm',
            params: { id: record.id },
          }"
          :title="$t('tasks.start')"
        >
          <RightSquareOutlined />
        </router-link>
        <button
          v-if="record?.actions?.unclaim"
          class="action"
          :disabled="loading"
          @click="handleUnclaim(record)"
          :title="$t('tasks.unclaim')"
        >
          <StopOutlined />
        </button>
      </div>

      <div class="actions-mobile">
        <Dropdown :trigger="['click', 'hover']">
          <EODButton class="ml-1">
            <MoreOutlined />
          </EODButton>
          <template v-slot:overlay>
            <Menu selectable>
              <MenuItem key="1">
                <router-link
                  :to="{
                    name: 'TaskPreview',
                    params: { id: record.id },
                  }"
                  :title="$t('app.preview')"
                  >{{ $t("app.preview") }}</router-link
                >
              </MenuItem>
              <MenuItem key="2">
                <router-link
                  v-if="record?.actions?.complete"
                  :to="{
                    name: 'TaskForm',
                    params: { id: record.id },
                  }"
                  :title="$t('tasks.start')"
                  >{{ $t("tasks.start") }}</router-link
                >
              </MenuItem>
              <MenuItem
                v-if="record?.actions?.unclaim"
                @click="handleUnclaim(record)"
                :title="$t('tasks.unclaim')"
                key="3"
              >
                {{ $t("tasks.unclaim") }}
              </MenuItem>
              <MenuItem
                v-if="record?.actions?.claim"
                @click="handleClaim(record)"
                :title="$t('tasks.claim')"
                key="4"
              >
                {{ $t("tasks.claim") }}
              </MenuItem>
            </Menu>
          </template>
        </Dropdown>
      </div>
    </template>
  </BaseTable>
  <div v-else>
    <img src="@/img/empty-img-default.png" alt="Logo" />
    <p class="color--font-tertiary">
      {{ $t("tasks.noTasks") }}
    </p>
  </div>
</template>
<script>
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PushpinOutlined,
  RightSquareOutlined,
  StopOutlined,
} from "@ant-design/icons-vue";

import { Dropdown, Menu } from "ant-design-vue";

import { claimTask, unclaimTask } from "@/services/tasks";
import BaseTable from "@/components/table/BaseTable";
import EODButton from "@/components/ui/EODButton.vue";

export default {
  name: "OwnTaskList",
  props: {
    title: { type: String, default: "" },
    link: { type: Object },
    customStore: {
      type: String,
      default: "",
    },
    successfulClaim: {
      type: Function,
      default: () => {},
    },
    service: {
      type: Function,
      default: () => {},
    },
    record: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTask: {},
      processInstanceId: null,
      today: null,
      tomorrow: null,
      selectedUser: null,
      deployment: null,
      loading: false,
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          width: "20%",
        },
        {
          title: this.$t("tasks.created"),
          dataIndex: "created",
          slots: { customRender: "created" },
          width: "20%",
        },
        {
          title: this.$t("tasks.due"),
          dataIndex: "dueDate",
          slots: { customRender: "due" },
          width: "20%",
        },
        {
          title: this.$t("forms.inputs.priority"),
          dataIndex: "priority",
          slots: { customRender: "priority" },
          width: "20%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
    };
  },
  components: {
    ArrowDownOutlined,
    ArrowUpOutlined,
    BaseTable,
    ExclamationCircleOutlined,
    PushpinOutlined,
    RightSquareOutlined,
    StopOutlined,
    EyeOutlined,
    MoreOutlined,
    EODButton,
    Menu,
    MenuItem: Menu.Item,
    Dropdown,
  },
  created() {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);

    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.tomorrow.setHours(0, 0, 0, 0);

    this.processInstanceId = this.$route.query.processInstanceId || null;
  },
  methods: {
    handleClaim(task) {
      this.loading = true;
      claimTask(task, task.id)
        .then(({ data }) => {
          this.successfulClaim(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleUnclaim(task) {
      this.loading = true;
      unclaimTask(task, task.id)
        .then(({ data }) => {
          this.successfulClaim(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}

@media (min-width: 768px) {
  .actions-mobile {
    display: none;
  }
}
</style>
