<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Tasks' }, title: $t('modules.tasks') }]"
    :current="taskData.name"
    iconComponent="ProfileOutlined"
    :what="!preview ? $tc('tasks.task', 0) : null"
  >
    <template #summary>
      <div class="summary">
        <div>
          {{ $t("tasks.assignee") }}:
          <span class="color--font-secondary">{{
            taskData.assignee || "-"
          }}</span>
        </div>
        <div>
          {{ $t("tasks.created") }}:
          <span class="color--font-secondary">
            {{
              taskData.startTime
                ? new Date(taskData.startTime).toLocaleString()
                : "-"
            }}
          </span>
        </div>
        <div v-if="taskData.endTime">
          {{ $t("tasks.endTime") }}:
          <span class="color--font-secondary">
            {{ new Date(taskData.endTime).toLocaleString() }}
          </span>
        </div>
        <div>
          {{ $t("tasks.due") }}:
          <span :class="taskData.endTime ? '' : dueColorClass">
            {{
              taskData.due ? new Date(taskData.due).toLocaleDateString() : "-"
            }}
          </span>
        </div>
      </div>
    </template>
    <Dropdown
      v-if="document && (hasViewIssuePermission || hasAddIssuePermission)"
      :trigger="['click', 'hover']"
    >
      <EODButton
        class="ml-1"
        :name="$t('modules.issues')"
        icon="FolderOpenOutlined"
        rightIcon="DownOutlined"
      />
      <template v-slot:overlay>
        <Menu selectable>
          <MenuItem
            v-if="hasViewIssuePermission"
            key="addToIssue"
            @click="openAddToIssueModal"
          >
            {{ $t("issues.meta.addTo") }}
          </MenuItem>
          <MenuItem
            v-if="hasAddIssuePermission"
            key="createIssue"
            @click="openIssueModal"
          >
            {{ $t("issues.addNewIssue") }}
          </MenuItem>
        </Menu>
      </template>
    </Dropdown>
    <EODButton
      v-if="!preview && taskData?.actions?.complete"
      :loading="loading"
      class="ml-1"
      htmlType="submit"
      form="task-form"
      :name="$t('app.saveAndLeave')"
      @click="draft = true"
    />
    <EODButton
      v-if="!preview && taskData?.actions?.complete"
      :loading="loading"
      class="ml-1"
      type="primary"
      htmlType="submit"
      form="task-form"
      :name="$t('tasks.endTask')"
      @click="draft = false"
    />
    <EODButton
      v-if="
        preview && assigneeInfo?.id === user.id && taskData?.actions?.unclaim
      "
      class="ml-1"
      :name="$t('tasks.unclaim')"
      @click="handleUnclaim"
    />
    <EODButton
      v-if="preview && taskData?.actions?.assignee"
      class="ml-1"
      :name="$t('tasks.delegate')"
      @click="openAssignUserModal"
    />
    <!--  todo actual claim on click-->
    <EODButtonLink
      v-if="
        preview &&
        assigneeInfo?.id === user.id &&
        (taskData?.actions?.claim || taskData?.actions?.complete)
      "
      :to="{
        name: 'TaskForm',
        params: { id },
      }"
      :title="$t('tasks.start')"
      icon=""
    />
  </Breadcrumbs>
  <AddToIssueModal
    v-if="hasViewIssuePermission && document"
    v-model:visible="showAddToIssueModal"
    :documents="[document]"
    :refreshDocument="fetchDocument"
  />
  <IssueForm
    v-if="hasAddIssuePermission && document"
    v-model:visible="showCreateIssueModal"
    :document="document?.id"
    :onCreate="fetchDocument"
  />

  <Tabs @change="$scrollToTop">
    <TabPane key="form" :tab="$t('tasks.form')">
      <div class="drawer-container">
        <div class="drawer-content">
          <Preview
            v-if="proposal"
            :oid="proposal?.id"
            :data="proposalPreview"
            :title="$t('proposal.preview')"
            :noPreviewMsg="$t('proposal.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.proposal"
          />

          <Preview
            v-if="document"
            :oid="document?.id"
            :data="documentPreview"
            :title="$t('documents.preview')"
            :noPreviewMsg="$t('documents.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.document"
          />

          <Section :title="$t('deployments.form')">
            <FormBuilder
              v-model:modelValue="model"
              v-model:errorsValue="errors"
              v-model:loading="loading"
              :schema="schema"
              :schemaUI="schemaUI"
              :schemaDraft="schemaDraft"
              :submitFunction="submit"
              :preview="preview"
              :draft="draft"
            />
          </Section>

          <Section
            v-if="proposal"
            :title="$t('app.attachments')"
            :collapsable="true"
            v-model:collapsed="collapse.attachments"
          >
            <Table
              :columns="attachmentColumns"
              :data-source="proposalAttachments"
            >
              <template v-slot:description="{ record }">
                {{ record.description || " - " }}
              </template>
              <template v-slot:actions="{ record }">
                <div class="actions">
                  <button
                    v-if="record.content_type === 'application/pdf'"
                    class="action"
                    @click="handlePreview(downloadAttachmentURL(record.id))"
                    :title="$t('app.preview')"
                  >
                    <EyeOutlined />
                  </button>
                  <a
                    class="action"
                    :href="downloadAttachmentURL(record.id)"
                    :title="$t('app.downloadFile')"
                  >
                    <DownloadOutlined />
                  </a>
                </div>
              </template>
            </Table>
          </Section>

          <!-- TODO: refactor to attachments section component -->
          <Section
            v-if="document && !preview"
            :title="$t('app.attachments')"
            :collapsable="true"
            v-model:collapsed="collapse.attachments"
          >
            <template v-slot:button>
              <EODButton
                class="mr-1"
                @click="fetchDocument"
                icon="ReloadOutlined"
                :name="$t('app.refresh')"
              />
            </template>
            <FileUploader
              :fileURL="attachmentURL"
              :downloadFileURL="downloadDocumentURL"
              v-model:fileList="document.attachments"
              @preview-file="handlePreview"
              :fetchData="fetchDocument"
              v-model:newAttachmentsWithDesc="newAttachmentsWithDesc"
              multiple
            />

            <div class="buttons mt-1">
              <Button
                @click="submitAttachments"
                type="primary"
                :title="$t('app.save')"
                :loading="loading$"
              >
                {{ $t("app.save") }}
              </Button>
            </div>
          </Section>

          <Section
            v-if="document && preview"
            :title="$t('app.attachments')"
            :collapsable="true"
            v-model:collapsed="collapse.attachments"
          >
            <Table
              :columns="attachmentColumns"
              :data-source="document.attachments"
            >
              <template v-slot:description="{ record }">
                {{ record.description || " - " }}
              </template>
              <template v-slot:actions="{ record }">
                <div class="actions">
                  <button
                    class="action"
                    @click="handlePreview(downloadDocumentURL(record.id))"
                    :title="$t('app.preview')"
                  >
                    <EyeOutlined />
                  </button>
                  <a
                    class="action"
                    :href="downloadDocumentURL(record.id)"
                    :title="$t('app.downloadFile')"
                  >
                    <DownloadOutlined />
                  </a>
                </div>
              </template>
            </Table>
          </Section>
        </div>

        <EODDrawer :visible="showPreview" :handle="handleDrawer">
          <FileViewer v-if="renderPdf" :previewFile="previewFile" />
        </EODDrawer>
      </div>
    </TabPane>
    <TabPane
      key="comments"
      forceRender
      :tab="`${$t('app.comments')}${
        numberOfComments > 0 ? ` (${numberOfComments})` : ''
      }`"
    >
      <Comments
        :id="id"
        :customStore="commentsCustomStore"
        :preview="preview"
      />
    </TabPane>
    <TabPane key="history" :tab="$t('tasks.history')" disabled />
  </Tabs>

  <SelectModal
    v-model:visible="showAssignUserModal"
    v-model:selectedOption="selectedUser"
    :title="$t('tasks.delegate')"
    :body="$tc('tasks.delegateInfo', [this.taskData.name])"
    :okText="$t('tasks.delegate')"
    :service="fetchUserSimpleList"
    :onSubmit="handleAssignUser"
    :onCancel="closeAssignUserModal"
    selectId="usersToAssignTaskForm"
  />
</template>

<script>
import {
  Breadcrumbs,
  EODButton,
  EODButtonLink,
  EODDrawer,
  Section,
} from "@/components/ui";
import { Button, Dropdown, Menu, Tabs } from "ant-design-vue";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { FileViewer, Preview } from "@/components/common";
import { FormBuilder, formsErrorHandler } from "@/components/forms";
import {
  assignTask,
  completeTask,
  getForm,
  saveTask,
  unclaimTask,
} from "@/services/tasks";
import {
  downloadAttachmentURL,
  getAttachmentsList,
} from "@/services/proposals";
import {
  downloadDocumentURL,
  editDocument,
  getConvertedDocuments,
  getDocument,
} from "@/services/documents";
import { getUser, getUsersSimpleList } from "@/services/permissions";

import AddToIssueModal from "@/views/Documents/AddToIssueModal";
import { Comments } from "@/components/tasks";
import { FileUploader } from "@/components/inputs";
import IssueForm from "@/views/Issues/IssueForm";
import PERMISSIONS from "@/consts/permissions";
import { SelectModal } from "@/components/modals";
import { Table } from "@/components/table";
import addAttachmentDesc from "@/helpers/addAttachmentDesc";
import { ref } from "vue";
import useCommentsNumber from "@/composables/useCommentsNumber";
import useDrawerHandler from "@/composables/useDrawerHandler";

export default {
  name: "TaskForm",
  props: {
    id: { type: String, required: true },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const commentsCustomStore = ref("commentsList");
    return {
      commentsCustomStore,
      ...useDrawerHandler(),
      ...useCommentsNumber(commentsCustomStore.value),
    };
  },
  data() {
    return {
      showAssignUserModal: false,
      attachmentURL: "/api/documents/attachments/",
      title: "",
      model: {},
      schema: { properties: {} },
      schemaUI: {},
      schemaDraft: {},
      // TODO: there is no backend validation for single fields
      errors: {},
      document: null,
      documentPreview: [],
      taskData: {},
      collapse: {
        document: false,
        attachments: false,
        proposal: false,
      },
      draft: false,
      loading: false,
      selectedUser: null,
      assigneeInfo: null,
      attachmentColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
          sorter: (a, b) => a.filename.localeCompare(b.filename),
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          slots: { customRender: "description" },
          width: "35%",
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
      newAttachmentsWithDesc: [],
      showAddToIssueModal: false,
      showCreateIssueModal: false,
      proposal: null,
      proposalPreview: [],
      proposalAttachments: [],
    };
  },
  components: {
    AddToIssueModal,
    Breadcrumbs,
    Button,
    Comments,
    DownloadOutlined,
    Dropdown,
    EODButton,
    EyeOutlined,
    EODButtonLink,
    EODDrawer,
    FileUploader,
    FileViewer,
    FormBuilder,
    IssueForm,
    Menu,
    MenuItem: Menu.Item,
    Preview,
    Section,
    SelectModal,
    Table,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    dueColorClass() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);

      const due = this.taskData.due ? new Date(this.taskData.due) : null;

      if (due) {
        if (due < tomorrow) {
          if (due.getTime() === today.getTime()) {
            return "color--warning";
          }

          return "color--error";
        }

        return "color--success";
      }

      return "color--font-secondary";
    },
    modalBodyContent() {
      return this.$t("tasks.delegateInfo", [this.taskData.name]);
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    hasAddIssuePermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES_ADD);
    },
    hasViewIssuePermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES);
    },
  },
  created() {
    if (this.id) {
      getForm(this.id).then(({ data }) => {
        this.taskData = data.task_data;
        this.assigneeInfo = data.assignee_user;
        this.schema = data.form.schema;
        this.schemaUI = data.form.schema_ui;
        this.schemaDraft = data.form.schema_draft;
        this.model = Object.fromEntries(
          Object.entries(data.variables).map(([key, value]) => [
            key,
            value.value,
          ]),
        );

        if (this.preview) {
          for (const item of Object.values(this.schemaUI)) {
            item.props.disabled = true;
          }
        }

        if (data.proposal) {
          this.proposal = data.proposal;
          this.proposalPreview = [
            { title: this.$t("proposal.title"), value: data.proposal.name },
            {
              title: this.$t("proposal.category"),
              value: data.proposal.category,
            },
            {
              title: this.$t("proposal.submissionDate"),
              value: data.proposal.submission_date
                ? new Date(data.proposal.submission_date).toLocaleString()
                : "-",
            },
            { title: this.$t("app.status"), value: data.proposal.status },
            {
              title: this.$t("proposal.applicant"),
              value: data.proposal.applicant,
            },
          ];

          this.fetchAttachments();
        }

        if (data.document) {
          this.documentPreview = [
            {
              title: this.$t("documents.date"),
              value: new Date(data.document.document_date).toLocaleDateString(),
            },
            { title: this.$t("app.name"), value: data.document.name },
            {
              title: this.$t("documents.JRWANumber"),
              value: data.document.jrwa_display,
            },
            {
              title: this.$t("documents.caseNumber"),
              value: data.document.issue_signature,
            },
            {
              title: this.$t("documents.type"),
              value: data.document.document_type_display,
            },
            {
              title: this.$t("app.description"),
              value: data.document.description,
            },
            {
              title: this.$t("documents.ownStructureFolder"),
              value: data.document.repository_folder_name,
            },
          ];

          if (data.document.original_document) {
            this.documentPreview.push({
              title: this.$t("documents.copyBasedOn"),
              value: data.document.original_document.name,
              to: {
                name: "DocumentsPreview",
                params: { id: data.document.original_document.id },
              },
            });
          }

          this.document = data.document;
          this.document.related_documents = data.document.related_documents.map(
            (item) => item.id,
          );
          this.fetchConverterDocuments(this.document.attachments);
        }
      });
    }
  },
  methods: {
    downloadAttachmentURL,
    downloadDocumentURL,
    fetchAttachments() {
      if (this.proposal?.id) {
        getAttachmentsList(this.proposal?.id).then(({ data: { results } }) => {
          this.proposalAttachments = results;
        });
      }
    },
    // TODO: zoptymalizować to i odświeżanie tabeli po dodaniu api (AWF-913)
    fetchConverterDocuments(documentsList) {
      documentsList.forEach((item) => {
        item.isSave = true;
        getConvertedDocuments(item.id).then(({ data: { results } }) => {
          if (results?.length > 0) {
            item.children = results;
          }
        });
      });
    },
    submit(formData) {
      const service = this.draft ? saveTask : completeTask;

      service({ variables: formData }, this.id, formsErrorHandler)
        .then(({ data }) => {
          this.$message.success(data);
          this.$router.replace({
            name: this.$route.query["back-to"] || "MyTasks",
          });
        })
        .finally(() => {
          this.loading = false;
          this.draft = false;
        });
    },
    submitAttachments() {
      this.loading$ = true;

      const formData = { ...this.document };
      formData.attachments = formData.attachments.map((el) => el.id);

      editDocument(formData, this.document.id)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.newAttachmentsWithDesc.forEach((item) => {
            addAttachmentDesc(this.attachmentURL, item);
          });
          this.newAttachmentsWithDesc = [];
          this.fetchDocument();
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchDocument() {
      getDocument(this.document.id).then(({ data }) => {
        this.document = data;
        this.document.related_documents = data.related_documents.map(
          (item) => item.id,
        );
        this.fetchConverterDocuments(this.document.attachments);
      });
    },
    fetchUserInfo(id) {
      getUser(id).then(({ data }) => {
        this.assigneeInfo = data;
        this.taskData.assignee = data.short_name;
      });
    },
    handleUnclaim() {
      unclaimTask(this.taskData, this.taskData.id).then(({ data }) => {
        this.$message.success(data);
        this.taskData.assignee = "-";
        this.assigneeInfo = null;
      });
    },
    fetchUserSimpleList(params) {
      return getUsersSimpleList({
        processDefinitionId: this.taskData.processDefinitionId,
        taskDefinitionKey: this.taskData.taskDefinitionKey,
        ...params,
      }).then(({ data }) => {
        const results = data.results.map((item) => ({
          ...item,
          name: item.full_name,
        }));
        return { ...data, results };
      });
    },
    openAssignUserModal() {
      this.showAssignUserModal = true;
    },
    closeAssignUserModal() {
      this.showAssignUserModal = false;
      this.selectedUser = null;
    },
    handleAssignUser() {
      this.taskData.user = this.selectedUser;
      assignTask(this.taskData, this.taskData.id).then(({ data }) => {
        this.fetchUserInfo(this.selectedUser);
        this.closeAssignUserModal();
        this.$message.success(data);
      });
    },
    openAddToIssueModal() {
      this.showAddToIssueModal = true;
    },
    openIssueModal() {
      this.showCreateIssueModal = true;
    },
  },
};
</script>
