//TODO: PoC implementation, resolver / mapper for apps
const APPS = {
  DICTS: !!+process.env.VUE_APP_SWITCH_DICTS,
  COUNTERS: !!+process.env.VUE_APP_SWITCH_COUNTERS,
  USERS: !!+process.env.VUE_APP_SWITCH_USERS,
  GROUPS: !!+process.env.VUE_APP_SWITCH_GROUP,
  SAML: !!+process.env.VUE_APP_SWITCH_SAML,
  UNITS: !!+process.env.VUE_APP_SWITCH_UNITS,
  WORKFLOWS: !!+process.env.VUE_APP_SWITCH_CAMUNDA,
  FORMS: !!+process.env.VUE_APP_SWITCH_FORMS,
  DOCUMENTS: !!+process.env.VUE_APP_SWITCH_DOCUMENTS,
  CONTRACTORS: !!+process.env.VUE_APP_SWITCH_CONTRACTORS,
  CONTACTS: !!+process.env.VUE_APP_SWITCH_CONTACTS,
  JRWA: !!+process.env.VUE_APP_SWITCH_JRWA,
  NOTIFICATIONS: !!+process.env.VUE_APP_SWITCH_NOTIFICATIONS,
  REPLACEMENTS: !!+process.env.VUE_APP_SWITCH_REPLACEMENT,
  REPOSITORIES: !!+process.env.VUE_APP_SWITCH_REPOSITORIES,
  MAILBOXES: !!+process.env.VUE_APP_SWITCH_MAILBOXES,
  ISSUES: !!+process.env.VUE_APP_SWITCH_ISSUES,
  REGISTERS: !!+process.env.VUE_APP_SWITCH_REGISTERS,
  SEARCH: !!+process.env.VUE_APP_SWITCH_SEARCH,
  ARCHIVES: !!+process.env.VUE_APP_SWITCH_ARCHIVES,
};

export default APPS;
