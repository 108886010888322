import APPS from "@/consts/apps";
import Documents from "@/views/Documents/Documents.vue";
import DocumentsForm from "@/views/Documents/DocumentsForm.vue";
import DocumentsPreview from "@/views/Documents/DocumentsPreview.vue";
import PERMISSIONS from "@/consts/permissions";
import UnitDocuments from "@/views/Documents/UnitDocuments.vue";
import i18n from "@/i18n";

const t = i18n.global.t;

const documents = [
  {
    path: "/documents/",
    name: "Documents",
    component: Documents,
    meta: {
      title: `${t("meta.app")} - ${t("documents.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DOCUMENTS,
      app: APPS.DOCUMENTS,
    },
  },
  {
    path: "/documents/:id/preview/",
    name: "DocumentsPreview",
    component: DocumentsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("documents.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DOCUMENTS,
      app: APPS.DOCUMENTS,
    },
  },
  {
    path: "/documents/create/",
    name: "DocumentsCreate",
    component: DocumentsForm,
    meta: {
      title: `${t("meta.app")} - ${t("documents.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DOCUMENTS_ADD,
      app: APPS.DOCUMENTS,
    },
  },
  {
    path: "/documents/:id/edit/",
    name: "DocumentsEdit",
    component: DocumentsForm,
    meta: {
      title: `${t("meta.app")} - ${t("documents.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DOCUMENTS_ADD,
      app: APPS.DOCUMENTS,
    },
  },
  {
    path: "/unit-documents/",
    name: "UnitDocuments",
    component: UnitDocuments,
    meta: {
      title: `${t("meta.app")} - ${t("documents.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DOCUMENTS,
      app: APPS.DOCUMENTS,
    },
  },
];

export default documents;
