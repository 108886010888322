import axiosSession from "@/config/apiClient";

const getUsersList = (params) => {
  return axiosSession.get("/api/users/", { params });
};

const getUsersSimpleList = (params) => {
  return axiosSession.get("/api/users/select/", { params });
};

const getUsersDescendantUnitsList = (params) => {
  return axiosSession.get("/api/users/descendant-units/select/", { params });
};

const getUser = (id) => {
  return axiosSession.get(`/api/users/${id}/`);
};

const editUser = (data, id) => {
  return axiosSession.put(`/api/users/${id}/groups/`, data);
};

const getGroupsList = (params) => {
  return axiosSession.get("/api/group/", { params });
};

const getRolesList = (params) => {
  return axiosSession.get("/api/group/roles/", { params });
};

const getGroupsSimpleList = (params) => {
  return axiosSession.get("/api/group/select/", { params });
};

const createRole = (data) => {
  return axiosSession.post("/api/group/roles/", data);
};

const createGroup = (data) => {
  return axiosSession.post("/api/group/", data);
};

const editRole = (data, id) => {
  return axiosSession.put(`/api/group/roles/${id}/`, data);
};

const editGroup = (data, id) => {
  return axiosSession.put(`/api/group/${id}/`, data);
};

const getRole = (id) => {
  return axiosSession.get(`/api/group/roles/${id}/`);
};

const getGroup = (id) => {
  return axiosSession.get(`/api/group/${id}/`);
};

const deleteRole = (id) => {
  return axiosSession.delete(`/api/group/roles/${id}/`);
};

const deleteGroup = (id) => {
  return axiosSession.delete(`/api/group/${id}/`);
};

const getPermissions = (params) => {
  return axiosSession.get("/api/group/permission/", { params });
};

export {
  deleteRole,
  deleteGroup,
  createRole,
  createGroup,
  editRole,
  editGroup,
  editUser,
  getRole,
  getGroup,
  getRolesList,
  getGroupsList,
  getPermissions,
  getUser,
  getUsersList,
  getUsersDescendantUnitsList,
  getGroupsSimpleList,
  getUsersSimpleList,
};
