<template>
  <Breadcrumbs
    :path="[
      {
        path: { name: 'Archive', query: { tab: 'handovers' } },
        title: $t('modules.archive'),
      },
    ]"
    :current="$tc('archive.handovers.handoverLists', 1)"
    iconComponent="ContainerOutlined"
  >
    <EODButton
      v-if="preview"
      :name="$t('deployments.start')"
      icon="PlayCircleOutlined"
      @click="openSelectDeploymentModal"
    />
    <EODButton
      v-if="preview"
      @click="openGenerateHandoverModal"
      :title="$t('archive.handovers.generateHandoverLists')"
      :loading="loading$"
      class="ml-1"
    >
      {{ $t("archive.handovers.generateHandoverLists") }}
    </EODButton>
    <EODButtonLink
      v-if="preview"
      :to="{ name: 'HandoversEdit', params: { id: id } }"
      :title="$t('app.edit_', [$tc('archive.handovers.handoverLists', 0)])"
      icon="EditOutlined"
    />

    <EODButton
      v-if="!preview"
      class="ml-1"
      type="primary"
      @click="openCompleteFolderModal"
      :name="$t('archive.handovers.completeHandoverList')"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('archive.handovers.handoversInfo')"
    :noPreviewMsg="$t('app.noPreview_', [$tc('archive.folders.folder', 1)])"
  />

  <HandoversContent :id="id" />
  <GenerateHandoverListsModal
    v-model:visible="showGenerateHandoverModalPreview"
  />
  <CompleteHandoveListsModal
    v-model:visible="showHandoverListsModal"
    :handover="handover"
    :id="id"
    customStore="handoverFolders"
  />
</template>

<script>
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";
import CompleteHandoveListsModal from "@/components/archive/CompleteHandoverListsModal.vue";
import GenerateHandoverListsModal from "@/components/archive/GenerateHandoverListsModal";
import HandoversContent from "@/components/archive/HandoversContent";
import { Preview } from "@/components/common";
import { getHandover } from "@/services/handovers";

export default {
  name: "HandoverPreview",
  props: {
    id: { type: String, required: true },
    preview: Boolean,
    handover: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      previewData: [],
      activeKey: "handovers",
      showGenerateHandoverModalPreview: false,
      showSelectDeploymentModal: false,
      showHandoverListsModal: false,
      handover: {},
    };
  },
  components: {
    Breadcrumbs,
    CompleteHandoveListsModal,
    EODButton,
    EODButton,
    EODButtonLink,
    HandoversContent,
    GenerateHandoverListsModal,
    Preview,
  },
  created() {
    if (this.id) {
      getHandover(this.id).then(({ data }) => {
        this.handover = data;
        this.previewData = [
          {
            title: this.$t("archive.handovers.handoverCreationDate"),
            value: data.filing_date,
          },
          {
            title: this.$t("archive.handovers.handoverCreationPerson"),
            value: data.user_display,
          },
          {
            title: this.$t("archive.handovers.handoverNumber"),
            value: data.protocol_number,
            info: this.$t("archive.folders.archiveSignatureInfo"),
          },
          { title: this.$t("app.status"), value: data.status_display },
          {
            title: this.$t("issues.unit"),
            value: data.act_symbol,
          },
          {
            title: this.$t("archive.folders.category"),
            value: data.archive_category_display,
          },
          {
            title: this.$t("archive.handovers.handoverType"),
            value: data.protocol_type_display,
          },
        ];
      });
    }
  },
  methods: {
    updateActiveKey(value) {
      this.activeKey = value;
    },
    openGenerateHandoverModal() {
      this.showGenerateHandoverModalPreview = true;
    },
    openSelectDeploymentModal() {
      this.showSelectDeploymentModal = true;
    },
    openCompleteFolderModal() {
      this.showHandoverListsModal = true;
    },
  },
};
</script>
