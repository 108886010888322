<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Documents' }, title: $t('modules.documents') }]"
    :current="title"
    iconComponent="FileTextOutlined"
  >
    <EODButton
      v-if="documentData?.actions?.start_deployment"
      :name="$t('deployments.start')"
      icon="PlayCircleOutlined"
      @click="openSelectDeploymentModal"
    />

    <Dropdown
      v-if="!issue && (hasViewIssuePermission || hasAddIssuePermission)"
      :trigger="['click', 'hover']"
    >
      <EODButton
        v-if="documentData?.actions?.can_be_in_issue"
        class="ml-1"
        :name="$t('modules.issues')"
        icon="FolderOpenOutlined"
        rightIcon="DownOutlined"
      />
      <template v-slot:overlay>
        <Menu selectable>
          <MenuItem
            v-if="hasViewIssuePermission"
            key="addToIssue"
            @click="handleModal"
          >
            {{ $t("issues.meta.addTo") }}
          </MenuItem>
          <MenuItem
            v-if="hasAddIssuePermission"
            key="createIssue"
            @click="openIssueModal"
          >
            {{ $t("issues.addNewIssue") }}
          </MenuItem>
        </Menu>
      </template>
    </Dropdown>

    <EODButtonLink
      v-if="documentData?.actions?.editable"
      :to="{ name: 'DocumentsEdit', params: { id: id } }"
      :title="$t('documents.meta.edit')"
      icon="EditOutlined"
    />

    <Dropdown
      v-if="documentData?.actions?.copyable && hasPermissionToEdit"
      :trigger="['click', 'hover']"
    >
      <EODButton class="ml-1">
        <MoreOutlined />
      </EODButton>
      <template v-slot:overlay>
        <Menu selectable>
          <MenuItem
            @click="copyDocument"
            key="1"
            v-if="documentData?.actions?.copyable"
          >
            <CopyOutlined />
            {{ $t("documents.createCopy") }}
          </MenuItem>
        </Menu>
      </template>
    </Dropdown>

    <template #title>
      {{ title }}
      <CopyOutlined v-if="documentData.original_document" />
    </template>
  </Breadcrumbs>

  <AddToIssueModal
    v-if="hasViewIssuePermission"
    v-model:visible="showModal"
    :documents="[documentData]"
    :refreshDocument="fetchDocument"
  />

  <IssueForm
    v-if="hasAddIssuePermission"
    v-model:visible="showIssueForm"
    :document="id"
    :onCreate="fetchDocument"
  />

  <div class="drawer-container">
    <div class="drawer-content">
      <Preview
        :oid="id"
        :data="previewData"
        :title="$t('documents.preview')"
        :noPreviewMsg="$t('documents.noPreview')"
      >
        <p class="related-documents-label">{{ $t("documents.related") }}:</p>
        <BaseTable
          :columns="relatedColumns"
          :service="(params) => getRelatedDocument(params, id)"
          disableRefresh
          :hasSearch="false"
        >
          <template v-slot:actions="{ record }">
            <TableActions
              :record="record"
              :previewRoute="{
                name: 'DocumentsPreview',
                params: { id: record.id },
              }"
              :showEdit="false"
              :showRemove="false"
            />
          </template>
        </BaseTable>
      </Preview>
      <Section v-if="showProcesses" :title="$t('documents.processes.label')">
        <BaseTable
          :columns="processesColumns"
          :service="(params) => getDocumentProcessesList(id, params)"
          :hasSearch="false"
        >
          <template v-slot:processId="{ record }">
            <Tooltip :title="record.process_id">
              <InfoCircleOutlined />
            </Tooltip>
          </template>
          <template v-slot:numeration="{ record }">
            {{ record.value || "-" }}
          </template>
          <template v-slot:created_by="{ record }">
            {{ record?.created_by?.full_name || "-" }}
          </template>
          <template v-slot:deployment="{ record }">
            {{
              (record.deployment?.version !== 1
                ? `${record.deployment?.name} - ${$t("app.version")} ${
                    record.deployment?.version
                  }`
                : record.deployment?.name) || "-"
            }}
          </template>
          <template v-slot:startTime="{ record }">
            {{ new Date(record.created).toLocaleString() }}
          </template>
          <template #state="{ record }">
            <StatusDot :status="record.finished ? 2 : 1" />
            {{
              record.finished
                ? $t("search.processCompleted")
                : $t("processes.active")
            }}
          </template>
          <template v-slot:actions="{ record }">
            <div class="actions">
              <router-link
                v-if="record.viewable"
                class="action"
                :to="{
                  name: 'ProcessPreview',
                  params: { id: record.process_id },
                }"
                :title="$t('app.preview')"
              >
                <EyeOutlined />
              </router-link>
              <PrintBarcodes v-if="record.value" :record="record" />
            </div>
          </template>
        </BaseTable>
      </Section>

      <Section
        v-if="attachmentsData.length > 0"
        :title="$t('app.attachments')"
        :collapsable="true"
        v-model:collapsed="collapse.attachments"
      >
        <Table :columns="attachmentColumns" :data-source="attachmentsData">
          <template v-slot:description="{ record }">
            {{ record.description || "-" }}
          </template>
          <template v-slot:actions="{ record }">
            <div class="actions">
              <button
                v-if="/(pdf)$/.test(record.filename)"
                class="action"
                @click="handlePreview(downloadDocumentURL(record.id))"
                :title="$t('app.preview')"
              >
                <EyeOutlined />
              </button>
              <a
                class="action"
                :href="downloadDocumentURL(record.id)"
                :title="$t('app.downloadFile')"
              >
                <DownloadOutlined />
              </a>
            </div>
          </template>
        </Table>
      </Section>
      <Preview
        :data="sharePreviewData"
        :noPreviewMsg="$t('app.share')"
        v-if="displayShareSection"
      />
    </div>
    <EODDrawer :visible="showPreview" :handle="handleDrawer">
      <FileViewer v-if="renderPdf" :previewFile="previewFile" />
    </EODDrawer>
  </div>

  <StartDeploymentForDocModal
    v-if="documentData?.actions?.start_deployment"
    v-model:visible="showSelectDeploymentModal"
    :documentName="documentData.name"
    selectId="deploymentsToStartDocPreview"
    :documentsId="[id]"
  />
</template>

<script>
import { BaseTable, StatusDot, Table, TableActions } from "@/components/table";
import {
  Breadcrumbs,
  EODButton,
  EODButtonLink,
  EODDrawer,
  Section,
} from "@/components/ui";
import {
  CopyOutlined,
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons-vue";
import { Dropdown, Menu, Tooltip } from "ant-design-vue";
import { FileViewer, Preview } from "@/components/common";
import {
  copyDocument,
  downloadDocumentURL,
  getDocument,
  getDocumentPerms,
  getRelatedDocument,
} from "@/services/documents";

import AddToIssueModal from "@/views/Documents/AddToIssueModal";
import IssueForm from "@/views/Issues/IssueForm";
import PERMISSIONS from "@/consts/permissions";
import PrintBarcodes from "@/components/numeration/PrintBarcodes";
import StartDeploymentForDocModal from "./StartDeploymentForDocModal";
import { getConvertedDocuments } from "@/services/documents";
import { getDocumentProcessesList } from "@/services/deployments";

export default {
  name: "DocumentsPreview",
  data() {
    return {
      previewFile: "",
      showPreview: false,
      renderPdf: false,
      issueName: null,
      showModal: false,
      showIssueForm: false,
      id: +this.$route.params?.id,
      issue: false,
      documentData: {},
      previewData: [],
      sharePreviewData: [],
      attachmentsData: [],
      showSelectDeploymentModal: false,
      relatedColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: "JRWA",
          dataIndex: "jrwa_display",
          sorter: true,
        },
        {
          title: this.$t("documents.caseNumber"),
          dataIndex: "issue_signature",
          sorter: true,
        },
        {
          title: this.$t("app.createdDate"),
          dataIndex: "document_date",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
      processesColumns: [
        {
          dataIndex: "processId",
          slots: { customRender: "processId" },
          width: "5%",
        },
        {
          title: this.$t("processes.number"),
          dataIndex: "numeration",
          ellipsis: true,
          slots: { customRender: "numeration" },
        },
        {
          title: this.$t("tasks.deployment"),
          dataIndex: "deployment",
          ellipsis: true,
          slots: { customRender: "deployment" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "finished",
          slots: { customRender: "state" },
        },
        {
          title: this.$t("processes.createdBy"),
          dataIndex: "created_by",
          slots: { customRender: "created_by" },
        },
        {
          title: this.$t("app.created"),
          dataIndex: "startTime",
          slots: { customRender: "startTime" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
      attachmentColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
          sorter: (a, b) => a.filename.localeCompare(b.filename),
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          slots: { customRender: "description" },
          width: "35%",
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
      collapse: {
        attachments: false,
      },
    };
  },
  components: {
    AddToIssueModal,
    BaseTable,
    Breadcrumbs,
    CopyOutlined,
    DownloadOutlined,
    Dropdown,
    EODButton,
    EODButtonLink,
    EODDrawer,
    EyeOutlined,
    FileViewer,
    InfoCircleOutlined,
    IssueForm,
    Menu,
    MenuItem: Menu.Item,
    MoreOutlined,
    Preview,
    Section,
    StartDeploymentForDocModal,
    StatusDot,
    Table,
    TableActions,
    Tooltip,
    PrintBarcodes,
  },
  computed: {
    addToFolderTitle() {
      return this.documentHasFolder
        ? this.$t("repository.folder")
        : this.$t("documents.addToFolder");
    },
    title() {
      return `${
        this.documentData.original_document
          ? `[${this.$t("documents.copy")}] `
          : ""
      }${this.documentData.name}`;
    },
    showProcesses() {
      return (
        this.$permissions.includes(PERMISSIONS.TASKS_ALL) ||
        this.$permissions.includes(PERMISSIONS.TASKS_OWN)
      );
    },
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS_ADD);
    },
    hasAddIssuePermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES_ADD);
    },
    hasViewIssuePermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES);
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    displayShareSection() {
      return this.user.id === this.previewData.created_by || this.user.is_boss;
    },
  },
  created() {
    if (this.id) {
      this.fetchDocument();
    }
  },
  methods: {
    getRelatedDocument,
    downloadDocumentURL,
    openIssueModal() {
      this.showIssueForm = true;
    },
    handleDrawer() {
      this.showPreview = !this.showPreview;
      if (this.showPreview) {
        // TODO: find a better way (maybe)
        setTimeout(() => {
          this.renderPdf = true;
        }, 400);
      } else {
        this.renderPdf = false;
      }
    },
    handlePreview(file) {
      this.previewFile = file;
      this.showPreview = true;
      // TODO: find a better way (maybe)
      setTimeout(() => {
        this.renderPdf = true;
      }, 400);
    },
    handleModal() {
      this.showModal = true;
    },
    getDocumentProcessesList,
    // TODO: zoptymalizować to i odświeżanie tabeli po dodaniu api (AWF-913)
    fetchConverterDocuments(documentsList) {
      documentsList.forEach((item) => {
        getConvertedDocuments(item.id).then(({ data: { results } }) => {
          if (results?.length > 0) {
            item.children = results;
          }
        });
      });
    },
    fetchDocument() {
      getDocument(this.id).then(({ data }) => {
        this.previewData = [
          {
            title: this.$t("documents.date"),
            value: new Date(data.document_date).toLocaleDateString(),
          },
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("documents.JRWANumber"),
            value: data.jrwa_display,
          },
          {
            title: this.$t("documents.caseNumber"),
            value: data.issue_signature,
            to: {
              name: "IssuePreview",
              params: { id: data.issue },
            },
          },
          {
            title: this.$t("documents.type"),
            value: data.document_type_display,
          },
          {
            title: this.$t("app.description"),
            value: data.description,
          },
          {
            title: this.$t("documents.ownStructureFolder"),
            value: data.repository_folder_name,
          },
        ];

        if (data.original_document) {
          this.previewData.push({
            title: this.$t("documents.copyBasedOn"),
            value: data.original_document.name,
            to: {
              name: "DocumentsPreview",
              params: { id: data.original_document.id },
            },
          });
        }

        this.documentData = data;
        this.documentHasFolder = !!data.repository_folder_name;
        this.issueName = this.previewData[1];
        this.attachmentsData = data.attachments;
        this.issue = data.issue;
        this.fetchConverterDocuments(this.attachmentsData);
        if (this.displayShareSection) {
          this.fetchDocumentPerms();
        }
      });
    },
    fetchDocumentPerms() {
      getDocumentPerms(this.id).then(({ data }) => {
        const canPreview = data.groups_can_view
          .map((item) => item.name)
          .concat(data.users_can_view.map((item) => item.name));
        const canEdit = data.groups_can_change
          .map((item) => item.name)
          .concat(data.users_can_change.map((item) => item.name));
        this.sharePreviewData = [
          { title: this.$t("app.sharedToRead"), value: canPreview },
          { title: this.$t("app.sharedToEdit"), value: canEdit },
        ];
      });
    },
    // TODO: composition API
    copyDocument() {
      copyDocument(this.id).then(({ data }) => {
        // TODO: make it work with router-link
        this.$message.success(
          <>
            {this.$t("documents.copySuccess")}
            <a href={`/documents/${data.id}/preview/`}>{this.$t("app.here")}</a>
          </>,
        );
      });
    },
    openSelectDeploymentModal() {
      this.showSelectDeploymentModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables.scss";

.dropdown-activator {
  margin-left: 1rem;

  &:hover,
  &:focus {
    cursor: pointer;
    color: $primary;
  }
}

.dropdown-button {
  padding: 0.5rem 1rem;
}

.related-documents-label {
  text-align: left;
  padding: 3rem 1rem 0;
  font-weight: bold;
}
</style>
