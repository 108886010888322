export default function generateSchemaDraft(schema: any) {
  const schemaDraft = JSON.parse(JSON.stringify(schema));
  schemaDraft.required = [];
  if (schemaDraft.allOf) {
    Object.values(schemaDraft.allOf).forEach((item: any) => {
      item.then.required = [];
      Object.values(item.then.properties).forEach((elem: any) => {
        if (elem.items?.required?.length > 0) {
          elem.items.required = [];
        }
      });
    });
  }

  Object.values(schemaDraft.properties).forEach((item: any) => {
    if (item.items?.required?.length > 0) {
      item.items.required = [];
    }
  });

  return schemaDraft;
}
