import { createRouter, createWebHistory } from "vue-router";

import ErrorComponent from "@/views/Errors/ErrorComponent.vue";
import Home from "@/views/Home/Home.vue";
import Login from "@/views/Login.vue";
import { RouterScrollBehavior } from "vue-router";
import { SSO_URLS } from "@/consts/statuses";
import archive from "./modules/archive";
import contacts from "./modules/contacts";
import contractors from "./modules/contractors";
import deployments from "./modules/deployments";
import dictionaries from "./modules/dictionaries";
import documents from "./modules/documents";
import forms from "./modules/forms";
import i18n from "@/i18n";
import issues from "./modules/issues";
import jrwa from "./modules/jrwa";
import mailboxes from "./modules/mailboxes";
import notifications from "./modules/notifications";
import numeration from "./modules/numeration";
import registry from "./modules/registry";
import replacements from "./modules/replacements";
import repository from "./modules/repository";
import roles from "./modules/roles";
import search from "./modules/search";
import { store } from "@/store/";
import tasks from "./modules/tasks";
import teams from "./modules/teams";
import units from "./modules/units";
import users from "./modules/users";

const t = i18n.global.t;

const scrollBehavior = function (to, from, savedPosition) {
  if (to.hash) {
    setTimeout(() => {
      const element = document.querySelector(to.hash);
      if (element && element.scrollIntoView) {
        element.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }, 100);

    return { selector: to.hash };
  } else if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0, left: 0 };
  }
} as RouterScrollBehavior;

// TODO: add props support
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: `${t("meta.app")} - ${t("meta.home")}`, requiresAuth: true },
  },
  {
    path: "/login/",
    name: "Login",
    component: Login,
    meta: {
      title: `${t("meta.app")} - ${t("meta.login")}`,
      requiresAuth: false,
    },
  },
  {
    path: "/403/",
    name: "403",
    component: ErrorComponent,
    meta: {
      title: `${t("meta.app")} - 403`,
      requiresAuth: true,
    },
    props: { type: "403" },
  },
  ...archive,
  ...contacts,
  ...contractors,
  ...deployments,
  ...dictionaries,
  ...documents,
  ...forms,
  ...issues,
  ...jrwa,
  ...mailboxes,
  ...notifications,
  ...numeration,
  ...roles,
  ...registry,
  ...replacements,
  ...repository,
  ...search,
  ...tasks,
  ...teams,
  ...units,
  ...users,
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: ErrorComponent,
    meta: {
      title: `${t("meta.app")} - 404`,
      requiresAuth: true,
    },
    props: { type: "404" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach(async (to, _, next) => {
  if (to.query?.access_token) {
    localStorage.setItem("access_token", to.query.access_token.toString());
  }

  if (to.query?.logout) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    store.dispatch("user/clear", { root: true });
    window.location.href = `${SSO_URLS.LOGOUT}?redirect=${to.query?.logout}`;
  }

  const token = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  if (to.name === "Login" && token) {
    next({ name: "Home" });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !token &&
    !refreshToken
  ) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else {
    if (to.meta.app === false) {
      next({ name: "404" });
    }

    if (store.getters["user/getUser"].permissions.length === 0) {
      await store.dispatch("user/add", { root: true });
    }

    if (to.meta.permission) {
      let hasPermissionToRoute;
      if (Array.isArray(to.meta.permission)) {
        hasPermissionToRoute = to.meta.permission.some((item) =>
          store.getters["user/getUser"].permissions.includes(item),
        );
      } else {
        hasPermissionToRoute = store.getters[
          "user/getUser"
        ].permissions.includes(to.meta.permission);
      }
      if (hasPermissionToRoute) {
        next();
      } else {
        next({ name: "403" });
      }
    } else {
      next();
    }
  }
});

export default router;
