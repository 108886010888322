<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Registry' }, title: $t('modules.registry') }]"
    :current="title"
    iconComponent="CarryOutOutlined"
    :what="$t('registry.registry')"
  >
    <Button
      v-if="id"
      type="danger"
      ghost
      :title="deleteTitle"
      @click="confirmDelete"
    >
      <DeleteOutlined />
      {{ deleteTitle }}
    </Button>
  </Breadcrumbs>

  <form id="register-form" @submit.prevent="submit">
    <Section :title="$t('registry.info')">
      <div class="form-container">
        <div class="form">
          <EODInput
            id="name"
            v-model:value="data.name"
            :label="$t('registry.name')"
            :errors="errors.name"
            :maxlength="250"
            required
          />

          <EODInput
            id="description"
            type="textarea"
            v-model:value="data.description"
            :label="$t('app.description')"
            :errors="errors.description"
            :maxlength="500"
          />

          <EODSelect
            id="counter"
            v-model:value="data.counter"
            :label="$t('modules.numeration')"
            :service="fetchNumerations"
            :errors="errors.counter"
            :defaultValue="defaultCounter"
            required
            @change="onNumerationChange"
          />

          <EODTreeSelect
            v-if="isUnitCounter"
            id="unit"
            :label="$t('issues.unit')"
            v-model:value="data.unit"
            :treeData="unitsList"
            :onLoadData="onLoadData"
            v-model:searchValue="searchUnit"
            @search="fetchUnits"
            @change="onTreeSelectChange"
            :errors="errors.unit"
            required
          />
        </div>
      </div>
    </Section>

    <Section :title="$t('registry.variables.info')">
      <template #checkbox>
        <Tooltip :title="$t('registry.variables.tip')">
          <InfoCircleOutlined />
        </Tooltip>
      </template>
      <ColumnsSection :columns="data.columns" />
    </Section>

    <Section :title="$t('app.share')" v-if="id">
      <ShareForm
        v-model:perms="perms"
        :defaultValues="permsDefaultValues"
        :errors="perms.errors"
        :editPerms="false"
        :submitFunction="submitPerms"
      />
    </Section>

    <Button
      class="mb-2"
      form="register-form"
      htmlType="submit"
      type="primary"
      :title="$t('app.save')"
      :loading="loading$"
    >
      {{ $t("registry.save") }}
    </Button>
  </form>
</template>

<script>
import { Breadcrumbs, Section } from "@/components/ui";
import { Button, Tooltip } from "ant-design-vue";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { EODInput, EODSelect } from "@/components/inputs";
import {
  createRegistry,
  deleteRegistry,
  editRegistry,
  editRegistryPerms,
  getRegistry,
  getRegistryPerms,
} from "@/services/registers";

import ColumnsSection from "./ColumnsSection";
import { EODTreeSelect } from "@/components/inputs";
import { STATUS } from "@/consts/statuses";
import { ShareForm } from "@/components/common";
import { getNumerationSelect } from "@/services/numeration";
import { getUnitsSelect } from "@/services/units";

export default {
  name: "RegistryForm",
  data() {
    return {
      id: +this.$route.params?.id,
      data: {
        name: "",
        description: "",
        columns: [],
        counter: null,
        unit: null,
        unit_display: "",
      },
      perms: {
        users_can_change: [],
        groups_can_change: [],
        users_can_view: [],
        groups_can_view: [],
        errors: {},
      },
      permsDefaultValues: {},
      errors: {},
      isUnitCounter: false,
      defaultCounter: null,
      searchUnit: "",
      unitsList: [],
    };
  },
  components: {
    Button,
    Breadcrumbs,
    ColumnsSection,
    DeleteOutlined,
    EODInput,
    EODSelect,
    EODTreeSelect,
    InfoCircleOutlined,
    Section,
    ShareForm,
    Tooltip,
  },
  computed: {
    title() {
      return this.id
        ? this.$t("app.edit_", [this.$t("registry.registry")])
        : this.$t("app.add_", [this.$t("registry.registry")]);
    },
    deleteTitle() {
      return `${this.$t("app.delete")} ${this.$t("registry.registry")}`;
    },
  },
  created() {
    if (this.id) {
      getRegistryPerms(this.id).then(({ data }) => {
        this.permsDefaultValues = data;
        this.perms = Object.entries(data).reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key]: val.map((item) => item.id),
          }),
          {},
        );
      });

      getRegistry(this.id).then(({ data }) => {
        this.data = data;
        this.defaultCounter = this.$getDefaultValue(data, "counter");
        this.isUnitCounter = !!data.unit;
        this.data.columns.reverse();
        this.fetchUnits();
      });
    } else {
      this.fetchUnits();
    }
  },
  methods: {
    confirmDelete() {
      this.$store.dispatch("setModal", {
        modalOk: this.deleteRegistry,
        title: this.$t("documents.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "registry.registry",
          1,
        )} "${this.data.name}"?`,
      });
    },
    deleteRegistry() {
      deleteRegistry(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Registry" });
      });
    },
    editRegistry,
    editRegistryPerms,
    getRegistry,
    getRegistryPerms,
    fetchNumerations(params) {
      return getNumerationSelect({ status: STATUS.ACTIVE, ...params }).then(
        ({ data }) => data,
      );
    },
    submit() {
      this.loading$ = true;

      const service = this.id ? editRegistry : createRegistry;

      if (this.id) {
        this.submitPerms();
      }

      service(this.data, this.id)
        .then(() => {
          this.$router.push({ name: "Registry" });

          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    submitPerms() {
      this.loading$ = true;

      editRegistryPerms(this.perms, this.id)
        .then(({ data: msg }) => {
          this.$message.success(
            typeof msg === "string" ? msg : this.$t("app.success"),
          );
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    onNumerationChange(val) {
      this.isUnitCounter = this.$store.state.select_counter?.serviceOptions?.find(
        (item) => item.id === val,
      )?.is_unit_counter;
    },
    getTreeNode(item) {
      return {
        id: item.id,
        pId: item.parent || 0,
        value: item.id,
        title: item.name,
        isLeaf: !item.children,
      };
    },
    fetchUnits() {
      // TODO: proper hierarchic select API AWF-1425
      return getUnitsSelect({
        limit: 100,
        search_field: this.searchUnit,
      }).then(({ data: { results } }) => {
        this.unitsList = results.map(this.getTreeNode);

        if (
          this.data.unit &&
          !this.unitsList.find((elem) => elem.id === this.data.unit) &&
          !this.searchUnit
        ) {
          this.unitsList.push({
            id: this.data.unit,
            pId: 0,
            value: this.data.unit,
            title: this.data.unit_display,
            isLeaf: true,
          });
        }
      });
    },
    onLoadData(treeNode) {
      const { id: nodeId } = treeNode.dataRef;
      return getUnitsSelect({ parent: nodeId }).then(
        ({ data: { results } }) => {
          const children = results.map((item) => {
            if (item.id === this.data.unit) {
              this.unitsList = this.unitsList.filter(
                (elem) => elem.id !== this.data.unit,
              );
            }
            return this.getTreeNode(item);
          });

          this.unitsList = this.unitsList.concat(children);
        },
      );
    },
    onTreeSelectChange(val) {
      this.searchUnit = "";
      this.data.unit_display = this.unitsList.find(
        (elem) => elem.id === val,
      )?.title;
    },
  },
};
</script>
