
import {
  ContainerOutlined,
  FolderOutlined,
  FormOutlined,
  HomeOutlined,
  IdcardOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  SortAscendingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons-vue";

import APPS from "@/consts/apps";
import { Menu } from "ant-design-vue";
import PERMISSIONS from "@/consts/permissions";
import RegistryMenuItem from "@/components/registry/RegistryMenuItem.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "EODMenu",
  props: {
    collapsed: Boolean,
  },
  data() {
    return {
      APPS,
      selectedKeys: [],
      openKeys: [],
      submenus: {
        Documents: "workspace",
        Mailboxes: "workspace",
        Dictionaries: "dictionaries",
        JRWA: "dictionaries",
        Users: "permissions",
        Roles: "permissions",
      },
      PERMISSIONS,
    };
  },
  components: {
    ContainerOutlined,
    FolderOutlined,
    FormOutlined,
    HomeOutlined,
    IdcardOutlined,
    Menu,
    MenuItem: Menu.Item,
    MessageOutlined,
    PartitionOutlined,
    ProfileOutlined,
    RegistryMenuItem,
    SnippetsOutlined,
    SortAscendingOutlined,
    SubMenu: Menu.SubMenu,
    SyncOutlined,
    TeamOutlined,
    UserSwitchOutlined,
    OrderedListOutlined,
  },
  computed: {
    isWorkspaceVisible() {
      return (
        (this.$permissions.includes(this.PERMISSIONS.DOCUMENTS) ||
          this.$permissions.includes(this.PERMISSIONS.MAILBOX)) &&
        (APPS.DOCUMENTS || APPS.MAILBOXES)
      );
    },
    isDictionariesVisible() {
      return (
        (this.$permissions.includes(this.PERMISSIONS.DICTIONARIES) ||
          this.$permissions.includes(this.PERMISSIONS.JRWA)) &&
        (APPS.DICTS || APPS.JRWA)
      );
    },
    isPermissionsVisible() {
      return (
        (this.$permissions.includes(this.PERMISSIONS.USERS) ||
          this.$permissions.includes(this.PERMISSIONS.ROLES) ||
          this.$permissions.includes(this.PERMISSIONS.GROUPS)) &&
        (APPS.USERS || APPS.GROUPS)
      );
    },
    hasPermissionsToTaskPreview() {
      return (
        this.$permissions.includes(PERMISSIONS.TASKS_ALL) ||
        this.$permissions.includes(PERMISSIONS.TASKS_OWN)
      );
    },
    hasPermissionsToAllTasks() {
      return this.$permissions.includes(PERMISSIONS.TASKS_ALL);
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  mounted() {
    const submenus = document.querySelectorAll<HTMLElement>(
      ".ant-menu-submenu-title",
    );

    submenus.forEach((element) => {
      element.setAttribute("tabindex", "0");
      element.addEventListener("keydown", (evt) => {
        if (evt.code === "Enter") {
          element.click();
        }
      });
    });
  },
  watch: {
    $route(to) {
      this.selectedKeys = [to.name];

      if (this.submenus[to.name] && this.openKeys.length === 0) {
        this.openKeys = [this.submenus[to.name]];
      }
    },
    collapsed() {
      this.openKeys = [];
    },
  },
});
