<template>
  <Breadcrumbs
    :path="[
      {
        path: { name: 'Archive', query: { tab: 'folders' } },
        title: $t('modules.archive'),
      },
    ]"
    :current="$t('archive.folders.meta.preview')"
    iconComponent="ContainerOutlined"
  >
    <EODButtonLink
      :to="{ name: 'FoldersEdit', params: { id: id } }"
      :title="$t('app.edit_', [$tc('archive.folders.folder', 0)])"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('archive.folders.meta.preview')"
    :noPreviewMsg="$t('app.noPreview_', [$tc('archive.folders.folder', 1)])"
  />

  <FoldersContent
    :id="id"
    v-if="id"
    @update:activeKey="updateActiveKey"
    :activeKey="activeKey"
    preview
  />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import FoldersContent from "@/components/archive/FoldersContent";
import { Preview } from "@/components/common";
import { getFolder } from "@/services/archive";

export default {
  name: "FoldersPreview",
  props: { id: String },
  data() {
    return {
      previewData: [],
      activeKey: "issues",
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    FoldersContent,
    Preview,
  },
  created() {
    if (this.id) {
      getFolder(this.id).then(({ data }) => {
        this.previewData = [
          { title: this.$t("issues.unit"), value: data.unit_display },
          { title: this.$t("modules.jrwa"), value: data.jrwa_display },
          {
            title: this.$t("archive.folders.category"),
            value: data.archive_category_display,
          },
          {
            title: this.$t("archive.folders.actSymbol"),
            value: data.act_symbol,
          },
          { title: this.$t("archive.folders.title"), value: data.title },
          {
            title: this.$t("archive.folders.description"),
            value: data.description,
          },
          {
            title: this.$t("archive.folders.yearFrom"),
            value:
              data.edge_date_from === null
                ? ""
                : data.edge_date_from.split("-")[0],
          },
          {
            title: this.$t("archive.folders.yearTo"),
            value:
              data.edge_date_to === null ? "" : data.edge_date_to.split("-")[0],
          },
          {
            title: this.$t("archive.folders.numberOfVolumes"),
            value: data.number_of_volumes,
          },
          {
            title: this.$t("archive.folders.archiveSignature"),
            value: data.archive_signature,
          },
        ];
      });
    }
  },
  methods: {
    updateActiveKey(value) {
      this.activeKey = value;
    },
  },
};
</script>
