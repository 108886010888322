
import { EODButton, EODSeparator } from "@/components/ui";
import { computed, defineComponent, toRefs, watch } from "vue";

import FormBuilderInputs from "./FormBuilderInputs.vue";
import useFormBuilderTreeSelects from "./useFormBuilderTreeSelects";

export default defineComponent({
  name: "MultiValue",
  props: {
    schemaUI: { type: Object, required: true },
    modelValue: { type: Object, required: true },
    errors: { type: Object, required: true },
    modelAdditionalValues: { type: Object, required: true },
    fieldKey: { type: String, required: true },
    fieldValue: { type: Object, required: true },
    defaultValues: { type: Object, required: true },
    index: { type: Number, required: true },
    showDelete: { type: Boolean, required: true },
    removeEntity: { type: Function, required: true },
    required: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const { fieldValue, modelAdditionalValues, modelValue, schemaUI } = toRefs(
      props,
    );

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val: any) {
        emit("update:modelValue", val);
      },
    });

    const additionalValues = computed<Record<string, string | number>>({
      get() {
        return modelAdditionalValues.value;
      },
      set(val) {
        emit("update:modelAdditionalValues", val);
      },
    });

    const { fetchTreeDictionaries, ...treeSelects } = useFormBuilderTreeSelects(
      schemaUI,
      model,
      additionalValues,
    );

    watch(schemaUI, () => {
      fetchTreeDictionaries(fieldValue.value.items.properties);
    });

    fetchTreeDictionaries(fieldValue.value.items.properties);

    return {
      ...treeSelects,
      additionalValues,
      model,
    };
  },
  components: {
    EODButton,
    EODSeparator,
    FormBuilderInputs,
  },
  emits: ["update:modelValue", "update:modelAdditionalValues"],
});
