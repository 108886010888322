<template>
  <Breadcrumbs :current="$t('modules.mailboxes')" iconComponent="MailOutlined">
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'MailboxCreate',
      }"
      :title="$t('mailboxes.add')"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getMailboxList" rowKey="name">
      <template v-slot:actions="{ record }">
        <!-- TODO: PoC backend proper actions -->
        <TableActions
          :record="{
            name: record.name,
            host: record.host,
            last_sync: record.last_sync,
            actions: record.actions,
          }"
          :previewRoute="{
            name: 'MailboxPreview',
            params: { id: record.name },
          }"
          :editRoute="{
            name: 'MailboxEdit',
            params: { id: record.name },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'mailboxes.mailbox',
            2,
          )} &quot;${record.name}&quot;?`"
          :deleteService="() => deleteMailbox(record.name)"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { deleteMailbox, getMailboxList } from "@/services/mailboxes";

import PERMISSIONS from "@/consts/permissions";

export default {
  name: "Mailboxes",
  data() {
    return {
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: this.$t("mailboxes.host"),
          dataIndex: "host",
          sorter: true,
        },
        {
          title: this.$t("mailboxes.lastSync"),
          dataIndex: "last_sync",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.MAILBOX_ADD);
    },
  },
  methods: {
    getMailboxList,
    deleteMailbox,
  },
};
</script>
