import i18n from "@/i18n";

const t = i18n.global.t;

const namesMap = {
  input: t("forms.inputs.input"),
  date: t("forms.inputs.date"),
  daterange: t("forms.inputs.dateRange"),
  dictSelect: t("forms.inputs.dictSelect"),
  simpleSelect: t("forms.inputs.simpleSelect"),
  treeSelect: t("forms.inputs.treeSelect"),
  multiSelect: t("forms.inputs.multiSelect"),
  number: t("forms.inputs.number"),
  textarea: t("forms.inputs.textarea"),
  radio: t("forms.inputs.radio"),
  checkbox: t("forms.inputs.checkbox"),
  numeration: t("modules.numeration"),
  contractors: t("forms.inputs.contractor"),
  jrwa: t("modules.jrwa"),
  separator: t("forms.inputs.separator"),
  currentDate: t("forms.inputs.currentDate"),
  dueDate: t("forms.inputs.deadline"),
  prioritySelect: t("forms.inputs.priority"),
  userDelegation: t("forms.inputs.userDelegation"),
  groupDelegation: t("forms.inputs.teamDelegation"),
  unitDelegation: t("forms.inputs.unitDelegation"),
  calc: t("forms.inputs.calc"),
  info: t("forms.inputs.info"),
  currentUser: t("forms.inputs.currentUser"),
  allUnits: t("forms.inputs.allUnits"),
  infoCheck: t("forms.inputs.infoCheck"),
  contacts: t("forms.inputs.contact"),
  multiValue: t("forms.inputs.multiValue"),
  systemSelect: t("forms.inputs.systemSelect"),
  address: t("forms.inputs.address"),
  editor: t("forms.inputs.editor"),
  usersUnits: t("forms.inputs.usersUnits"),
  bankAccount: t("forms.inputs.bankAccount"),
};

const iconsMap = {
  input: "FontSizeOutlined",
  date: "CalendarOutlined",
  daterange: "FieldTimeOutlined",
  dictSelect: "SortAscendingOutlined",
  simpleSelect: "UnorderedListOutlined",
  treeSelect: "OrderedListOutlined",
  multiSelect: "DownOutlined",
  number: "FieldNumberOutlined",
  textarea: "LineHeightOutlined",
  radio: "CheckCircleOutlined",
  checkbox: "CheckSquareOutlined",
  numeration: "FieldBinaryOutlined",
  contractors: "TeamOutlined",
  jrwa: "ProfileOutlined",
  separator: "LineOutlined",
  currentDate: "CalendarOutlined",
  dueDate: "CarryOutOutlined",
  prioritySelect: "CrownOutlined",
  userDelegation: "UserSwitchOutlined",
  groupDelegation: "TagsOutlined",
  unitDelegation: "BankOutlined",
  calc: "CalculatorOutlined",
  info: "InfoCircleOutlined",
  currentUser: "UserOutlined",
  allUnits: "BankOutlined",
  infoCheck: "CheckSquareOutlined",
  contacts: "TeamOutlined",
  multiValue: "AppstoreAddOutlined",
  systemSelect: "ControlOutlined",
  address: "HomeOutlined",
  editor: "FileMarkdownOutlined",
  usersUnits: "BankOutlined",
  bankAccount: "CreditCardOutlined",
};

const config = [
  {
    id: 1,
    name: namesMap["input"],
    title: "",
    type: "string",
    required: false,
    icon: iconsMap["input"],
    ui: {
      component: "input",
      props: {},
    },
  },
  {
    id: 9,
    name: namesMap["textarea"],
    title: "",
    type: "string",
    required: false,
    icon: iconsMap["textarea"],
    ui: {
      component: "textarea",
      props: {},
    },
  },
  {
    id: 8,
    name: namesMap["number"],
    title: "",
    type: "number",
    required: false,
    multipleOf: 0.01,
    icon: iconsMap["number"],
    ui: {
      component: "number",
      props: {},
    },
  },
  {
    id: 15,
    name: namesMap["separator"],
    title: "",
    required: false,
    icon: iconsMap["separator"],
    ui: {
      component: "separator",
      props: {
        showLine: true,
      },
    },
  },
  {
    id: 23,
    name: namesMap["info"],
    title: "",
    required: false,
    icon: iconsMap["info"],
    ui: {
      component: "info",
      props: {},
    },
  },
  {
    id: 30,
    name: namesMap["editor"],
    title: "",
    type: "string",
    required: false,
    icon: iconsMap["editor"],
    ui: {
      component: "editor",
      props: {},
    },
  },
  {
    id: 10,
    name: namesMap["radio"],
    title: "",
    type: "string",
    enum: [],
    stringRepresentation: "",
    required: false,
    icon: iconsMap["radio"],
    ui: {
      component: "radio",
      props: {},
    },
  },
  {
    id: 11,
    name: namesMap["checkbox"],
    title: "",
    type: "array",
    uniqueItems: true,
    items: {
      type: "string",
      enum: [],
    },
    stringRepresentation: "",
    required: false,
    icon: iconsMap["checkbox"],
    ui: {
      component: "checkbox",
      props: {},
    },
  },
  {
    id: 4,
    name: namesMap["dictSelect"],
    title: "",
    type: "integer",
    required: false,
    icon: iconsMap["dictSelect"],
    ui: {
      component: "dictSelect",
      dictionaryID: undefined,
      props: {},
    },
  },
  {
    id: 6,
    name: namesMap["treeSelect"],
    title: "",
    type: "integer",
    required: false,
    icon: iconsMap["treeSelect"],
    ui: {
      component: "treeSelect",
      dictionaryID: undefined,
      defaultDisplay: undefined,
      props: {},
    },
  },
  {
    id: 7,
    name: namesMap["multiSelect"],
    title: "",
    type: "array",
    uniqueItems: true,
    items: {
      type: "integer",
    },
    default: [],
    required: false,
    icon: iconsMap["multiSelect"],
    ui: {
      component: "multiSelect",
      dictionaryID: undefined,
      props: {},
    },
  },
  {
    id: 5,
    name: namesMap["simpleSelect"],
    title: "",
    type: "string",
    enum: [],
    stringRepresentation: "",
    required: false,
    icon: iconsMap["simpleSelect"],
    ui: {
      component: "simpleSelect",
      props: {},
    },
  },
  {
    id: 28,
    name: namesMap["systemSelect"],
    title: "",
    type: "integer",
    required: false,
    icon: iconsMap["systemSelect"],
    ui: {
      component: "systemSelect",
      props: {},
    },
  },
  {
    id: 3,
    name: namesMap["daterange"],
    title: "",
    type: "array",
    items: {
      type: "string",
      format: "date-time",
    },
    maxItems: 2,
    required: false,
    default: [],
    icon: iconsMap["daterange"],
    ui: {
      component: "daterange",
      props: {},
    },
  },
  {
    id: 2,
    name: namesMap["date"],
    title: "",
    type: "string",
    format: "date-time",
    required: false,
    icon: iconsMap["date"],
    ui: {
      component: "date",
      props: {},
    },
  },
  {
    id: 16,
    name: namesMap["currentDate"],
    title: "",
    type: "string",
    format: "date-time",
    required: false,
    icon: iconsMap["currentDate"],
    ui: {
      component: "currentDate",
      props: {
        disabled: true,
      },
    },
  },
  {
    id: 17,
    name: namesMap["dueDate"],
    title: t("forms.inputs.deadlineTitle"),
    type: "string",
    format: "date-time",
    required: false,
    propertyName: "dueDate",
    icon: iconsMap["dueDate"],
    ui: {
      component: "dueDate",
      props: {},
    },
  },
  {
    id: 12,
    name: namesMap["numeration"],
    title: "",
    type: "string",
    required: false,
    propertyName: "numeration",
    icon: iconsMap["numeration"],
    ui: {
      component: "numeration",
      props: {
        placeholder: t("forms.gui.numerationPlaceholder"),
        disabled: true,
      },
    },
  },
  {
    id: 14,
    name: namesMap["jrwa"],
    type: "integer",
    required: false,
    propertyName: "jrwa",
    title: t("documents.JRWANumber"),
    icon: iconsMap["jrwa"],
    ui: {
      component: "jrwa",
      props: {},
    },
  },
  {
    id: 13,
    name: namesMap["contractors"],
    title: "",
    type: "array",
    default: [],
    uniqueItems: true,
    items: {
      type: "integer",
    },
    required: false,
    icon: iconsMap["contractors"],
    ui: {
      component: "contractors",
      props: {},
    },
  },
  {
    id: 27,
    name: namesMap["contacts"],
    title: "",
    type: "array",
    default: [],
    uniqueItems: true,
    items: {
      type: "integer",
    },
    required: false,
    icon: iconsMap["contacts"],
    ui: {
      component: "contacts",
      props: {},
    },
  },
  {
    id: 18,
    name: namesMap["prioritySelect"],
    title: t("forms.inputs.priorityTitle"),
    type: "integer",
    required: false,
    propertyName: "priority",
    icon: iconsMap["prioritySelect"],
    ui: {
      component: "prioritySelect",
      props: {},
    },
  },
  {
    id: 19,
    name: namesMap["userDelegation"],
    title: t("forms.inputs.userDelegationTitle"),
    type: "array",
    default: [],
    uniqueItems: true,
    items: {
      type: "integer",
    },
    required: false,
    propertyName: "candidate_users",
    icon: iconsMap["userDelegation"],
    ui: {
      component: "userDelegation",
      props: {},
    },
  },
  {
    id: 20,
    name: namesMap["groupDelegation"],
    title: t("forms.inputs.teamDelegationTitle"),
    type: "array",
    default: [],
    uniqueItems: true,
    items: {
      type: "integer",
    },
    required: false,
    propertyName: "candidate_groups",
    icon: iconsMap["groupDelegation"],
    ui: {
      component: "groupDelegation",
      props: {},
    },
  },
  {
    id: 21,
    name: namesMap["unitDelegation"],
    title: t("forms.inputs.unitDelegationTitle"),
    type: "array",
    default: [],
    uniqueItems: true,
    items: {
      type: "integer",
    },
    required: false,
    propertyName: "candidate_units",
    icon: iconsMap["unitDelegation"],
    ui: {
      component: "unitDelegation",
      props: {},
    },
  },
  {
    id: 22,
    name: namesMap["calc"],
    title: "",
    type: "number",
    required: false,
    icon: iconsMap["calc"],
    ui: {
      component: "calc",
      calc: {
        variables: [],
        value: "",
        precision: 2,
      },
      props: {
        disabled: true,
      },
    },
  },
  {
    id: 24,
    name: namesMap["currentUser"],
    title: t("forms.inputs.currentUser"),
    type: "number",
    required: false,
    propertyName: "current_user",
    icon: iconsMap["currentUser"],
    ui: {
      component: "currentUser",
      props: {},
    },
  },
  {
    id: 25,
    name: namesMap["allUnits"],
    title: t("forms.inputs.allUnits"),
    type: "number",
    required: false,
    propertyName: "all_units",
    icon: iconsMap["allUnits"],
    ui: {
      component: "allUnits",
      props: {},
    },
  },
  {
    id: 31,
    name: namesMap["usersUnits"],
    title: "",
    type: "integer",
    required: false,
    propertyName: "users_units",
    icon: iconsMap["usersUnits"],
    ui: {
      component: "usersUnits",
      props: {},
    },
  },
  {
    id: 26,
    name: namesMap["infoCheck"],
    type: "boolean",
    title: "",
    required: true,
    default: false,
    icon: iconsMap["infoCheck"],
    ui: {
      component: "infoCheck",
      checkboxTitle: "",
      props: {},
    },
  },
  {
    id: 27,
    name: namesMap["multiValue"],
    type: "array",
    items: {
      type: "object",
      required: [],
      properties: {},
      additionalProperties: false,
    },
    title: "",
    icon: iconsMap["multiValue"],
    subfields: [],
    ui: {
      component: "multiValue",
      props: {},
      buttonTitle: t("app.addAnother"),
    },
  },
  {
    id: 29,
    name: namesMap["address"],
    type: "array",
    items: {
      type: "object",
      required: [],
      properties: {},
      additionalProperties: false,
    },
    title: t("forms.inputs.address"),
    icon: iconsMap["address"],
    subfields: [
      {
        id: "country",
        name: namesMap["systemSelect"],
        title: t("contractors.country"),
        type: "integer",
        required: true,
        icon: iconsMap["systemSelect"],
        ui: {
          component: "systemSelect",
          props: {},
          dictionaryKeyword: "address_country",
          dictionaryKeyword_display: "Kraj",
        },
        propertyName: "country",
      },
      {
        id: "postcode",
        name: namesMap["input"],
        title: t("contractors.postCode"),
        type: "string",
        required: true,
        icon: iconsMap["input"],
        ui: { component: "input", props: {} },
        propertyName: "postcode",
      },
      {
        id: "city",
        name: namesMap["input"],
        title: t("contractors.city"),
        type: "string",
        required: true,
        icon: iconsMap["input"],
        ui: { component: "input", props: {} },
        propertyName: "city",
      },
      {
        id: "street",
        name: namesMap["input"],
        title: t("contractors.streetName"),
        type: "string",
        required: true,
        icon: iconsMap["input"],
        ui: { component: "input", props: {} },
        propertyName: "street",
      },
      {
        id: "house_number",
        name: namesMap["input"],
        title: t("contractors.streetNumber"),
        type: "string",
        required: true,
        icon: iconsMap["input"],
        ui: { component: "input", props: {} },
        propertyName: "house_number",
      },
      {
        id: "local_number",
        name: namesMap["input"],
        title: t("contractors.streetSuite"),
        type: "string",
        required: false,
        icon: iconsMap["input"],
        ui: { component: "input", props: {} },
        propertyName: "local_number",
      },
    ],
    ui: {
      component: "multiValue",
      props: {},
      multiValueType: "address",
    },
  },
  {
    id: 32,
    name: namesMap["bankAccount"],
    type: "string",
    title: "",
    required: false,
    propertyName: "",
    icon: iconsMap["bankAccount"],
    maxLength: 26,
    minLength: 26,
    ui: {
      component: "bankAccount",
      mask: "99 9999 9999 9999 9999 9999 9999",
      props: {},
    },
  },
];

export { config, iconsMap, namesMap };
