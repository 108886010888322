<template>
  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getContractorsList"
      :customParams="params"
      :customStore="customStore"
    >
      <template v-slot:name="{ record }">
        <CheckCircleOutlined
          v-if="record.verified"
          class="color--success"
          :title="$t('contractors.verified')"
        />
        <CloseCircleOutlined
          v-else
          class="color--grey"
          :title="$t('contractors.unverified')"
        />
        &nbsp;
        {{ record.name }}
      </template>
      <template v-slot:contact="{ record }">
        <template v-if="record.contact_details.length > 0">
          <template v-if="record.contact_details[0].email">
            <a
              :href="`mailto:${record.contact_details[0].email}`"
              :title="$t('contractors.sendEmail')"
            >
              {{ record.contact_details[0].email }}
            </a>
          </template>
          <template v-else>-</template>
          &nbsp;|&nbsp;
          {{ record.contact_details[0].phone || "-" }}
        </template>
        <template v-else>-</template>
      </template>
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'ContractorsPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'ContractorsEdit',
            params: { id: record.id },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$t(
            'contractors.contractor',
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('contractors.delete')"
          :deleteService="deleteContractor"
          :customStore="customStore"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { deleteContractor, getContractorsList } from "@/services/contractors";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "Contractors",
  props: {
    params: Object,
    customStore: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
          ellipsis: true,
          slots: { customRender: "name" },
        },
        {
          title: "NIP",
          dataIndex: "nip",
        },
        {
          title: this.$t("app.contact"),
          dataIndex: "contact_details",
          slots: { customRender: "contact" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    CheckCircleOutlined,
    CloseCircleOutlined,
    StatusDot,
    TableActions,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    deleteContractor,
    getContractorsList,
  },
};
</script>
