import APPS from "@/consts/apps";
import Archive from "@/views/Archive/Archive.vue";
import FoldersForm from "@/views/Archive/FoldersForm.vue";
import FoldersPreview from "@/views/Archive/FoldersPreview.vue";
import HandoverForm from "@/views/Archive/HandoverForm.vue";
import HandoverPreview from "@/views/Archive/HandoverPreview.vue";
import PERMISSIONS from "@/consts/permissions";
import { RouteLocation } from "vue-router";
import i18n from "@/i18n";

const t = i18n.global.t;

const archive = [
  {
    path: "/archive/",
    name: "Archive",
    component: Archive,
    meta: {
      title: `${t("meta.app")} - ${t("modules.archive")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ARCHIVE,
      app: APPS.ARCHIVES,
    },
  },
  {
    path: "/archive/folders/create/",
    name: "FoldersCreate",
    component: FoldersForm,
    meta: {
      title: `${t("meta.app")} - ${t("archive.folders.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ARCHIVE,
      app: APPS.ARCHIVES,
    },
  },
  {
    path: "/archive/folders/:id/edit/",
    name: "FoldersEdit",
    component: FoldersForm,
    meta: {
      title: `${t("meta.app")} - ${t("archive.folders.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ARCHIVE,
      app: APPS.ARCHIVES,
    },
    props: (route: RouteLocation) => ({
      id: +route.params.id,
      showCompleteFolderModalProp: route.params.showCompleteFolderModalProp,
    }),
  },
  {
    path: "/archive/folders/:id/preview/",
    name: "FoldersPreview",
    component: FoldersPreview,
    meta: {
      title: `${t("meta.app")} - ${t("archive.folders.meta.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ARCHIVE,
      app: APPS.ARCHIVES,
    },
    props: true,
  },
  {
    path: "/archive/handovers/:id/edit/",
    name: "HandoversEdit",
    component: HandoverPreview,
    meta: {
      title: `${t("meta.app")} - ${t("archive.folders.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ARCHIVE,
      app: APPS.ARCHIVES,
    },
    props: (route: RouteLocation) => ({
      id: +route.params.id,
      preview: false,
    }),
  },
  {
    path: "/archive/handovers/:id/preview/",
    name: "HandoversPreview",
    component: HandoverPreview,
    meta: {
      title: `${t("meta.app")} - ${t("archive.folders.meta.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ARCHIVE,
      app: APPS.ARCHIVES,
    },
    props: (route: RouteLocation) => ({
      id: +route.params.id,
      preview: true,
    }),
  },
];

export default archive;
