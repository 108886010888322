
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { defineComponent, ref } from "vue";

import { Contact } from "@/interfaces/contacts";
import { Preview } from "@/components/common";
import { PreviewDataNode } from "@/interfaces/common";
import { getContact } from "@/services/contacts";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ContactsPreview",
  props: { id: { type: Number, required: true } },
  setup(props) {
    const { t } = useI18n();

    const previewData = ref<PreviewDataNode[]>([]);
    const editable = ref(false);

    if (props.id) {
      getContact(props.id).then(({ data }: { data: Contact }) => {
        previewData.value = [
          { title: t("contractors.firstName"), value: data.name },
          { title: t("contractors.lastName"), value: data.last_name },
          { title: t("contractors.phone"), value: data.phone_numbers },
          { title: t("contractors.email"), value: data.emails },
          { title: t("contractors.streetName"), value: data.street },
          { title: t("contractors.streetNumber"), value: data.street_number },
          {
            title: t("contractors.streetSuite"),
            value: data.apartment_number,
          },
          { title: t("contractors.postCode"), value: data.postal_code },
          { title: t("contractors.city"), value: data.city },
          { title: t("contractors.country"), value: data.country_display },
        ];
        editable.value = data?.actions?.editable;
      });
    }

    return { editable, previewData };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    Preview,
  },
});
