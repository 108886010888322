<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Roles' }, title: $t('modules.roles') }]"
    :current="`${title} ${id ? data.name : ''}`"
    iconComponent="UserOutlined"
    :what="!preview ? $tc('permissions.roles.roles', 1) : null"
  >
    <EODButtonLink
      v-if="preview && hasPermissionToEdit"
      :to="{ name: 'RolesEdit', params: { id: id } }"
      :title="$t('app.edit_', [$tc('permissions.roles.roles', 2)])"
    />
    <EODButton
      v-if="!preview && id && hasPermissionToDelete"
      class="ml-1"
      type="danger"
      ghost
      @click="confirmDelete"
      icon="DeleteOutlined"
      :name="$t('app.delete_', [$tc('permissions.roles.roles', 2)])"
    />
    <template #title v-if="preview">
      <span>{{ `${title} ${id ? data.name : ""}` }}</span>
      <span class="users-number-info">
        {{ $t("permissions.roles.numberOfUsers") }}:
        <span class="number">{{ data.user_count }}</span>
      </span>
    </template>
  </Breadcrumbs>

  <section class="section">
    <div class="form-container">
      <form @submit.prevent="submit" class="form">
        <EODInput
          id="name"
          v-model:value="data.name"
          :label="$t('app.name')"
          required
          :maxlength="250"
          :errors="errors.name"
          :disabled="preview"
        />
      </form>
    </div>
  </section>

  <Section :title="$tc('permissions.roles.rolePermissions')">
    <template v-if="!preview" v-slot:checkbox>
      <Checkbox :checked="isAllChecked()" @change="checkAll">
        {{ $tc("permissions.roles.fullAccess") }}
      </Checkbox>
    </template>
    <div class="roles-panel">
      <Menu
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        mode="inline"
        :inlineCollapsed="collapsed"
        class="roles-panel__menu"
      >
        <MenuItem v-for="group in permissionsList" :key="group.id">
          <div
            class="menu-item"
            :title="`${$t(`modules.${group.name}`)}${
              selectedCount[group.name] && permissionsCount[group.name]
                ? ` ${selectedCount[group.name]} / ${
                    permissionsCount[group.name]
                  }`
                : ''
            }`"
          >
            <div>
              <component :is="group.icon" />
              {{ $t(`modules.${group.name}`) }}
            </div>
            <div
              v-if="selectedCount[group.name] && permissionsCount[group.name]"
            >
              <CheckCircleOutlined
                v-if="
                  selectedCount[group.name] === permissionsCount[group.name]
                "
                class="color--primary"
                :title="$t('permissions.roles.fullAccess')"
              />
              <span v-else>
                {{
                  `${selectedCount[group.name]} / ${
                    permissionsCount[group.name]
                  }`
                }}
              </span>
            </div>
          </div>
        </MenuItem>
      </Menu>
      <div class="roles-panel__list">
        <div class="group">
          <Checkbox
            v-if="selectedKeys.length > 0 && !preview"
            :checked="
              isAllChecked(
                permissionsList.findIndex(
                  (item) => item.id === selectedKeys[0],
                ),
              )
            "
            @change="
              (e) =>
                checkGroup(
                  e,
                  permissionsList.findIndex(
                    (item) => item.id === selectedKeys[0],
                  ),
                )
            "
          >
            {{ $tc("permissions.roles.fullAccessTo") }}
            {{
              $t(
                `modules.${
                  permissionsList.find((item) => item.id === selectedKeys[0])
                    .name
                }`,
              )
            }}
          </Checkbox>
        </div>
        <div
          v-for="subcategory in permissionsList.find(
            (item) => item.id === selectedKeys[0],
          )?.subcategories"
          :key="subcategory.id"
          class="subcategory"
        >
          <h2 class="subcategory__name">
            {{ $tc("permissions.roles.subcategory") }}:
            {{ $t(`modules.${subcategory.name}`) }}
          </h2>
          <div class="subcategory__fields">
            <div
              class="subcategory__fields-item"
              v-for="field in subcategory.fields"
              :key="field.id"
            >
              <Checkbox
                :checked="
                  !!data.permissions.find((item) => item.id === field.id)
                "
                @change="(e) => checkPermission(e, field)"
                :disabled="preview"
              >
                <p v-if="preview" class="checkbox-label">{{ field.name }}</p>
                {{ !preview ? field.name : null }}
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>

  <Button
    v-if="!preview"
    type="primary"
    :title="$t('app.save')"
    :loading="loading$"
    @click="submit"
    class="mb-2 mt-1"
  >
    {{ $t("app.save") }}
  </Button>
</template>

<script>
import {
  Breadcrumbs,
  EODButton,
  EODButtonLink,
  Section,
} from "@/components/ui";
import { Button, Checkbox, Input, Menu } from "ant-design-vue";
import {
  CarryOutOutlined,
  CheckCircleOutlined,
  ContainerOutlined,
  DownOutlined,
  FolderOutlined,
  FormOutlined,
  HomeOutlined,
  IdcardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  ProfileOutlined,
  SearchOutlined,
  SnippetsOutlined,
  SortAscendingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons-vue";
import {
  createRole,
  deleteRole,
  editRole,
  getPermissions,
  getRole,
} from "@/services/permissions";
import { EODInput } from "@/components/inputs";
import PERMISSIONS from "@/consts/permissions";
import { permissionList } from "./permissionList";

export default {
  name: "RolesForm",
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: +this.$route.params?.id,
      sourceId: +this.$route.params?.sourceId,
      data: { name: "", permissions: [] },
      permissionsList: permissionList(),
      errors: {},
      selectedKeys: [],
      openKeys: [],
      collapsed: false,
      checkedAll: false,
      selectedCount: {},
      permissionsCount: {},
    };
  },
  components: {
    Breadcrumbs,
    Button,
    CarryOutOutlined,
    CheckCircleOutlined,
    ContainerOutlined,
    EODButton,
    EODButtonLink,
    EODInput,
    Input,
    Menu,
    MenuItem: Menu.Item,
    Checkbox,
    Section,
    DownOutlined,
    FolderOutlined,
    FormOutlined,
    HomeOutlined,
    IdcardOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    MessageOutlined,
    PartitionOutlined,
    ProfileOutlined,
    SearchOutlined,
    SnippetsOutlined,
    SortAscendingOutlined,
    SyncOutlined,
    TeamOutlined,
    UserOutlined,
    UserSwitchOutlined,
    OrderedListOutlined,
  },
  computed: {
    action() {
      return this.id ? this.$t("app.editAction") : this.$t("app.addAction");
    },
    title() {
      return !this.preview
        ? this.id
          ? this.$t("app.edit")
          : `${this.$t("app.addition")} ${this.$tc(
              "permissions.roles.roles",
              1,
            )}`
        : "";
    },
    appMap() {
      return this.permissionsList.reduce(
        (acc, item) => ({
          ...acc,
          ...item.subcategories.reduce(
            (sub, elem) => ({ ...sub, [elem.appName]: item.name }),
            {},
          ),
        }),
        {},
      );
    },
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.ROLES_EDIT);
    },
    hasPermissionToDelete() {
      return this.$permissions.includes(PERMISSIONS.ROLES_DELETE);
    },
  },
  created() {
    this.fetchPermissions();

    if (this.id || this.sourceId) {
      this.fetchRole(this.id || this.sourceId);
    }
  },
  methods: {
    confirmDelete() {
      this.$store.dispatch("setModal", {
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "permissions.roles.roles",
          2,
        )} ${this.data.name}? ${this.$tc("permissions.roles.deleteInfo", [
          this.data.user_count,
        ])}`,
        title: `${this.$t("permissions.roles.delete")}`,
        modalOk: this.deleteItem,
      });
    },
    deleteItem() {
      this.loading$ = true;
      deleteRole(this.id)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.$store.dispatch("closeModal");
          this.$router.replace({ name: "Roles" });
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    isAllChecked(groupId) {
      const permissions =
        groupId === undefined
          ? this.permissionsList
          : [this.permissionsList[groupId]];
      for (const permission of permissions) {
        for (const subcategory of permission.subcategories) {
          for (const field of subcategory.fields) {
            if (!this.data.permissions.find((item) => item.id === field.id)) {
              return false;
            }
          }
        }
      }
      return true;
    },
    checkPermission(e, field) {
      if (e.target.checked) {
        this.data.permissions.push(field);
      } else {
        this.data.permissions.splice(
          this.data.permissions.findIndex((item) => item.id === field.id),
          1,
        );
      }
    },
    checkGroup(e, groupId) {
      this.permissionsList[groupId].subcategories.forEach((subcategory) =>
        subcategory.fields.forEach((field) => {
          const index = this.data.permissions.findIndex(
            (permission) => field.id === permission.id,
          );
          if (index > -1) {
            this.data.permissions.splice(index, 1);
          }
        }),
      );
      if (e.target.checked) {
        this.permissionsList[groupId].subcategories.forEach((subcategory) =>
          subcategory.fields.forEach((field) =>
            this.data.permissions.push(field),
          ),
        );
      }
    },
    checkAll(e) {
      this.data.permissions = [];
      if (e.target.checked) {
        this.permissionsList.forEach((permission) =>
          permission.subcategories.forEach((subcategory) =>
            subcategory.fields.forEach((field) =>
              this.data.permissions.push(field),
            ),
          ),
        );
      }
    },
    submit() {
      if (this.data.permissions.length === 0) {
        this.$message.error(this.$t("permissions.roles.noSelectedPermission"));
        return;
      }

      this.loading$ = true;

      const service = this.id ? editRole : createRole;

      const data = {
        ...this.data,
        permissions: this.data.permissions.map((item) => item.id),
      };

      service(data, this.id)
        .then(() => {
          this.$router.push({ name: "Roles" });

          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchPermissions() {
      getPermissions().then(({ data }) => {
        data.forEach((item) =>
          this.permissionsList.find((permission) =>
            permission.subcategories
              .find((el) => el.appName === item.app)
              ?.fields.push(item),
          ),
        );
        this.permissionsCount = data.reduce(
          (acc, item) => ({
            ...acc,
            [this.appMap[item.app]]: (acc[this.appMap[item.app]] ?? 0) + 1,
          }),
          {},
        );
        this.selectedKeys = [this.permissionsList[0].id];
      });
    },
    fetchRole(id) {
      getRole(id).then(({ data }) => {
        this.data.name = this.id ? data.name : "";
        this.data.permissions = data.permissions;
        this.data.user_count = data.user_count;
      });
    },
  },
  watch: {
    "data.permissions": {
      deep: true,
      handler(val) {
        this.selectedCount = val.reduce(
          (acc, item) => ({
            ...acc,
            [this.appMap[item.app]]: (acc[this.appMap[item.app]] ?? 0) + 1,
          }),
          {},
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.checkbox-label {
  color: $font-primary;
  display: inherit;
}

.roles-panel {
  display: flex;
  &__menu {
    width: 22%;
    text-align: left;
  }
  &__list {
    width: 78%;
    padding-left: 2rem;
  }
}
.group {
  padding: 1rem 0;
  border-bottom: 1px solid $background-color;
  text-align: left;
}

.subcategory {
  &__name {
    text-align: left;
    padding-top: 3rem;
  }
  &__fields {
    display: flex;
    height: 15rem;
    flex-wrap: wrap;
    align-content: flex-start;
    &-item {
      text-align: left;
      height: 20%;
      width: 49%;
      margin: 0;
    }
  }
}

.users-number-info {
  font-size: 0.85rem;
  margin-left: 1rem;
  color: $font-tertiary;
}

.number {
  font-weight: bold;
}

.menu-item {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .roles-panel {
    padding-left: 1rem;
    display: flex;
    &__menu {
      width: 28%;
    }
    &__list {
      width: 72%;
      padding-left: 1rem;
    }
  }
  .subcategory {
    &__fields {
      height: auto;
      max-height: 30rem;
      &-item {
        padding-top: 1rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .roles-panel {
    padding-left: 1rem;
    display: flex;
    &__menu {
      width: 30%;
    }
    &__list {
      width: 70%;
    }
  }
}
</style>
