import {
  Deployment,
  DeploymentPerms,
  ServiceTask,
  UserTaskPerms,
} from "@/interfaces/deployments";

import { Params } from "@/interfaces/common";
import apiClient from "@/config/apiClient";

const createDeployment = (data: Deployment) => {
  return apiClient.post("/api/camunda/deployment/", data);
};

const createUserTasksPerms = (
  data: { user_tasks: UserTaskPerms[] },
  id: number,
) => {
  return apiClient.post(`/api/camunda/deployment/${id}/user-tasks-perm/`, data);
};

const deleteDeployment = (id: number) => {
  return apiClient.delete(`/api/camunda/deployment/${id}/`);
};

const editDeployment = (data: Deployment, id: number) => {
  return apiClient.patch(`/api/camunda/deployment/${id}/`, data);
};

const getDeployment = (id: number) => {
  return apiClient.get(`/api/camunda/deployment/${id}/`);
};

const getDeploymentStatuses = () => {
  return apiClient.get("/api/camunda/deployment/status/");
};

const getDeploymentsList = (params: Params) => {
  return apiClient.get("/api/camunda/deployment/", { params });
};

const getDeploymentsSelect = (params: Params) => {
  return apiClient.get("/api/camunda/deployment/select/", { params });
};

// TODO: proper typing
const startDeployment = (data: any, id: number) => {
  return apiClient.post(`/api/camunda/deployment/${id}/start/`, data);
};

const startDeploymentWithProposal = (id: number) => {
  return apiClient.post(`/api/proposals/create-and-run-deployment/${id}/`);
};

const startDeploymentAndClaim = (data: any, id: number) => {
  return apiClient.post(`/api/camunda/deployment/${id}/start-and-claim/`, data);
};

const getUserTasks = (id: number) => {
  return apiClient.get(`/api/camunda/deployment/${id}/user-tasks/`);
};

const getUserTasksPerms = (id: number) => {
  return apiClient.get(`/api/camunda/deployment/${id}/user-tasks-perm/`);
};

const getProcessesList = (params: Params) => {
  return apiClient.get("/api/camunda/processes/", { params });
};
const getDocumentProcessesList = (id: number, params: Params) => {
  return apiClient.get(`/api/documents/${id}/processes/`, { params });
};
const getProcess = (id: string) => {
  return apiClient.get(`/api/camunda/process/${id}/`);
};
const getProcessTasks = (id: string, params: Params) => {
  return apiClient.get(`/api/camunda/process/${id}/tasks/`, { params });
};

const getServiceTasks = (id: number) => {
  return apiClient.get(`/api/camunda/deployment/${id}/service-tasks/`);
};

const editServiceTasks = (data: ServiceTask[], id: number) => {
  return apiClient.post(`/api/camunda/deployment/${id}/service-tasks/`, data);
};

const exportDeployments = (data: { deployments: number[] }) => {
  return apiClient.post("/api/camunda/deployment/export/", data);
};

const importDeployments = () => {
  return apiClient.post("/api/camunda/deployment/import/");
};

const getDeploymentPerms = (id: number) => {
  return apiClient.get(`/api/camunda/${id}/assign-perm/`);
};

const editDeploymentPerms = (data: DeploymentPerms, id: number) => {
  return apiClient.post(`/api/camunda/${id}/assign-perm/`, data);
};

const getDeploymentCategory = () => {
  return apiClient.get(`/api/dicts/entry/keyword/proposal_category/`);
};

export {
  createDeployment,
  createUserTasksPerms,
  deleteDeployment,
  editDeployment,
  getDeploymentCategory,
  editDeploymentPerms,
  editServiceTasks,
  exportDeployments,
  getDeployment,
  getDeploymentPerms,
  getDeploymentStatuses,
  getDeploymentsList,
  getDeploymentsSelect,
  getProcessesList,
  getServiceTasks,
  getUserTasks,
  getUserTasksPerms,
  importDeployments,
  startDeployment,
  startDeploymentAndClaim,
  startDeploymentWithProposal,
  getProcess,
  getProcessTasks,
  getDocumentProcessesList,
};
