<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Users' }, title: $t('modules.users') }]"
    :current="$t('permissions.users.preview')"
    iconComponent="UserOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'UsersEdit', params: { id } }"
      :title="$t('permissions.users.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('permissions.users.preview')"
    :noPreviewMsg="$t('permissions.users.noPreview')"
  />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import { Preview } from "@/components/common";
import { getUser } from "@/services/permissions";

export default {
  name: "UsersPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      previewData: [],
      hasPermissionToEdit: false,
    };
  },
  components: { Breadcrumbs, EODButtonLink, Preview },
  mounted() {
    if (this.id) {
      getUser(this.id).then(({ data }) => {
        this.previewData = [
          { title: "Email", value: data.email },
          {
            title: this.$t("permissions.users.fullName"),
            value: data.full_name,
          },
          {
            title: this.$t("permissions.users.registered"),
            value: data.registered_at,
          },
          {
            title: this.$t("permissions.users.superior"),
            value:
              data.superior_of_units?.length > 0
                ? data.units
                    .filter((e) => data.superior_of_units.includes(e.id))
                    .map((e) => e.name)
                : "-",
          },
          {
            title: this.$tc("permissions.roles.roles", 0),
            value:
              data.roles.length > 0 ? data.roles.map((item) => item.name) : "-",
          },
          {
            title: this.$t("permissions.users.unit", 0),
            value:
              data.units.length > 0 ? data.units.map((item) => item.name) : "-",
          },
        ];
        this.hasPermissionToEdit = data.actions.editable;
      });
    }
  },
};
</script>
