import { Contact } from "@/interfaces/contacts";
import { Params } from "@/interfaces/common";
import axios from "@/config/apiClient";

const getContacts = (params: Params) => {
  return axios.get("/api/contacts/", { params });
};

const createContact = (data: Contact) => {
  return axios.post("/api/contacts/", data);
};

const editContact = (data: Contact, id: number) => {
  return axios.put(`/api/contacts/${id}/`, data);
};

const getContact = (id: number) => {
  return axios.get(`/api/contacts/${id}/`);
};

const deleteContact = (id: number) => {
  return axios.delete(`/api/contacts/${id}/`);
};

const getContactsSelect = (params: Params) => {
  return axios.get("/api/contacts/select/", { params });
};

export {
  createContact,
  deleteContact,
  editContact,
  getContact,
  getContacts,
  getContactsSelect,
};
