const STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  ARCHIVE: 3,
};

const ENTRY_TYPE = {
  TEXT: 1,
  INT: 2,
  FLOAT: 3,
  DATE: 4,
  EMPTY: 5,
};

const BARCODE_TYPE = {
  QR: "qr",
  CODE128: "code128",
};

const BARCODE_PAPER_FORMAT = {
  DEFAULT: 1,
};

const NOTIFICATION_TYPE = {
  EMAIL: 1,
  POPUP: 2,
  SMS: 3,
};

const ISSUE_STATUS = {
  IN_PROGRESS: 1,
  CLOSED: 2,
};
const HANDOVER_TYPE = {
  CASUAL: "N",
  IT_MEDIA: "P",
  PHOTO_DOCUMENTATION: "D",
};

const CATEGORY = {
  ARCHIVE: "A",
  NOT_ARCHIVE: "B",
};
const RESOURCE_TYPES = {
  ISSUE: "issue",
  DOCUMENT: "document",
};

const REPOSITORY_TYPES = {
  JRWA: "JRWA",
  OWN: "OWN",
};

const SORT_FIELDS = {
  CREATED: "created",
  CREATED_REVERSE: "-created",
  INITIATION_DATE: "initiation_date",
  INITIATION_DATE_REVERSE: "-initiation_date",
  DOCUMENT_DATE: "document_date",
  DOCUMENT_DATE_REVERSE: "-document_date",
};

const SORT_FIELDS_FOLDERS = {
  CREATED: "document_date",
  CREATED_REVERSE: "-document_date",
};

const SSO_URLS = {
  LOGIN: "/api/saml/sso?redirect=/",
  LOGOUT: "/api/saml/slo",
};

const USER_SOURCE = {
  LOCAL: "local",
  CBU: "cbu",
};

export {
  BARCODE_TYPE,
  ENTRY_TYPE,
  BARCODE_PAPER_FORMAT,
  HANDOVER_TYPE,
  NOTIFICATION_TYPE,
  STATUS,
  SORT_FIELDS,
  SORT_FIELDS_FOLDERS,
  ISSUE_STATUS,
  CATEGORY,
  REPOSITORY_TYPES,
  RESOURCE_TYPES,
  SSO_URLS,
  USER_SOURCE,
};
