
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { defineComponent, ref } from "vue";
import { deleteContact, getContacts } from "@/services/contacts";

import PERMISSIONS from "@/consts/permissions";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Contacts",
  setup() {
    const { t } = useI18n();

    const columns = ref([
      {
        title: t("contractors.firstName"),
        dataIndex: "name",
        sorter: true,
      },
      {
        title: t("contractors.lastName"),
        dataIndex: "last_name",
        sorter: true,
      },
      {
        title: t("contractors.address"),
        dataIndex: "address",
        slots: { customRender: "address" },
      },
      {
        title: t("app.actions"),
        dataIndex: "actions",
        slots: { customRender: "actions" },
        width: "10%",
      },
    ]);

    return { columns, deleteContact, getContacts, PERMISSIONS };
  },
  components: { Breadcrumbs, BaseTable, EODButtonLink, TableActions },
});
