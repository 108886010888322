<template>
  <Section :title="$t('deployments.basicInformation')">
    <div class="form-container">
      <form id="deployments-form" class="form">
        <EODInput
          id="name"
          v-model:value="data.name"
          :label="$t('app.name')"
          required
          :maxlength="250"
          :errors="errors.name"
        />

        <EODSelect
          id="form"
          v-model:value="data.form"
          :label="$t('deployments.form')"
          required
          :disabled="data.deployment_started"
          :service="fetchFormsList"
          :defaultValue="defaultForm"
          :errors="errors.form"
        />

        <EODSelect
          id="category"
          v-model:value="data.category"
          :label="$t('app.category')"
          required
          :disabled="data.deployment_started"
          :service="fetchCategoryList"
          :defaultValue="defaultCategory"
          :errors="errors.category"
        />

        <EODRadio
          id="status"
          v-model:value="data.status"
          :label="$t('app.status')"
          required
          :options="statusOptions"
          :errors="errors.status"
        />

        <EODDatePicker
          id="available_from"
          v-model:value="data.available_from"
          showTime
          :defaultMidnight="false"
          :label="$t('deployments.availableFrom')"
          :disabledDate="disabledDate"
          :errors="errors.available_from"
        />

        <EODDatePicker
          id="available_to"
          v-model:value="data.available_to"
          showTime
          :defaultMidnight="false"
          :label="$t('deployments.availableTo')"
          :disabledDate="disabledDate"
          :errors="errors.available_to"
        />

        <EODInput
          id="description"
          v-model:value="data.description"
          :label="$t('app.description')"
          :maxlength="500"
          type="textarea"
          :errors="errors.description"
        />
      </form>
    </div>
  </Section>

  <Section :title="$t('deployments.addBPMN')">
    <UploadDragger
      :fileList="fileList"
      name="bpmn"
      accept=".bpmn"
      :remove="handleRemove"
      :multiple="false"
      :beforeUpload="beforeUpload"
    >
      <p class="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p class="ant-upload-text">
        {{ $t("app.fileUploader.hint") }}
      </p>
    </UploadDragger>
  </Section>

  <div class="buttons">
    <Button type="primary" :title="$t('app.continue')" @click="submit">
      {{ $t("deployments.saveAndConfigure") }}
    </Button>
  </div>
</template>

<script>
import { Button, Upload } from "ant-design-vue";
import {
  EODDatePicker,
  EODInput,
  EODRadio,
  EODSelect,
} from "@/components/inputs";
import {
  createDeployment,
  editDeployment,
  getDeploymentCategory,
  getDeploymentStatuses,
} from "@/services/deployments";

import { InboxOutlined } from "@ant-design/icons-vue";
import { STATUS } from "@/consts/statuses";
import { Section } from "@/components/ui";
import { getFormsSimpleList } from "@/services/forms";

export default {
  name: "BasicInformation",
  props: {
    id: {
      type: [Number, String],
    },
    deploymentData: {
      type: Object,
      required: true,
    },
    defaultForm: { type: Object, default: null },
    defaultCategory: { type: Object, default: null },
    tab: String,
  },
  data() {
    return {
      statusOptions: [],
      fileList: [],
      errors: {},
    };
  },
  components: {
    Button,
    EODDatePicker,
    EODInput,
    EODRadio,
    EODSelect,
    InboxOutlined,
    Section,
    UploadDragger: Upload.Dragger,
  },
  computed: {
    data: {
      get() {
        return this.deploymentData;
      },
      set(val) {
        this.$emit("update:deploymentData", val);
      },
    },
  },
  created() {
    getDeploymentStatuses().then(({ data }) => {
      this.statusOptions = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });
  },
  emits: ["update:deploymentData", "update:id", "update:tab"],
  methods: {
    fetchFormsList(params) {
      return getFormsSimpleList({ status: STATUS.ACTIVE, ...params }).then(
        ({ data }) => ({
          ...data,
          results: data.results.map((elem) => ({
            id: elem.id,
            name: elem.name,
            disabled: elem.in_use,
          })),
        }),
      );
    },
    fetchCategoryList() {
      return getDeploymentCategory().then(({ data }) => data);
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    submit() {
      const { fileList, data } = this;
      const formData = new FormData();

      const service = this.id ? editDeployment : createDeployment;

      //TODO: cleanup
      formData.append("name", data.name);
      formData.append("status", data.status);
      formData.append("description", data.description);
      if (data.available_to) {
        formData.append("available_to", data.available_to);
      }
      if (data.available_from) {
        formData.append("available_from", data.available_from);
      }
      if (data.form !== undefined && !data.deployment_started) {
        formData.append("form", data.form);
      }
      if (data.category !== undefined && !data.deployment_started) {
        formData.append("category", data.category);
      }
      if (fileList.length > 0) {
        formData.append("bpmn", fileList[0]);
      } else if (!this.id) {
        this.$message.error(this.$t("deployments.BPMNInfo"));
        return;
      }

      service(formData, this.id)
        .then(({ data }) => {
          this.fileList = [];
          this.data = data;
          this.$router
            .replace({
              name: "DeploymentEdit",
              params: { id: data.id },
              query: { tab: "configuration" },
            })
            .then(() => {
              this.$emit("update:tab", "configuration");
            });
          this.$emit("update:id", data.id);
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    disabledDate(current) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return current < yesterday;
    },
  },
};
</script>
