import { Params, SelectOption } from "@/interfaces/common";
import { Ref, computed } from "vue";
import {
  getDictionaryEntriesByKeyword,
  getDictionaryEntriesSelect,
} from "@/services/dictionaries";
import {
  getGroupsSimpleList,
  getUsersSimpleList,
} from "@/services/permissions";

import { AxiosResponse } from "axios";
import { ContractorSelectOption } from "@/interfaces/contractors";
import { DictionaryEntriesParams } from "@/interfaces/dictionaries";
import { STATUS } from "@/consts/statuses";
import { getContactsSelect } from "@/services/contacts";
import { getContractorsSelect } from "@/services/contractors";
import { getPriorityOption } from "@/services/tasks";
import { useStore } from "@/store";

export default function useFormBuilderSelects(
  schemaUI: Ref<any>,
  additionalValues: Ref<Record<string, string | number>>,
) {
  const store = useStore();

  const getStoreID = (key: string) => {
    switch (schemaUI.value[key].component) {
      case "dictSelect":
      case "multiSelect": {
        return `forms-dict-${schemaUI.value[key].dictionaryID}`;
      }
      case "systemSelect": {
        return `forms-${schemaUI.value[key].dictionaryKeyword}`;
      }
      case "candidate_user":
      case "currentUser": {
        return "forms-users";
      }
      default: {
        return `forms-${schemaUI.value[key].component}`;
      }
    }
  };

  const onSelectChange = (val: string | number, key: string) => {
    additionalValues.value[key] = store.state[
      `select_${getStoreID(key)}`
    ].serviceOptions.find(
      (item: SelectOption) => item.id === val,
    )?.additional_value;
  };

  const getService = (key: string) => {
    const callback = ({ data }: AxiosResponse) => ({
      ...data,
      results: data.results.map((elem: any) => ({
        id: elem.id,
        name: elem.name ?? elem.full_name,
        additional_value: elem.additional_value,
      })),
    });

    switch (schemaUI.value[key].component) {
      case "prioritySelect": {
        return (params: Params) => getPriorityOption(params).then(callback);
      }
      case "userDelegation":
      case "currentUser": {
        return (params: Params) => getUsersSimpleList(params).then(callback);
      }
      case "groupDelegation": {
        return (params: Params) => getGroupsSimpleList(params).then(callback);
      }
      case "contacts": {
        return (params: Params) => getContactsSelect(params).then(callback);
      }
      case "systemSelect": {
        return (params: Params) =>
          getDictionaryEntriesByKeyword(schemaUI.value[key].dictionaryKeyword, {
            status: STATUS.ACTIVE,
            ...params,
          }).then(callback);
      }
      default: {
        return (params: DictionaryEntriesParams) =>
          getDictionaryEntriesSelect({
            dictionary: schemaUI.value[key].dictionaryID,
            status: STATUS.ACTIVE,
            ...params,
          }).then(callback);
      }
    }
  };

  const fetchContractors = (params: Params) => {
    return getContractorsSelect({ status: STATUS.ACTIVE, ...params }).then(
      ({ data }) => ({
        ...data,
        results: data.results.map((item: ContractorSelectOption) => ({
          id: +item.id,
          name: item.name,
          verified: item.verified,
        })),
      }),
    );
  };

  const contractors = computed(
    () => store.state["select_forms-contractors"]?.serviceOptions ?? [],
  );

  const usersUnitsList = computed(
    () => store.getters["user/getUser"]?.units ?? [],
  );

  return {
    contractors,
    fetchContractors,
    getService,
    getStoreID,
    onSelectChange,
    usersUnitsList,
  };
}
