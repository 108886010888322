<template>
  <!-- TODO: current has to be capitalized in EN version -->
  <Breadcrumbs
    :path="[{ path: { name: 'Numeration' }, title: $t('modules.numeration') }]"
    :current="$t('app.preview_', [$tc('numeration.numeration', 1)])"
    iconComponent="OrderedListOutlined"
  >
    <EODButtonLink
      v-if="$permissions.includes('counters.change_counter_custom')"
      :to="{ name: 'NumerationEdit', params: { id: id } }"
      :title="$t('app.edit', [$tc('numeration.numeration', 2)])"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('app.preview_', [$tc('numeration.numeration', 1)])"
    :noPreviewMsg="$t('app.noPreview_', [$tc('numeration.numeration', 1)])"
  />

  <NumerationUnits v-if="id && isUnitCounter" :id="id" />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import NumerationUnits from "./NumerationUnits.vue";
import { Preview } from "@/components/common";
import { getNumeration } from "@/services/numeration";

export default {
  name: "NumerationPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      previewData: [],
      isUnitCounter: false,
    };
  },
  components: { Breadcrumbs, EODButtonLink, NumerationUnits, Preview },
  created() {
    if (this.id) {
      getNumeration(this.id).then(({ data }) => {
        this.isUnitCounter = data.is_unit_counter;

        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          { title: this.$t("numeration.format"), value: data.text_format },
          {
            title: this.$t("numeration.formatPreview"),
            value: data.counter_text_value,
          },
          {
            title: this.$t("numeration.counter"),
            value: data.is_unit_counter ? "-" : data.next_free_value,
          },
          {
            title: this.$t("numeration.inUse"),
            value: data.in_use ? this.$t("app.yes") : this.$t("app.no"),
          },
        ];
      });
    }
  },
};
</script>
