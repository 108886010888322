import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "field-tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BranchesOutlined = _resolveComponent("BranchesOutlined")
  const _component_EODSwitch = _resolveComponent("EODSwitch")
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      _createVNode("span", null, _toDisplayString(_ctx.item.title), 1),
      (_ctx.item.required)
        ? (_openBlock(), _createBlock("span", {
            key: 0,
            class: "color--error",
            title: _ctx.$t('app.fieldRequired')
          }, " * ", 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", null, [
      (!_ctx.multiValue)
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editCondition(_ctx.item))),
            class: [
          'tile-button',
          _ctx.item.condition ? 'color--success' : 'color--primary',
        ],
            title: _ctx.$t('forms.conditionalForm.title')
          }, [
            _createVNode(_component_BranchesOutlined)
          ], 10, ["title"]))
        : _createCommentVNode("", true),
      (
          ![
            'numeration',
            'currentDate',
            'separator',
            'calc',
            'info',
            'multiValue',
          ].includes(_ctx.item.ui.component)
        )
        ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.item.ui.props.disabled
            ? _ctx.$t("deployments.disabledField")
            : _ctx.$t("deployments.activeField")) + " ", 1),
            _createVNode(_component_EODSwitch, {
              checked: _ctx.cDisabled,
              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => (_ctx.cDisabled = $event)),
              size: "small",
              title: 
            _ctx.item.ui.props.disabled
              ? _ctx.$t('deployments.activate')
              : _ctx.$t('deployments.disable')
          ,
              invert: true
            }, null, 8, ["checked", "title"])
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.multiValue)
        ? (_openBlock(), _createBlock("button", {
            key: 2,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeItem(_ctx.item.id))),
            class: "tile-button",
            title: _ctx.$t('app.delete')
          }, [
            _createVNode(_component_DeleteOutlined)
          ], 8, ["title"]))
        : _createCommentVNode("", true)
    ])
  ]))
}