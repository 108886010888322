<template>
  <Section id="folders-content" :title="$t('archive.folders.content')">
    <template v-slot:button>
      <EODButton
        v-if="!preview"
        @click="openAddToVolumeModal"
        :name="$t('archive.folders.addToVolume')"
        type="primary"
        :disabled="isAddToVolumeBtnDisabled"
      />
    </template>
    <ConfirmModal
      v-model:visible="showRemoveModal"
      :title="$t('archive.folders.delete')"
      :okText="$t('archive.folders.delete')"
      :onSubmit="handleRemove"
      :onCancel="closeRemoveModal"
    >
      {{
        $t("archive.folders.doYouWantToRemove", [
          record.name || record.signature,
        ])
      }}
    </ConfirmModal>
    <Tabs :tabBarStyle="{ boxShadow: 'none' }" v-model:activeKey="cActiveKey">
      <TabPane key="issues" :tab="$t('modules.issues')" class="mt-1">
        <BaseTable
          :columns="issuesColumns"
          :service="(params) => getFolderIssues(id, params)"
          customStore="folderIssues"
          :rowSelection="preview ? false : rowSelectionIssues"
        >
          <template #volume="{ record }">
            {{ record.volume }}
          </template>
          <template #dates="{ record }">
            {{ new Date(record.initiation_date).toLocaleDateString() }} -
            {{ new Date(record.close_date).toLocaleDateString() }}
          </template>
          <template #actions="{ record }">
            <TableActions
              :record="record"
              :previewRoute="{
                name: 'IssuePreview',
                params: { id: record.id },
              }"
              :showEdit="false"
              :showRemove="false"
            >
              <button
                v-if="!preview"
                class="action"
                @click="openRemoveModal(record)"
                :title="$t('archive.folders.delete')"
              >
                <DisconnectOutlined />
              </button>
            </TableActions>
          </template>
        </BaseTable>
      </TabPane>
      <TabPane key="documents" :tab="$t('modules.documents')" class="mt-1">
        <BaseTable
          :columns="documentsColumns"
          :service="(params) => getFolderDocuments(id, params)"
          customStore="folderDocuments"
          :rowSelection="preview ? false : rowSelectionDocuments"
        >
          <template #volume="{ record }">
            {{ record.volume }}
          </template>
          <template #document_date="{ record }">
            {{ new Date(record.document_date).toLocaleDateString() }}
          </template>
          <template v-slot:actions="{ record }">
            <TableActions
              :record="record"
              :previewRoute="{
                name: 'DocumentsPreview',
                params: { id: record.id },
              }"
              :showEdit="false"
              :showRemove="false"
            >
              <button
                v-if="!preview"
                class="action"
                @click="openRemoveModal(record)"
                :title="$t('archive.folders.delete')"
              >
                <DisconnectOutlined />
              </button>
            </TableActions>
          </template>
        </BaseTable>
      </TabPane>
    </Tabs>
  </Section>
  <AddToVolumeModal
    v-if="!preview"
    v-model:visible="showAddToVolumeModal"
    :id="id"
    :issues="rowSelectionIssues.selectedRowKeys"
    :documents="rowSelectionDocuments.selectedRowKeys"
  />
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { EODButton, Section } from "@/components/ui";
import {
  deleteFolderDocument,
  deleteFolderIssue,
  getFolderDocuments,
  getFolderIssues,
} from "@/services/archive";
import AddToVolumeModal from "@/components/archive/AddToVolumeModal.vue";
import { ConfirmModal } from "@/components/modals";
import { DisconnectOutlined } from "@ant-design/icons-vue";
import { Tabs } from "ant-design-vue";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "FoldersContent",
  props: {
    id: { type: Number, default: null },
    preview: {
      type: Boolean,
      default: false,
    },
    activeKey: {
      type: String,
      default: "issues",
    },
  },
  data() {
    return {
      defaultRecord: {
        folder_document_id: null,
        folder_issue_id: null,
        name: "",
        signature: "",
      },
      record: {
        ...this.defaultRecord,
      },
      showRemoveModal: false,
      showAddToVolumeModal: false,
      rowSelectionDocuments: {
        selectedRowKeys: [],
        onChange: this.onSelectDocument,
      },
      rowSelectionIssues: {
        selectedRowKeys: [],
        onChange: this.onSelectIssue,
      },
      documentsColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: this.$t("app.volume"),
          dataIndex: "folder_document__volume",
          slots: { customRender: "volume" },
          sorter: true,
          width: "8%",
        },
        {
          title: this.$t("app.createdDate"),
          dataIndex: "document_date",
          slots: { customRender: "document_date" },
          sorter: true,
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "7%",
        },
      ],
      issuesColumns: [
        {
          title: this.$t("issues.number"),
          dataIndex: "signature",
          sorter: true,
        },
        {
          title: this.$t("issues.content"),
          dataIndex: "content",
          ellipsis: true,
          sorter: true,
        },
        {
          title: this.$t("app.volume"),
          dataIndex: "folder_issue__volume",
          slots: { customRender: "volume" },
          sorter: true,
          width: "8%",
        },
        {
          title: this.$t("archive.folders.edgeDates"),
          dataIndex: "initiation_date",
          slots: { customRender: "dates" },
          width: "15%",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "7%",
        },
      ],
    };
  },
  components: {
    AddToVolumeModal,
    BaseTable,
    ConfirmModal,
    DisconnectOutlined,
    EODButton,
    Section,
    TableActions,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    cActiveKey: {
      get() {
        return this.activeKey;
      },
      set(val) {
        this.$emit("update:activeKey", val);
      },
    },
    isAddToVolumeBtnDisabled() {
      return (
        this.rowSelectionDocuments.selectedRowKeys.length < 1 &&
        this.rowSelectionIssues.selectedRowKeys.length < 1
      );
    },
  },
  created() {
    for (const store of ["folderDocuments", "folderIssues"]) {
      if (!this.$store.hasModule(store)) {
        this.$store.registerModule(store, dynamicTable);
      }
    }
  },
  methods: {
    getFolderDocuments,
    getFolderIssues,
    deleteFolderDocument,
    deleteFolderIssue,
    openRemoveModal(record) {
      const { folder_document_id, folder_issue_id, name, signature } = record;
      this.record = { folder_document_id, folder_issue_id, name, signature };
      this.showRemoveModal = true;
    },
    handleRemove() {
      const service = this.record.signature
        ? this.deleteFolderIssue
        : this.deleteFolderDocument;

      const id = this.record.signature
        ? this.record.folder_issue_id
        : this.record.folder_document_id;

      service(id).then(() => {
        this.closeRemoveModal();
        for (const store of ["folderDocuments", "folderIssues"]) {
          if (this.$store.getters[`${store}/checkIsFetched`]) {
            this.$store.dispatch(`${store}/refreshTable`);
          }
        }
        this.$message.success(this.$t("app.success"));
      });

      this.$store.dispatch("refetchEntries");
    },
    closeRemoveModal() {
      this.clearRecord();
      this.showRemoveModal = false;
    },
    clearRecord() {
      this.record = {
        ...this.defaultRecord,
      };
    },
    openAddToVolumeModal() {
      this.showAddToVolumeModal = true;
    },
    onSelectDocument(selectedRowKeys) {
      this.rowSelectionDocuments.selectedRowKeys = selectedRowKeys;
    },
    onSelectIssue(selectedRowKeys) {
      this.rowSelectionIssues.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
</style>
