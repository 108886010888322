<template>
  <Breadcrumbs current="JRWA">
    <EODButtonLink
      v-if="hasPermissionsToAdd"
      :to="{
        name: 'JRWACreate',
      }"
      :title="$t('jrwa.meta.add')"
    />
  </Breadcrumbs>
  <section class="section">
    <BaseTable :columns="columns" :service="getJRWAList">
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'JRWAPreview',
            params: { id: record.id },
          }"
          :editRoute="{ name: 'JRWAEdit', params: { id: record.id } }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'jrwa.jrwa',
            1,
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('app.delete')"
          :deleteService="deleteJRWA"
        >
          <button
            class="action"
            v-if="record?.actions?.activate"
            @click="activateJRWA(record.id)"
            :title="$t('jrwa.activate')"
          >
            <CheckCircleOutlined />
          </button>
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { activateJRWA, deleteJRWA, getJRWAList } from "@/services/jrwa";

import { CheckCircleOutlined } from "@ant-design/icons-vue";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "JRWA",
  props: {
    params: Object,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          slots: { customRender: "status" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    CheckCircleOutlined,
    TableActions,
    EODButtonLink,
    StatusDot,
  },
  computed: {
    hasPermissionsToAdd() {
      return this.$permissions.includes(PERMISSIONS.JRWA_ADD);
    },
  },
  methods: {
    getJRWAList,
    deleteJRWA,
    activateJRWA(recordID) {
      this.$store.dispatch("setModal", {
        modalOk: () => this.handleActivate(recordID),
        title: this.$t("app.confirmation"),
        message: `${this.$t("jrwa.doYouWantToActivate")}`,
      });
    },
    handleActivate(id) {
      activateJRWA(id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$store.dispatch("refreshTable");
      });
    },
  },
};
</script>
