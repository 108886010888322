<template>
  <Breadcrumbs
    :current="$t('modules.archive')"
    iconComponent="ContainerOutlined"
  >
    <EODButtonLink
      v-if="tab === 'folders'"
      :to="{ name: 'FoldersCreate' }"
      :title="$t('archive.folders.add')"
    />

    <EODButton
      v-if="tab === 'handovers'"
      type="primary"
      :name="$t('archive.handovers.createHandoverLists')"
      icon="PlusOutlined"
      @click="openHandoverCreationModal"
      class="ml-1"
    />

    <AddHandoverModal
      v-model:visible="showHandoverCreactionForm"
      v-model:visibleCompleteModal="showHandoverListsModal"
      v-model:handover="handover"
    />
    <CompleteHandoveListsModal
      v-if="showHandoverListsModal"
      v-model:visible="showHandoverListsModal"
      v-model:handover="handover"
      customStore="handoversTable"
    />
  </Breadcrumbs>

  <Tabs v-model:activeKey="tab" @change="$scrollToTop">
    <TabPane key="archive" :tab="$t('modules.archive')"></TabPane>
    <TabPane key="folders" :tab="$t('archive.folders.folders')">
      <Folders />
    </TabPane>
    <TabPane key="handovers" :tab="$t('archive.handovers.handoverLists')">
      <HandoverLists customStore="handoversTable" />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButtonLink, EODButton } from "@/components/ui";
import AddHandoverModal from "@/components/archive/AddHandoverModal";
import CompleteHandoveListsModal from "@/components/archive/CompleteHandoverListsModal";
import Folders from "./Folders";
import HandoverLists from "./HandoverLists";
import { HANDOVER_TYPE, CATEGORY } from "@/consts/statuses";
import { Tabs } from "ant-design-vue";

export default {
  name: "Archive",
  data() {
    return {
      tab: this.$route.query.tab || "archive",
      showHandoverCreactionForm: false,
      showHandoverListsModal: false,
      selectedHandover: null,
      noSelectedHandoverError: [],
      handover: {
        protocol_type: HANDOVER_TYPE.CASUAL,
        protocol_type_display: "",
        type_display: this.$t("archive.handovers.handoverTypes.casual"),
        archive_category_type: CATEGORY.ARCHIVE,
        archive_category_type_display: this.$t(
          "archive.handovers.categoriesDocumentsTypes.archivedDocument",
        ),
        unit: null,
        unit_display: "",
        folders: [],
      },
    };
  },
  methods: {
    openHandoverCreationModal() {
      this.showHandoverCreactionForm = true;
    },
    openHandoverListsModal() {
      this.showHandoverListsModal = true;
    },
  },
  components: {
    AddHandoverModal,
    Breadcrumbs,
    EODButton,
    EODButtonLink,
    HandoverLists,
    Folders,
    TabPane: Tabs.TabPane,
    Tabs,
    CompleteHandoveListsModal,
  },
  watch: {
    tab(val) {
      this.$router.replace({ name: "Archive", query: { tab: val } });
    },
  },
};
</script>
