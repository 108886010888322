import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EODSeparator = _resolveComponent("EODSeparator")
  const _component_FormBuilderInputs = _resolveComponent("FormBuilderInputs")
  const _component_EODButton = _resolveComponent("EODButton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_EODSeparator, {
      title: 
      _ctx.schemaUI[_ctx.fieldKey].multiValueType === 'address' ? _ctx.fieldValue.title : ''
    ,
      showLine: _ctx.schemaUI[_ctx.fieldKey].multiValueType !== 'address'
    }, null, 8, ["title", "showLine"]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(Object.entries(_ctx.fieldValue.items.properties).sort(
      ([key1], [key2]) => {
        return _ctx.schemaUI[key1].ordering - _ctx.schemaUI[key2].ordering;
      },
    ), ([subkey, subval]) => {
      return (_openBlock(), _createBlock(_component_FormBuilderInputs, {
        key: subkey,
        fieldKey: subkey,
        uniqueKey: `${subkey}-${_ctx.index}`,
        val: subval,
        schemaUI: _ctx.schemaUI,
        properties: _ctx.fieldValue.items.properties,
        required: _ctx.required,
        errors: _ctx.errors,
        defaultValues: _ctx.defaultValues,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model = $event)),
        modelAdditionalValues: _ctx.additionalValues,
        "onUpdate:modelAdditionalValues": _cache[2] || (_cache[2] = ($event: any) => (_ctx.additionalValues = $event)),
        modelSearchValue: _ctx.searchValue,
        "onUpdate:modelSearchValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.searchValue = $event)),
        treeSelectOptions: _ctx.treeSelectOptions,
        onLoadData: _ctx.onLoadData,
        fetchTreeDictionary: _ctx.fetchTreeDictionary,
        onTreeSelectChange: _ctx.onTreeSelectChange,
        treeComponents: _ctx.treeComponents
      }, null, 8, ["fieldKey", "uniqueKey", "val", "schemaUI", "properties", "required", "errors", "defaultValues", "modelValue", "modelAdditionalValues", "modelSearchValue", "treeSelectOptions", "onLoadData", "fetchTreeDictionary", "onTreeSelectChange", "treeComponents"]))
    }), 128)),
    (_ctx.schemaUI[_ctx.fieldKey].multiValueType !== 'address' && _ctx.showDelete)
      ? (_openBlock(), _createBlock(_component_EODButton, {
          key: 0,
          class: "mb-050",
          name: _ctx.$t('app.delete'),
          icon: "DeleteOutlined",
          type: "danger",
          ghost: "",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeEntity(_ctx.fieldKey, _ctx.index)))
        }, null, 8, ["name"]))
      : _createCommentVNode("", true)
  ], 64))
}