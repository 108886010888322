<template>
  <Tabs
    v-model:activeKey="activeTab"
    class="tabs-repository"
    :animated="false"
    :tabBarStyle="{
      boxShadow: 'none',
    }"
  >
    <TabPane key="1" :tab="$t('modules.issues')">
      <div v-if="!filters.unit" class="no-content">
        <img
          src="@/img/empty-img-default.png"
          :alt="$t('repository.noSelection')"
        />
        <p>
          {{ $t("repository.noSelection") }}
        </p>
      </div>
      <div v-else class="tile-view">
        <div class="header">
          <SortPanel :sortLabel="sortLabel" :handleSort="handleSort" />
          <div class="results">
            {{ $t("repository.results") }}: {{ totalIssues }}
          </div>
        </div>
        <div v-if="issues.length" class="content">
          <EODCards :items="issues" />
        </div>
        <div v-else>{{ $t("repository.noData") }}</div>
        <div class="footer">
          <Pagination
            v-model:current="page"
            :total="actualTotalIssues || totalIssues"
            simple
            @change="fetchIssues()"
          />
        </div>
      </div>
    </TabPane>
    <TabPane key="2" :tab="$t('modules.documents')">
      <div v-if="!filters.unit" class="no-content">
        <img
          src="@/img/empty-img-default.png"
          :alt="$t('repository.noSelection')"
        />
        <p>
          {{ $t("repository.noSelection") }}
        </p>
      </div>
      <div v-else class="tile-view">
        <div class="header">
          <SortPanel :sortLabel="sortLabel" :handleSort="handleSort" />
          <div class="results">
            {{ $t("repository.results") }}: {{ totalDocuments }}
          </div>
        </div>
        <div v-if="documents.length" class="content">
          <EODCards :items="documents" />
        </div>
        <div v-else>{{ $t("repository.noData") }}</div>

        <div class="footer">
          <Pagination
            v-model:current="page"
            :total="actualTotalDocuments || totalDocuments"
            simple
            @change="fetchDocuments()"
          />
        </div>
      </div>
    </TabPane>
  </Tabs>
</template>

<script>
import EODCards from "@/components/repository/EODCards";
import SortPanel from "./SortPanel.vue";
import { Pagination, Tabs } from "ant-design-vue";
import { SORT_FIELDS } from "@/consts/statuses";
import {
  getResourcesDocuments,
  getResourcesIssues,
} from "@/services/repository";

export default {
  name: "RepositoryList",
  props: {
    filters: Object,
  },
  data() {
    return {
      totalIssues: 0,
      totalDocuments: 0,
      actualTotalIssues: null,
      actualTotalDocuments: null,
      page: 1,
      limit: 6,
      issueOrdering: SORT_FIELDS.INITIATION_DATE_REVERSE,
      documentOrdering: SORT_FIELDS.DOCUMENT_DATE_REVERSE,
      sortLabel: this.$t("repository.oldFirst"),
      issues: [],
      documents: [],
    };
  },
  components: {
    EODCards,
    Pagination,
    Tabs,
    TabPane: Tabs.TabPane,
    SortPanel,
  },
  methods: {
    getResourcesIssues,
    getResourcesDocuments,
    fetchIssues() {
      this.getResourcesIssues({
        page: this.page,
        limit: this.limit,
        ordering: this.issueOrdering,
        ...this.filters,
      }).then(({ data }) => {
        this.issues = data.results;
        this.totalIssues = data.count;

        // set actual total for Pagination component if more pages
        if (data.next != null) {
          this.actualTotalIssues = this.totalIssues + this.limit;
        }
      });
    },
    fetchDocuments() {
      this.getResourcesDocuments({
        page: this.page,
        limit: this.limit,
        ordering: this.documentOrdering,
        ...this.filters,
      }).then(({ data }) => {
        this.documents = data.results;
        this.totalDocuments = data.count;

        // set actual total for Pagination component if more pages
        if (data.next != null) {
          this.actualTotalDocuments = this.totalDocuments + this.limit;
        }
      });
    },
    handleSort() {
      if (this.issueOrdering === SORT_FIELDS.INITIATION_DATE_REVERSE) {
        this.issueOrdering = SORT_FIELDS.INITIATION_DATE;
        this.sortLabel = this.$t("repository.newFirst");
        this.fetchIssues();
      } else if (this.issueOrdering === SORT_FIELDS.INITIATION_DATE) {
        this.issueOrdering = SORT_FIELDS.INITIATION_DATE_REVERSE;
        this.sortLabel = this.$t("repository.oldFirst");
        this.fetchIssues();
      }
      if (this.documentOrdering === SORT_FIELDS.DOCUMENT_DATE_REVERSE) {
        this.documentOrdering = SORT_FIELDS.DOCUMENT_DATE;
        this.sortLabel = this.$t("repository.newFirst");
        this.fetchDocuments();
      } else if (this.documentOrdering === SORT_FIELDS.DOCUMENT_DATE) {
        this.documentOrdering = SORT_FIELDS.DOCUMENT_DATE_REVERSE;
        this.sortLabel = this.$t("repository.oldFirst");
        this.fetchDocuments();
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        //reset pagination after changing filter
        this.total = 0;
        this.totalIssues = 0;
        this.actualTotalDocuments = null;
        this.actualTotalIssues = null;
        this.page = 1;
        this.fetchIssues();
        this.fetchDocuments();
      },
    },
  },
};
</script>
<style scoped>
.tabs-repository {
  width: 100%;
}
</style>
