<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Processes' }, title: $t('modules.processes') }]"
    :current="`${process.deployment?.name ?? ''}${
      process.numeration ? ` - ${process.numeration}` : ''
    }`"
    iconComponent="PartitionOutlined"
  >
    <PrintBarcodes
      v-if="process.numeration"
      :record="process"
      :isAction="false"
    />
    <template #summary>
      <div class="summary">
        <div>
          {{ $t("tasks.deployment") }}:
          <span class="color--font-secondary">
            {{ process.deployment?.name || "-" }}
          </span>
        </div>
        <div>
          {{ $t("app.status") }}:
          <span class="color--font-secondary">
            {{
              process.state === "COMPLETED"
                ? $t("search.processCompleted")
                : $t("status.active")
            }}
          </span>
        </div>
        <div>
          {{ $t("processes.startDate") }}:
          <span class="color--font-secondary">
            {{
              process.startTime
                ? new Date(process.startTime).toLocaleString()
                : "-"
            }}
          </span>
        </div>
        <div>
          {{ $t("issues.endDate") }}:
          <span class="color--font-secondary">
            {{
              process.endTime
                ? new Date(process.endTime).toLocaleDateString()
                : "-"
            }}
          </span>
        </div>
      </div>
    </template>
  </Breadcrumbs>

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('processes.information')">
      <div class="drawer-container">
        <div class="drawer-content">
          <Section
            :title="$t('deployments.form')"
            :collapsable="true"
            v-model:collapsed="collapse.form"
          >
            <FormBuilder
              v-model:modelValue="model"
              :schema="schema"
              :schemaUI="schemaUI"
              :preview="true"
            />
          </Section>

          <Section
            v-if="document"
            :title="$t('app.attachments')"
            :collapsable="true"
            v-model:collapsed="collapse.attachments"
          >
            <Table
              :columns="attachmentColumns"
              :data-source="document.attachments"
            >
              <template v-slot:description="{ record }">
                {{ record.description || " - " }}
              </template>
              <template v-slot:actions="{ record }">
                <div class="actions">
                  <button
                    v-if="record.content_type === 'application/pdf'"
                    class="action"
                    @click="handlePreview(downloadDocumentURL(record.id))"
                    :title="$t('app.preview')"
                  >
                    <EyeOutlined />
                  </button>
                  <a
                    class="action"
                    :href="downloadDocumentURL(record.id)"
                    :title="$t('app.downloadFile')"
                  >
                    <DownloadOutlined />
                  </a>
                </div>
              </template>
            </Table>
          </Section>

          <Preview
            v-if="document"
            :oid="document?.id"
            :data="documentPreview"
            :title="$t('documents.preview')"
            :noPreviewMsg="$t('documents.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.document"
          />
        </div>

        <EODDrawer :visible="showPreview" :handle="handleDrawer">
          <FileViewer v-if="renderPdf" :previewFile="previewFile" />
        </EODDrawer>
      </div>
    </TabPane>
    <TabPane key="2" :tab="$t('modules.tasks')">
      <section class="section">
        <!-- TODO: add proper tasks service AWF-1190 -->
        <BaseTable :columns="tasksColumns" :service="tasksService">
          <template #name="{ record }">
            {{ record.name ? record.name : "-" }}
          </template>
          <template #assignee="{ record }">
            {{ record.assignee ? record.assignee.full_name : "-" }}
          </template>
          <template #startTime="{ record }">
            {{
              record.startTime
                ? new Date(record.startTime).toLocaleDateString()
                : "-"
            }}
          </template>
          <template #endTime="{ record }">
            {{
              record.endTime
                ? new Date(record.endTime).toLocaleDateString()
                : "-"
            }}
          </template>
          <template v-slot:actions="{ record }">
            <div class="actions">
              <!-- TODO: v-if action serializer? -->
              <router-link
                class="action"
                :to="{
                  name: 'TaskPreview',
                  params: { id: record.id },
                }"
                :title="$t('app.preview')"
              >
                <EyeOutlined />
              </router-link>
            </div>
          </template>
        </BaseTable>
      </section>
    </TabPane>
    <TabPane
      key="comments"
      forceRender
      :tab="`${$t('app.comments')}${
        numberOfComments > 0 ? ` (${numberOfComments})` : ''
      }`"
    >
      <Comments :id="id" :customStore="commentsCustomStore" preview process />
    </TabPane>
  </Tabs>
</template>

<script>
import { BaseTable, Table } from "@/components/table";
import { Breadcrumbs, EODDrawer, Section } from "@/components/ui";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { FileViewer, Preview } from "@/components/common";
import {
  downloadDocumentURL,
  getConvertedDocuments,
  getDocument,
} from "@/services/documents";
import { getProcess, getProcessTasks } from "@/services/deployments";

import { Comments } from "@/components/tasks";
import { FormBuilder } from "@/components/forms";
import PrintBarcodes from "@/components/numeration/PrintBarcodes";
import { Tabs } from "ant-design-vue";
import { ref } from "vue";
import useCommentsNumber from "@/composables/useCommentsNumber";

export default {
  name: "ProcessPreview",
  props: { id: { type: String, required: true } },
  setup() {
    const commentsCustomStore = ref("processCommentsList");

    return {
      commentsCustomStore,
      ...useCommentsNumber(commentsCustomStore.value),
    };
  },
  data() {
    return {
      process: {},
      previewFile: "",
      model: {},
      schema: { properties: {} },
      schemaUI: {},
      document: null,
      documentPreview: [],
      collapse: {
        document: false,
        attachments: false,
        form: false,
      },
      showPreview: false,
      renderPdf: false,
      attachmentColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
          sorter: (a, b) => a.filename.localeCompare(b.filename),
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          slots: { customRender: "description" },
          width: "35%",
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
      tasksColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "taskName",
          sorter: true,
          slots: { customRender: "name" },
        },
        {
          title: this.$t("tasks.assignedTo"),
          dataIndex: "assignee",
          sorter: true,
          slots: { customRender: "assignee" },
        },
        {
          title: this.$t("processes.startDate"),
          dataIndex: "startTime",
          slots: { customRender: "startTime" },
          sorter: true,
        },
        {
          title: this.$t("issues.endDate"),
          dataIndex: "endTime",
          slots: { customRender: "endTime" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    Comments,
    DownloadOutlined,
    EODDrawer,
    EyeOutlined,
    FileViewer,
    FormBuilder,
    Preview,
    Section,
    Table,
    Tabs,
    TabPane: Tabs.TabPane,
    PrintBarcodes,
  },
  created() {
    if (this.id) {
      getProcess(this.id).then(({ data }) => {
        this.process = data;
        this.schema = data.form?.schema;
        this.schemaUI = data.form?.schema_ui;
        this.model = Object.fromEntries(
          Object.entries(data.variables).map(([key, value]) => [
            key,
            value.value,
          ]),
        );

        for (const item of Object.values(this.schemaUI)) {
          item.props.disabled = true;
        }

        if (data.document) {
          this.document = data.document;
          this.fetchConverterDocuments(this.document.attachments);

          this.documentPreview = [
            {
              title: this.$t("documents.date"),
              value: new Date(data.document.document_date).toLocaleDateString(),
            },
            {
              title: this.$t("app.name"),
              value: data.document.name,
              to: {
                name: "DocumentsPreview",
                params: { id: data.document.id },
              },
            },
            {
              title: this.$t("documents.JRWANumber"),
              value: data.document.jrwa_display || "-",
            },
            {
              title: this.$t("documents.caseNumber"),
              value: data.document.case || "-",
            },
            {
              title: this.$t("documents.type"),
              value: data.document.document_type_display,
            },
            {
              title: this.$t("app.description"),
              value: data.document.description || "-",
            },
          ];
        }
      });
    }
  },
  methods: {
    downloadDocumentURL,
    // TODO: zoptymalizować to i odświeżanie tabeli po dodaniu api (AWF-913)
    fetchConverterDocuments(documentsList) {
      documentsList.forEach((item) => {
        item.isSave = true;
        getConvertedDocuments(item.id).then(({ data: { results } }) => {
          if (results?.length > 0) {
            item.children = results;
          }
        });
      });
    },
    handlePreview(file) {
      this.previewFile = file;
      this.showPreview = true;
      // TODO: find a better way (maybe)
      setTimeout(() => {
        this.renderPdf = true;
      }, 400);
    },
    fetchDocument() {
      getDocument(this.document.id).then(({ data }) => {
        this.document = data;
        this.fetchConverterDocuments(this.document.attachments);
      });
    },
    handleDrawer() {
      this.showPreview = !this.showPreview;
      if (this.showPreview) {
        // TODO: find a better way (maybe)
        setTimeout(() => {
          this.renderPdf = true;
        }, 400);
      } else {
        this.renderPdf = false;
      }
    },
    tasksService(params) {
      return getProcessTasks(this.id, params);
    },
  },
};
</script>
