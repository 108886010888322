<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Documents' }, title: $t('modules.documents') }]"
    :current="`${title} ${$tc('documents.document', 0)}`"
    iconComponent="FileTextOutlined"
    :what="$tc('documents.document', 0)"
  >
    <EODButton
      v-if="displayDeploymentButton"
      :name="$t('deployments.start')"
      icon="PlayCircleOutlined"
      @click="openSelectDeploymentModal"
    />

    <Dropdown
      v-if="
        id && !data.issue && (hasViewIssuePermission || hasAddIssuePermission)
      "
      :trigger="['click', 'hover']"
      :disabled="showEditMode"
    >
      <EODButton
        v-if="data?.actions?.can_be_in_issue"
        class="ml-1"
        :name="$t('modules.issues')"
        icon="FolderOpenOutlined"
        rightIcon="DownOutlined"
      />
      <template v-slot:overlay>
        <Menu selectable>
          <MenuItem
            v-if="hasViewIssuePermission"
            key="addToIssue"
            @click="openAddToIssueModal"
          >
            {{ $t("issues.meta.addTo") }}
          </MenuItem>
          <MenuItem
            v-if="hasAddIssuePermission"
            key="createIssue"
            @click="openCreateIssueModal"
          >
            {{ $t("issues.addNewIssue") }}
          </MenuItem>
        </Menu>
      </template>
    </Dropdown>

    <Button
      v-if="id && data?.actions?.removable"
      type="danger"
      ghost
      :title="deleteTitle"
      @click="confirmDelete"
      class="ml-1"
      :disabled="showEditMode"
    >
      <DeleteOutlined />
      {{ deleteTitle }}
    </Button>
    <Dropdown
      v-if="id && hasPermissionToEdit"
      :trigger="['click', 'hover']"
      :disabled="showEditMode"
    >
      <EODButton class="ml-1">
        <MoreOutlined />
      </EODButton>
      <template v-slot:overlay>
        <Menu selectable>
          <MenuItem
            @click="copyDocument"
            key="1"
            v-if="data?.actions?.copyable"
          >
            <CopyOutlined />
            {{ $t("documents.createCopy") }}
          </MenuItem>
          <MenuItem key="2" v-if="canBeShared" @click="openShowShareModal">
            <ShareAltOutlined />
            {{ $t("issues.shareDocument") }}
          </MenuItem>
        </Menu>
      </template>
    </Dropdown>

    <template #title>
      {{ title }}
      <CopyOutlined v-if="data.original_document" />
    </template>

    <template #summary>
      <Alert
        v-if="showEditMode"
        class="mt-1 w-100"
        :type="showEditModeType"
        show-icon
      >
        <template #message>
          <p
            v-if="showEditModeType === 'warning'"
            class="mb-0"
            v-html="showEditModeMsg"
          />
          <p v-else class="mb-0">
            {{ showEditModeMsg }}
            <button class="link-button" @click="refreshPage">
              {{ $t("documents.ws.refreshPage") }}
            </button>
            {{ $t("documents.ws.toSeeChangesAndEdit") }}
          </p>
        </template>
      </Alert>
    </template>
  </Breadcrumbs>

  <div class="drawer-container">
    <div class="drawer-content">
      <Section id="step-1" :title="$t('documents.info')">
        <div class="form-container">
          <form @submit.prevent="submit" class="form">
            <EODDatePicker
              id="document_date"
              v-model:value="data.document_date"
              required
              :label="$t('documents.date')"
              :errors="errors.document_date"
              dateOnly
              :disabled="showEditMode"
            />

            <JRWASelect
              id="jrwa"
              v-model:value="data.jrwa"
              v-model:value_display="data.jrwa_display"
              :disabled="data.issue !== null || showEditMode"
              :errors="errors.jrwa"
              :runInitialFetch="!id"
              v-model:refetch="refetchJRWASelect"
            />

            <EODInput
              v-if="id"
              id="issue_signature"
              v-model:value="data.issue_signature"
              :label="$t('documents.caseNumber')"
              disabled
              :errors="errors.issue_signature"
            />

            <EODInput
              id="name"
              v-model:value="data.name"
              :label="$t('app.name')"
              required
              :maxlength="250"
              :errors="errors.name"
              :disabled="showEditMode"
            />

            <EODInput
              id="description"
              type="textarea"
              v-model:value="data.description"
              :label="$t('app.description')"
              :props="{ maxlength: 500 }"
              :errors="errors.description"
              :disabled="showEditMode"
            />
            <EODSelect
              id="folder"
              v-model:value="data.repository_folder"
              :label="$t('documents.ownStructureFolder')"
              :service="fetchFolders"
              :defaultValue="defaultRepositoryFolder"
              :errors="errors.repository_folder"
              :disabled="showEditMode"
            >
              <template #tooltip>
                <Tooltip :title="$t('documents.selectFolderInfo')">
                  <QuestionCircleOutlined />
                </Tooltip>
              </template>
            </EODSelect>

            <EODSelect
              id="related_documents"
              v-model:value="data.related_documents"
              :label="$t('documents.related')"
              mode="multiple"
              :service="fetchDocuments"
              :defaultValue="defaultRelatedDocuments"
              :errors="errors.related_documents"
              :disabled="showEditMode"
            />

            <div class="buttons" v-if="!id">
              <Dropdown v-if="hasStartDeploymentPermission">
                <template #overlay>
                  <Menu>
                    <!-- TODO: submit button -->
                    <MenuItem @click="submit">
                      {{ $t("app.saveAndLeave") }}
                    </MenuItem>
                    <MenuItem @click="openSelectDeploymentForNewDocModal">
                      {{ $t("documents.saveAndStartDeployment") }}
                    </MenuItem>
                  </Menu>
                </template>
                <Button>
                  {{ $t("app.saveAndLeave") }}
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Button
                v-else
                htmlType="submit"
                :title="$t('app.app.saveAndLeave')"
              >
                {{ $t("app.saveAndLeave") }}
              </Button>

              <Button
                htmlType="submit"
                type="primary"
                :title="$t('app.continue')"
                @click="saveAndEdit"
                :loading="loading$"
              >
                {{ $t("app.continue") }}
              </Button>
            </div>
          </form>
        </div>
      </Section>

      <Section id="step-2" :title="$t('app.attachments')" v-if="id">
        <template v-slot:button>
          <EODButton
            @click="() => fetchConverterDocuments(data.attachments)"
            :name="$t('app.refresh')"
            icon="ReloadOutlined"
          />
        </template>
        <FileUploader
          :fileURL="attachmentURL"
          :downloadFileURL="downloadDocumentURL"
          v-model:fileList="data.attachments"
          @preview-file="handlePreview"
          :fetchData="fetchDocument"
          v-model:newAttachmentsWithDesc="newAttachmentsWithDesc"
          :disabled="showEditMode"
          multiple
        />
      </Section>

      <Button
        class="mb-1"
        v-if="id"
        @click="submit"
        type="primary"
        :title="$t('app.save')"
        :loading="loading$"
        :disabled="showEditMode"
      >
        {{ $t("documents.saveDoc") }}
      </Button>
    </div>

    <EODDrawer :visible="showPreview" :handle="handleDrawer">
      <FileViewer v-if="renderPdf" :previewFile="previewFile" />
    </EODDrawer>
  </div>

  <AddToIssueModal
    v-if="id && !data.issue && hasViewIssuePermission"
    v-model:visible="showAddToIssueModal"
    :documents="[data]"
    :refreshDocument="fetchDocument"
  />

  <IssueForm
    v-if="id && !data.issue && hasAddIssuePermission"
    v-model:visible="showCreateIssueModal"
    :document="id"
    :onCreate="fetchDocument"
  />

  <StartDeploymentForDocModal
    v-if="id && data?.actions?.start_deployment"
    v-model:visible="showSelectDeploymentModal"
    :documentName="data.name"
    selectId="deploymentsToStartDocPreview"
    :documentsId="[id]"
  />

  <Modal
    v-model:visible="showShareModal"
    :title="$tc('issues.shareDocument', 1)"
    :okText="$tc('app.share', 2)"
    :cancelText="$t('app.cancel')"
    @ok="submitPerms"
  >
    <ShareForm
      v-model:perms="perms"
      :defaultValues="permsDefaultValues"
      :errors="errors.params"
      :submitFunction="submitPerms"
    />
  </Modal>
  <SelectModal
    v-if="!id && hasStartDeploymentPermission"
    v-model:visible="showSelectDeploymentForNewDocModal"
    v-model:selectedOption="selectedDeployment"
    :title="$t('documents.saveAndStartDeployment')"
    :body="$t('documents.assignNewDocumentsTo')"
    :okText="$t('documents.saveAndStartDeployment')"
    :service="FETCH_DEPLOYMENTS_SELECT"
    :onSubmit="handleStartDeploymentForNewDoc"
    :onCancel="closeSelectDeploymentForNewDocModal"
    selectId="deploymentsToStartForNewDoc"
    :isLoading="isLoading"
  />
</template>

<script>
import { Alert, Button, Dropdown, Menu, Tooltip } from "ant-design-vue";
import { Breadcrumbs, EODButton, EODDrawer, Section } from "@/components/ui";
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined,
  ContainerOutlined,
  SnippetsOutlined,
} from "@ant-design/icons-vue";
import {
  EODDatePicker,
  EODInput,
  EODSelect,
  FileUploader,
  JRWASelect,
} from "@/components/inputs";
import { FileViewer, ShareForm } from "@/components/common";
import {
  copyDocument,
  createDocument,
  createDocumentAndStartDeployment,
  deleteDocument,
  downloadDocumentURL,
  editDocument,
  editDocumentPerms,
  getDocument,
  getDocumentPerms,
  getDocumentTypes,
  getDocumentsSelectList,
} from "@/services/documents";
import AddToIssueModal from "@/views/Documents/AddToIssueModal";
import { FETCH_DEPLOYMENTS_SELECT } from "@/views/Deployments/deploymentsConst";
import IssueForm from "@/views/Issues/IssueForm";
import PERMISSIONS from "@/consts/permissions";
import { SelectModal } from "@/components/modals";
import StartDeploymentForDocModal from "@/views/Documents/StartDeploymentForDocModal";
import addAttachmentDesc from "@/helpers/addAttachmentDesc";
import { getConvertedDocuments } from "@/services/documents";
import { getFolders } from "@/services/repository";
import { Modal } from "ant-design-vue";
export default {
  name: "DocumentsForm",
  data() {
    return {
      isLoading: false,
      renderPdf: false,
      showPreview: false,
      attachmentURL: "/api/documents/attachments/",
      previewFile: "",
      fileList: [],
      showAddToIssueModal: false,
      showCreateIssueModal: false,
      showSelectDeploymentModal: false,
      showSelectDeploymentForNewDocModal: false,
      showShareModal: false,
      selectedDeployment: null,
      data: {
        created_by: null,
        document_date: new Date().toISOString().substring(0, 10),
        name: "",
        //TODO temporary stay due to backend document_type -  deletion  AWF-2050
        document_type: 7,
        jrwa: null,
        issue: null,
        issue_signature: "",
        number: "",
        description: "",
        related_documents: [],
        repository_folder: null,
        attachments: [],
        original_document: null,
      },
      defaultDocumentType: null,
      defaultRepositoryFolder: null,
      defaultRelatedDocuments: null,
      errors: {},
      id: +this.$route.params?.id,
      goEdit: false,
      newAttachmentsWithDesc: [],
      perms: {
        users_can_change: [],
        groups_can_change: [],
        users_can_view: [],
        groups_can_view: [],
      },
      permsDefaultValues: {},
      refetchJRWASelect: false,
      documentSocket: null,
      showEditMode: false,
      showEditModeMsg: "",
      showEditModeType: "warning",
      websocketInfo: null,
      DEBUG: process.env.VUE_APP_DEBUG,
      connectionSignalInterval: null,
    };
  },
  components: {
    AddToIssueModal,
    Alert,
    Breadcrumbs,
    Button,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    Dropdown,
    EODButton,
    EODDatePicker,
    EODDrawer,
    EODInput,
    EODSelect,
    FileUploader,
    FileViewer,
    IssueForm,
    JRWASelect,
    Menu,
    MenuItem: Menu.Item,
    MoreOutlined,
    Modal,
    Section,
    SelectModal,
    ShareForm,
    StartDeploymentForDocModal,
    Tooltip,
    QuestionCircleOutlined,
    ShareAltOutlined,
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    colSpan() {
      return this.id ? 12 : 24;
    },
    title() {
      return this.id ? this.$t("app.editActivity") : this.$t("app.addition");
    },
    deleteTitle() {
      return `${this.$t("app.delete")} ${this.$tc("documents.document")}`;
    },
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS_ADD);
    },
    hasAddIssuePermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES_ADD);
    },
    hasViewIssuePermission() {
      return this.$permissions.includes(PERMISSIONS.ISSUES);
    },
    hasStartDeploymentPermission() {
      return this.$permissions.includes(PERMISSIONS.DEPLOYMENTS_START);
    },
    canBeShared() {
      return this.data?.actions?.can_share;
    },
    displayDeploymentButton() {
      return (
        this.id &&
        this.data?.actions?.start_deployment &&
        (!this.showEditMode ||
          (this.showEditMode &&
            (this.data.created_by === this.user.id || this.user.is_boss)))
      );
    },
  },
  created() {
    this.fetchDocuments();
    if (this.id) {
      this.fetchDocument();
      this.connectToWS();
    }
  },
  unmounted() {
    if (this.DEBUG) {
      console.log("Closing websocket connection");
    }
    this.documentSocket?.close();
  },
  beforeUnmount() {
    clearInterval(this.connectionSignalInterval);
  },
  methods: {
    downloadDocumentURL,
    FETCH_DEPLOYMENTS_SELECT,
    connectToWS() {
      const scheme = window.location.protocol == "https:" ? "wss" : "ws";
      const accessToken = localStorage.getItem("access_token");
      const socketURL = `${scheme}://${window.location.host}/ws/document-edit/${this.id}/?token=${accessToken}`;
      this.documentSocket = new WebSocket(socketURL);

      this.documentSocket.onmessage = (evt) => {
        if (this.DEBUG) {
          console.log("MSG", evt);
        }

        const data = JSON.parse(evt.data);

        if (data.queue_position > 0) {
          // user waits in the queue
          this.showEditModeMsg = this.$t("documents.ws.inQueue", [
            data.current_editor_name || this.$t("documents.ws.anotherUser"),
            data.queue_position,
          ]);
          this.showEditModeType = "warning";
          this.showEditMode = true;
        } else {
          if (data.has_multiple_connections) {
            // user has few tabs opend with the same document
            this.showEditModeMsg = this.$t("documents.ws.openedTabs");
            this.showEditModeType = "warning";
            this.showEditMode = true;
          } else if (
            this.websocketInfo?.queue_position > 0 ||
            this.websocketInfo?.has_multiple_connections
          ) {
            // user is now the first person in the queue or closed all other tabs with the same document
            this.showEditModeMsg = this.$t("documents.ws.wasEditedBy_", [
              data.current_editor_name,
            ]);
            this.showEditModeType = "info";
            this.showEditMode = true;
          } else {
            // user closed the second tab with the same document
            this.showEditMode = false;
          }
        }

        this.websocketInfo = data;
      };

      if (this.DEBUG) {
        this.documentSocket.onopen = (evt) => {
          console.log("OPEN", evt);
        };

        this.documentSocket.onclose = (evt) => {
          console.log("CLOSE", evt);
        };

        this.documentSocket.onerror = (evt) => {
          console.error("ERROR", evt);
        };
      }
      this.connectionSignalInterval = setInterval(() => {
        this.documentSocket.send(
          JSON.stringify({
            message: "CONNECTION_SIGNAL",
          }),
        );
      }, 30000);
    },
    fetchFolders(params) {
      return getFolders(params).then(({ data }) => data);
    },
    handleDrawer() {
      this.showPreview = !this.showPreview;
      if (this.showPreview) {
        // TODO: find a better way (maybe)
        setTimeout(() => {
          this.renderPdf = true;
        }, 400);
      } else {
        this.renderPdf = false;
      }
    },
    // TODO: zoptymalizować to i odświeżanie tabeli po dodaniu api (AWF-913)
    fetchConverterDocuments(documentsList) {
      documentsList.forEach((item) => {
        getConvertedDocuments(item.id).then(({ data: { results } }) => {
          if (results?.length > 0) {
            item.children = results;
          }
        });
      });
    },
    handlePreview(file) {
      this.previewFile = file;
      this.showPreview = true;
      // TODO: find a better way (maybe)
      setTimeout(() => {
        this.renderPdf = true;
      }, 400);
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        modalOk: this.deleteDocument,
        title: this.$t("documents.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "documents.document",
          1,
        )} "${this.data.name}"?`,
      });
    },
    saveAndEdit() {
      this.goEdit = true;
    },
    submit() {
      this.loading$ = true;

      const formData = this.data;
      formData.attachments = formData.attachments.map((el) => el.id);
      formData.repository_folder = this.data.repository_folder || null;

      const service = this.id ? editDocument : createDocument;
      service(formData, this.id)
        .then(({ data }) => {
          this.newAttachmentsWithDesc.forEach((item) => {
            addAttachmentDesc(this.attachmentURL, item);
          });
          this.newAttachmentsWithDesc = [];
          this.$message.success(this.$t("app.success"));
          if (this.goEdit) {
            this.id = data.id;
            this.$router.replace({
              name: "DocumentsEdit",
              params: { id: data.id },
              hash: "#step-2",
            });
            return;
          }
          this.$router.push({ name: "Documents" });
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    async fetchDocument() {
      await getDocument(this.id).then(({ data }) => {
        this.data = data;
        this.refetchJRWASelect = true;
        this.defaultRepositoryFolder = data.repository_folder
          ? { id: data.repository_folder, name: data.repository_folder_name }
          : null;
        this.defaultRelatedDocuments = data.related_documents;
        this.data.related_documents = data.related_documents.map(
          (item) => item.id,
        );
        this.documentHasFolder = !!data.repository_folder_name;
        this.fetchConverterDocuments(this.data.attachments);

        if (this.data?.actions?.can_share) {
          this.fetchDocumentPerms();
        }
      });
    },
    fetchDocumentTypes(params) {
      return getDocumentTypes(params).then(({ data }) => data);
    },
    fetchDocuments(params) {
      return getDocumentsSelectList(params).then(({ data }) => {
        const results = data.results.filter((item) => item.id !== this.id);
        return { ...data, results };
      });
    },
    refreshPage() {
      this.showEditMode = false;
      this.websocketInfo = null;
      this.fetchDocument();
    },
    deleteDocument() {
      deleteDocument(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Documents" });
      });
    },
    // TODO: composition API
    copyDocument() {
      copyDocument(this.id).then(({ data }) => {
        // TODO: make it work with router-link
        this.$message.success(
          <>
            {this.$t("documents.copySuccess")}
            <a href={`/documents/${data.id}/preview/`}>{this.$t("app.here")}</a>
          </>,
        );
        this.fetchDocument();
      });
    },
    openAddToIssueModal() {
      this.showAddToIssueModal = true;
    },
    openSelectDeploymentModal() {
      this.showSelectDeploymentModal = true;
    },
    openSelectDeploymentForNewDocModal() {
      this.showSelectDeploymentForNewDocModal = true;
    },
    closeSelectDeploymentForNewDocModal() {
      this.showSelectDeploymentForNewDocModal = false;
      this.selectedDeployment = null;
      this.isLoading = false;
    },
    openShowShareModal() {
      this.showShareModal = true;
    },
    closeShowShareModal() {
      this.showShareModal = false;
    },
    handleStartDeploymentForNewDoc() {
      this.isLoading = true;
      const formData = this.data;
      formData.attachments = formData.attachments.map((el) => el.id);
      formData.repository_folder = this.data.repository_folder || null;
      createDocumentAndStartDeployment(formData, this.selectedDeployment)
        .then(({ data }) => {
          if (data.task_id) {
            this.$router.replace({
              name: "TaskForm",
              params: { id: data.task_id },
              query: { "back-to": "Documents" },
            });
          } else {
            this.$message.success(data.message);
            this.$router.replace({ name: "Documents" });
          }
          this.closeSelectDeploymentForNewDocModal();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openCreateIssueModal() {
      this.showCreateIssueModal = true;
    },
    submitPerms() {
      editDocumentPerms(this.perms, this.id)
        .then(({ data: msg }) => {
          this.$message.success(
            typeof msg === "string" ? msg : this.$t("app.success"),
          );
          this.closeShowShareModal();
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    fetchDocumentPerms() {
      getDocumentPerms(this.id).then(({ data }) => {
        this.permsDefaultValues = data;
        this.perms = Object.entries(data).reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key]: val.map((item) => item.id),
          }),
          {},
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables.scss";

.link-button {
  border: none;
  background: transparent;
  color: $primary;
  cursor: pointer;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
</style>
