<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Contractors' }, title: $t('modules.contractors') },
    ]"
    :current="title"
    iconComponent="TeamOutlined"
    :what="$tc('contractors.contractor')"
  >
    <template v-if="id">
      <span v-if="contractor.verified" class="color--success span--no-wrap">
        <CheckCircleOutlined />
        &nbsp;
        {{ $t("contractors.verified") }}
      </span>
      <EODButton
        v-else-if="hasPermissionToVerify"
        type="primary"
        @click="verify"
        :name="$t('contractors.verify')"
      />
      <EODButton
        v-if="hasPermissionToDelete"
        class="ml-1"
        type="danger"
        ghost
        @click="confirmDelete"
        icon="DeleteOutlined"
        :name="`${$t('app.delete')} ${$t('contractors.contractor')}`"
      />
    </template>
  </Breadcrumbs>

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('contractors.basicInfo')">
      <div class="drawer-container">
        <div class="drawer-content">
          <ContractorForm
            :id="id"
            v-model:modelErrors="errors.contractor"
            v-model:modelData="contractor"
            v-model:modelCollapse="collapse.contractor"
            v-model:promiseArray="promiseArray"
            v-model:modelAddress="address"
            v-model:modelAttachments="attachments"
            :countriesService="fetchCountries"
            :isDataEmpty="isDataEmpty"
            :defaultValues="defaultValues.contractor"
          />

          <AddressForm
            :id="id"
            v-model:modelErrors="errors.address"
            v-model:modelData="address"
            v-model:modelCollapse="collapse.address"
            v-model:promiseArray="promiseArray"
            :countriesService="fetchCountries"
            :defaultValues="defaultValues.address"
          />

          <SupplyForm
            :id="id"
            v-model:modelErrors="errors.supply"
            v-model:modelData="supply"
            v-model:modelCollapse="collapse.supply"
            v-model:promiseArray="promiseArray"
            :isDataEmpty="isDataEmpty"
            :paymentConditionsService="fetchPaymentConditions"
            :currencyService="fetchCurrencies"
            :defaultValues="defaultValues.supply"
          />

          <AttachmentsForm
            :id="id"
            v-model:modelData="attachments"
            v-model:modelCollapse="collapse.attachments"
            :attachmentURL="attachmentURL"
            v-model:newAttachmentsWithDesc="newAttachmentsWithDesc"
            :handlePreview="handlePreview"
          />

          <FeaturesForm
            :id="id"
            v-model:modelErrors="errors.features"
            v-model:modelAttributesErrors="errors.attributes"
            v-model:modelData="features"
            v-model:modelCollapse="collapse.features"
            v-model:promiseArray="promiseArray"
            :paymentConditionsService="fetchPaymentConditions"
            :defaultValues="defaultValues.features"
          />

          <Button
            v-if="id"
            type="primary"
            @click="ask"
            class="mb-2"
            :loading="loading$"
          >
            {{ $t("app.saveAll") }}
          </Button>
        </div>
        <EODDrawer :visible="showPreview" :handle="handleDrawer">
          <FileViewer v-if="renderPdf" :previewFile="previewFile" />
        </EODDrawer>
      </div>
    </TabPane>
    <TabPane
      v-if="hasPermissionToContactData"
      key="2"
      :tab="$t('contractors.contactData')"
      :disabled="!id"
    >
      <ContactForm
        v-if="showContactForm"
        :id="id"
        :isDataEmpty="isDataEmpty"
        v-model:showContactForm="showContactForm"
      />
      <ContactsList
        :contractorID="id"
        v-model:showContactForm="showContactForm"
      />
    </TabPane>
    <TabPane
      v-if="hasPermissionToBankAccounts"
      key="3"
      :tab="$t('contractors.bankAccounts')"
      :disabled="!id"
    >
      <BankAccountForm
        v-if="showBankAccountForm"
        :id="id"
        :isDataEmpty="isDataEmpty"
        v-model:modelData="contractor"
        :countriesService="fetchCountries"
        :currencyService="fetchCurrencies"
        v-model:showBankAccountForm="showBankAccountForm"
      />
      <BankAccountsList
        :contractorID="id"
        v-model:showBankAccountForm="showBankAccountForm"
      />
      <SortAccounts :contractorID="id" />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButton, EODDrawer } from "@/components/ui";
import {
  deleteContractor,
  getAccountCountryOption,
  getContractor,
  getCurrencyOption,
  getPaymentConditionsOption,
} from "@/services/contractors";

import AddressForm from "./Forms/AddressForm";
import AttachmentsForm from "./Forms/AttachmentsForm";
import BankAccountForm from "./Forms/BankAccountForm";
import BankAccountsList from "./BankAccountsList";
import { Button } from "ant-design-vue";
import { CheckCircleOutlined } from "@ant-design/icons-vue";
import ContactForm from "./Forms/ContactForm";
import ContactsList from "./ContactsList";
import ContractorForm from "./Forms/ContractorForm";
import FeaturesForm from "./Forms/FeaturesForm";
import { FileViewer } from "@/components/common";
import PERMISSIONS from "@/consts/permissions";
import { STATUS } from "@/consts/statuses";
import SortAccounts from "./Forms/SortAccounts";
import SupplyForm from "./Forms/SupplyForm";
import { Tabs } from "ant-design-vue";
import addAttachmentDesc from "@/helpers/addAttachmentDesc";
import { nextTick } from "vue";

export default {
  name: "ContractorsForm",
  data() {
    return {
      id: +this.$route.params.id,
      contractorName: "",
      contractor: {
        name: "",
        short_name: "",
        identifier: "",
        contractor_class: "",
        status: STATUS.ACTIVE,
        country_tax: "",
        nip: "",
        country_nip: "",
      },
      address: {},
      supply: {},
      attachments: [],
      features: {
        attributes: [{ value: "" }],
        is_auto: true,
        payment_conditions: "",
      },
      errors: {
        contractor: {},
        address: {},
        supply: {},
        features: {},
        attributes: [{}],
      },
      collapse: {
        contractor: false,
        address: false,
        supply: true,
        features: true,
        attachments: true,
      },
      promiseArray: [],
      defaultValues: {
        contractor: {},
        address: {},
        supply: {},
        features: {},
      },
      showContactForm: false,
      showBankAccountForm: false,
      attachmentURL: "/api/contractors/attachments/",
      newAttachmentsWithDesc: [],
      showPreview: false,
      renderPdf: false,
      previewFile: "",
    };
  },
  components: {
    AddressForm,
    AttachmentsForm,
    BankAccountForm,
    BankAccountsList,
    Breadcrumbs,
    Button,
    CheckCircleOutlined,
    ContactForm,
    ContactsList,
    ContractorForm,
    EODButton,
    EODDrawer,
    FeaturesForm,
    FileViewer,
    SortAccounts,
    SupplyForm,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    title() {
      return this.id
        ? `${this.$t("app.edit")} ${this.contractorName}`
        : this.$t("contractors.add");
    },
    hasPermissionToDelete() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_DELETE);
    },
    hasPermissionToVerify() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_VERIFICATION);
    },
    hasPermissionToContactData() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_CONTACT_DATA);
    },
    hasPermissionToBankAccounts() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_BANK_ACCOUNTS);
    },
  },
  created() {
    this.$store.dispatch("setContactID", undefined);
    this.$store.dispatch("setBankAccountID", undefined);
    this.$store.dispatch("setAccountsCount", 0);

    if (this.id) {
      getContractor(this.id).then(({ data }) => {
        this.contractorName = data.name;

        const {
          // eslint-disable-next-line no-unused-vars
          contact_details,
          // eslint-disable-next-line no-unused-vars
          bank_accounts,
          address_detail,
          supply_params,
          additional_features,
          attachments,
          ...rest
        } = data;

        if (address_detail.length > 0) {
          this.address = address_detail[0];
          this.defaultValues.address = {
            address_country: this.$getDefaultValue(
              address_detail[0],
              "address_country",
            ),
            address_state: this.$getDefaultValue(
              address_detail[0],
              "address_state",
            ),
          };
        } else {
          this.address = {};
        }

        if (supply_params.length > 0) {
          this.supply = supply_params[0];
          this.defaultValues.supply = {
            payment_conditions: this.$getDefaultValue(
              supply_params[0],
              "payment_conditions",
            ),
            trade_credit_type: this.$getDefaultValue(
              supply_params[0],
              "trade_credit_type",
            ),
            currency: this.$getDefaultValue(supply_params[0], "currency"),
          };
        } else {
          this.supply = {};
        }

        if (additional_features.length > 0) {
          this.features = additional_features[0];
          this.defaultValues.features = {
            payment_conditions: this.$getDefaultValue(
              additional_features[0],
              "payment_conditions",
            ),
          };
        } else {
          this.features = { attributes: [{ value: "" }] };
        }

        if (this.features.attributes.length === 0) {
          this.features.attributes = [{ value: "" }];
        }
        this.errors.attributes = Array(
          this.features.attributes.length || 1,
        ).fill({});

        this.attachments = attachments;

        this.contractor = rest;
        this.contractor.contractor_class = rest.contractor_class ?? undefined;
        this.contractor.country_nip = rest.country_nip ?? undefined;
        this.contractor.country_tax = rest.country_tax ?? undefined;

        this.defaultValues.contractor = {
          contractor_class: this.$getDefaultValue(rest, "contractor_class"),
          country_nip: this.$getDefaultValue(rest, "country_nip"),
          country_tax: this.$getDefaultValue(rest, "country_tax"),
        };
      });
    }
  },
  methods: {
    saveAll() {
      this.$store.dispatch("closeModal");

      if (
        [
          document.getElementById("contractor-form").reportValidity(),
          document.getElementById("address-form").reportValidity(),
          document.getElementById("supply-form").reportValidity(),
          document.getElementById("features-form").reportValidity(),
        ].every((item) => item)
      ) {
        this.$store.dispatch("submitContractor");
      } else {
        return;
      }

      this.loading$ = true;
      nextTick(async () => {
        await Promise.all(this.promiseArray)
          .then((res) => {
            if (res.every((item) => item)) {
              this.$message.success(this.$t("app.success"));
              this.$router.push({ name: "Contractors" });
              this.newAttachmentsWithDesc.forEach((item) => {
                addAttachmentDesc(this.attachmentURL, item);
              });
              this.newAttachmentsWithDesc = [];
            } else {
              this.$message.error(this.$t("app.error"));
            }
            this.promiseArray = [];
          })
          .finally(() => {
            this.loading$ = false;
          });
      });
    },
    isDataEmpty(data) {
      //or => number for select values (id)
      return !Object.values(data).filter(
        (value) =>
          (typeof value === "string" && value !== "") ||
          typeof value === "number",
      ).length;
    },
    verify() {
      if (document.getElementById("contractor-form").reportValidity()) {
        this.$store.dispatch("setModal", {
          title: `${this.$t("contractors.verify")} ${this.$t(
            "contractors.contractor",
          )}`,
          message: this.$t("contractors.doYouWantToVerify"),
          modalOk: this.onVerify,
        });
      }
    },
    onVerify() {
      this.contractor.verified = true;

      if (document.getElementById("contractor-form").reportValidity()) {
        this.$store.dispatch("submitVerification");
      }

      nextTick(async () => {
        await Promise.all(this.promiseArray).then((res) => {
          if (res.every((item) => item)) {
            this.$message.success(this.$t("app.success"));
            this.$store.dispatch("closeModal");
          } else {
            this.$message.error(this.$t("app.error"));
            this.contractor.verified = false;
          }
          this.promiseArray = [];
        });
      });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        title: this.$t("contractors.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$t(
          "contractors.contractor",
        )} "${this.contractorName}"?`,
        modalOk: this.deleteThis,
      });
    },
    deleteThis() {
      deleteContractor(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Contractors" });
      });
    },
    ask() {
      if (this.contractor.verified) {
        this.$store.dispatch("setModal", {
          message: this.$t("contractors.verifyInfo"),
          title: this.$t("contractors.verifyTitle"),
          modalOk: this.saveAll,
        });
      } else {
        this.saveAll();
      }
    },
    fetchPaymentConditions(params) {
      return getPaymentConditionsOption(params).then(({ data }) => data);
    },
    fetchCountries(params) {
      return getAccountCountryOption(params).then(({ data }) => data);
    },
    fetchCurrencies(params) {
      return getCurrencyOption(params).then(({ data }) => data);
    },
    handlePreview(file) {
      this.previewFile = file;
      this.showPreview = true;
      // TODO: find a better way (maybe)
      setTimeout(() => {
        this.renderPdf = true;
      }, 400);
    },
    handleDrawer() {
      this.showPreview = !this.showPreview;
      if (this.showPreview) {
        // TODO: find a better way (maybe)
        setTimeout(() => {
          this.renderPdf = true;
        }, 400);
      } else {
        this.renderPdf = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
