import APPS from "@/consts/apps";
import Contractors from "@/views/Contractors/Contractors.vue";
import ContractorsForm from "@/views/Contractors/ContractorsForm.vue";
import ContractorsPreview from "@/views/Contractors/ContractorsPreview.vue";
import PERMISSIONS from "@/consts/permissions";
import i18n from "@/i18n";

const t = i18n.global.t;

const contractors = [
  {
    path: "/contractors/",
    name: "Contractors",
    component: Contractors,
    meta: {
      title: `${t("meta.app")} - ${t("modules.contractors")}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTRACTORS,
      app: APPS.CONTRACTORS,
    },
  },
  {
    path: "/contractors/create/",
    name: "ContractorsCreate",
    component: ContractorsForm,
    meta: {
      title: `${t("meta.app")} - ${t("contractors.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTRACTORS_ADD,
      app: APPS.CONTRACTORS,
    },
  },
  {
    path: "/contractors/:id/edit/",
    name: "ContractorsEdit",
    component: ContractorsForm,
    meta: {
      title: `${t("meta.app")} - ${t("contractors.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTRACTORS_EDIT,
      app: APPS.CONTRACTORS,
    },
  },
  {
    path: "/contractors/:id/preview/",
    name: "ContractorsPreview",
    component: ContractorsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("contractors.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTRACTORS,
      app: APPS.CONTRACTORS,
    },
  },
];

export default contractors;
