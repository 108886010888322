import axiosSession from "@/config/apiClient";

const getDictionariesList = (params) => {
  return axiosSession.get("/api/dicts/", { params });
};
const getDictionariesSelect = (params) => {
  return axiosSession.get("/api/dicts/select/", { params });
};

const createDictionary = (data) => {
  return axiosSession.post("/api/dicts/", data);
};

const getDictionary = (id) => {
  return axiosSession.get(`/api/dicts/${id}/`);
};

const editDictionary = (data, id) => {
  return axiosSession.put(`/api/dicts/${id}/`, data);
};

const deleteDictionary = (id) => {
  return axiosSession.delete(`/api/dicts/${id}/`);
};

const getDictionaryEntries = (params) => {
  return axiosSession.get("/api/dicts/entry/", { params });
};

const getDictionaryEntriesSelect = (params) => {
  return axiosSession.get("/api/dicts/entry/select/", { params });
};

const getDictionaryEntry = (id) => {
  return axiosSession.get(`/api/dicts/entry/${id}/`);
};

const createDictionaryEntry = (data) => {
  return axiosSession.post(`/api/dicts/entry/`, data);
};

const editDictionaryEntry = (data, id) => {
  return axiosSession.put(`/api/dicts/entry/${id}/`, data);
};

const getDictionaryStatuses = () => {
  return axiosSession.get("/api/dicts/status/");
};

const getDictionaryEntryStatuses = () => {
  return axiosSession.get("/api/dicts/entry/status/");
};

const deleteDictionaryEntry = (id) => {
  return axiosSession.delete(`/api/dicts/entry/${id}/`);
};

const getDictionaryEntryTypes = () => {
  return axiosSession.get("/api/dicts/entry/types/");
};

const getDictionaryEntriesByKeyword = (keyword, params) => {
  return axiosSession.get(`/api/dicts/entry/keyword/${keyword}/`, { params });
};

export {
  createDictionary,
  createDictionaryEntry,
  editDictionary,
  editDictionaryEntry,
  deleteDictionary,
  deleteDictionaryEntry,
  getDictionary,
  getDictionaryEntries,
  getDictionaryEntriesByKeyword,
  getDictionaryEntry,
  getDictionaryEntryStatuses,
  getDictionaryEntryTypes,
  getDictionaryStatuses,
  getDictionariesList,
  getDictionariesSelect,
  getDictionaryEntriesSelect,
};
