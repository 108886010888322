
import {
  Pagination as AntPagination,
  Checkbox,
  Input,
  Modal,
  Tabs,
} from "ant-design-vue";
import { createHandover, patchHandover } from "@/services/handovers";
import { getFolders } from "@/services/archive";
import { HANDOVER_TYPE, CATEGORY } from "@/consts/statuses";
import {
  computed,
  defineComponent,
  PropType,
  inject,
  ref,
  toRefs,
  watch,
} from "vue";
import { Folder } from "@/interfaces/folders";
import { Handover } from "@/interfaces/handovers";
import { EODButton } from "@/components/ui";
import EODCards from "@/components/repository/EODCards.vue";
import { MessageApi } from "ant-design-vue/lib/message";
import { Pagination } from "@/interfaces/archive";

import { store } from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CompleteHandoveListsModal",
  props: {
    id: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: String,
      default: false,
    },
    handover: { type: Object as PropType<Handover>, default: () => ({}) },
  },
  setup(props, { emit }) {
    const $message = inject<MessageApi>("$message");
    const { t } = useI18n();
    const { id, visible, handover, customStore } = toRefs(props);

    const searchField = ref<string>("");
    const selectedFolders = ref<number[]>([]);
    const defaultPagination = ref<Pagination>({
      total: 0,
      page: 1,
      limit: 10,
      offset: null,
      search_field: "",
    });
    const folderPagination = ref<Pagination>({ ...defaultPagination.value });
    const folders = ref<Folder[]>([]);
    const allItemsCount = ref<{ folders: number }>({
      folders: 0,
    });

    const isLoading = ref(false);
    const cVisible = computed({
      get: () => visible.value,
      set: (val) => emit("update:visible", val),
    });

    const checkedAllFoldersOnPage = computed(() =>
      folders.value.every((folder) =>
        selectedFolders.value.includes(folder.id),
      ),
    );

    const checkedSomeFoldersOnPage = computed(() =>
      folders.value.some((folder) => selectedFolders.value.includes(folder.id)),
    );

    const shouldRefetch = computed(() => {
      return store.state.archive.shouldRefetch;
    });

    watch(shouldRefetch, (val) => {
      if (val) {
        fetchFolders();
        store.dispatch("resetRefetchEntries");
      }
    });

    const submit = async () => {
      const service = id.value ? patchHandover : createHandover;
      if (selectedFolders.value.length > 0) {
        await service(
          {
            ...handover.value,
            folders: selectedFolders.value,
          },
          id.value,
        )
          .then(() => {
            $message.success(t("app.success"));
            selectedFolders.value = [];
            folderPagination.value = defaultPagination.value;
            fetchFolders();
            if (store.hasModule(customStore.value)) {
              store.dispatch(`${customStore.value}/refreshTable`);
            }
          })
          .finally(() => {
            isLoading.value = true;
            cVisible.value = false;
            isLoading.value = false;
            handover.value.unit = null;
            handover.value.unit_display = "";
            handover.value.archive_category_type = CATEGORY.ARCHIVE;
            handover.value.archive_category_type_display = t(
              "archive.handovers.categoriesDocumentsTypes.archivedDocument",
            );
            handover.value.protocol_type = HANDOVER_TYPE.CASUAL;
            handover.value.protocol_type_display = t(
              "archive.handovers.handoverTypes.casual",
            );
          });
      }
    };

    const onCancel = () => {
      cVisible.value = false;
    };

    const fetchFolders = () => {
      getFolders({
        limit: folderPagination.value.limit,
        page: folderPagination.value.page,
        folder: id.value,
        search_field: folderPagination.value.search_field,
        with_archivation_protocols: false,
        unit: handover.value.unit,
      }).then(({ data }) => {
        folders.value = data.results;
        folderPagination.value.total = data.count;
        if (folderPagination.value.search_field === "") {
          allItemsCount.value.folders = data.count;
        }
      });
    };
    const onChecked = (
      e: any,
      item: { id: number },
      selectedItemsArray: number[],
    ) => {
      if (e.target.checked) {
        selectedItemsArray.push(item.id);
      } else {
        selectedItemsArray.splice(selectedItemsArray.indexOf(item.id), 1);
      }
    };

    const onCheckAllFolders = (e: any) => {
      let foldersOnPage = folders.value.map((item) => item.id);
      if (e.target.checked) {
        selectedFolders.value = selectedFolders.value.concat(
          foldersOnPage.filter((item) => !selectedFolders.value.includes(item)),
        );
      } else {
        foldersOnPage.forEach((item) => {
          selectedFolders.value.splice(selectedFolders.value.indexOf(item), 1);
        });
      }
    };

    const handleFolderPagination = (page: number, pageSize: number) => {
      folderPagination.value.offset = (page - 1) * pageSize;
      fetchFolders();
    };

    const handleSearch = () => {
      if (folderPagination.value.search_field === searchField.value) {
        return;
      }
      folderPagination.value.search_field = searchField.value;
      folderPagination.value.limit = 10;
      folderPagination.value.page = 1;
      fetchFolders();
    };

    fetchFolders();

    return {
      allItemsCount,
      cVisible,
      folderPagination,
      fetchFolders,
      handleFolderPagination,
      handleSearch,
      isLoading,
      folders,
      onCancel,
      onChecked,
      onCheckAllFolders,
      searchField,
      submit,
      checkedSomeFoldersOnPage,
      checkedAllFoldersOnPage,
      selectedFolders,
    };
  },
  components: {
    Checkbox,
    EODButton,
    EODCards,
    Input,
    Modal,
    AntPagination,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  emits: ["update:visible", "update:activeKeyContent"],
});
